import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CircuitListService } from '../../../services/circuit-list/circuit-list.service';

@Component({
  selector: 'app-circuit-list-filter',
  templateUrl: './circuit-list-filter.component.html',
  styleUrls: ['./circuit-list-filter.component.scss']
})
export class CircuitListFilterComponent implements OnInit {

  // @ViewChild('dropdown', { static: true })
  // dropdown!: ElementRef;

  @ViewChild('searchInput', { static: true })
  searchInput!: ElementRef;

  @ViewChild('onlyWithLayoutCheckbox', { static: true })
  onlyWithLayoutCheckbox!: ElementRef;

  @Output('addCircuitClick') addCircuitClickEvent = new EventEmitter<void>();

  isMenuDropdownVisible = false;

  constructor(private renderer: Renderer2, private circuitListService: CircuitListService) { }

  ngOnInit(): void {
    this.searchInput.nativeElement.value = this.circuitListService.searchBoxFilterText.value;
    this.onlyWithLayoutCheckbox.nativeElement.checked = this.circuitListService.circuitOnlyWithLayoutsFilter.value;
  }

  searchTextChange(event: any) {
    this.circuitListService.searchBoxFilterText.next(event.target.value);
  }

  handleOnlyWithLayoutsClick(event: any) {
    this.circuitListService.circuitOnlyWithLayoutsFilter.next(event.target.checked);
  }

   /* dropdownFixPosition(a: any, dropdown: any) {
    this.isMenuDropdownVisible = !this.isMenuDropdownVisible;
    var dropDownTop = a.offsetTop + a.offsetHeight;
    this.renderer?.setStyle(dropdown, 'top', dropDownTop + 5 + "px");
    this.renderer?.setStyle(dropdown, 'left', a.offsetLeft - dropdown.offsetWidth + "px");
  } */

  // @HostListener('document:mousedown', ['$event'])
  // onGlobalClick(event: any): void {
  //   if (!this.dropdown.nativeElement.contains(event.target)) {
  //     // clicked outside, close dropdown list
  //     this.isMenuDropdownVisible = false;
  //   }
  // }

  handleAddCircuitClick() {
    this.addCircuitClickEvent.emit();
  }
}
