
<div class="left-panel-wrapper track-details">
    <div class="back-button-block">
      <button class="trans" (click)="hideModalDetails()">
        <span class="icon icon_back_arrow"></span>
        <span>{{buttonNames.BACK | customTranslation: translationData }}</span>
      </button>
    </div>
    <div class="info-block-main">
      <div class="form-block">
        <div #target id="target">
          <form #layoutForm="ngForm" *ngIf="currentStep === 'EDIT_TRACK_DETAILS' else detailsBlock">
            <div class="form-row">
              <div class="form-item material full-w">
                <input type="text" name="layoutName" [(ngModel)]="layoutDetails.layoutName" autocomplete="off" [maxlength]="50"
                (ngModelChange)="isFormValueChanged = (layoutDetails.layoutName === $event)"  [ngClass]="{'not-empty': layoutDetails.layoutName && layoutDetails.layoutName.length}">
                <label for="layoutName">{{'BP_LM_LAYOUT_NAME' | customTranslation: translationData }}</label>
              </div>
              <div class="form-note-text right">{{layoutDetails.layoutName ? layoutDetails.layoutName.length: 0}} / 50 {{'BP_CM_CHARACTERS' | customTranslation: translationData }}</div>
            </div>
            <div class="form-row">
              <div class="form-item material full-w" >
                <textarea name="layoutDesc" [(ngModel)]="layoutDetails.layoutDesc" autocomplete="off"
                (ngModelChange)="isFormValueChanged = (layoutDetails.layoutDesc === $event)" [maxLength]="500" [ngClass]="{'content-available': layoutDetails.layoutDesc && layoutDetails.layoutDesc.length}"></textarea>
                <label for="layoutDesc">{{commonLabels.DESCRIPTION | customTranslation:translationData}}</label>
              </div>
              <div class="form-note-text right">{{layoutDetails.layoutDesc ? layoutDetails.layoutDesc.length: 0}} / 500 {{'BP_CM_CHARACTERS' | customTranslation: translationData }}</div>
            </div>
          </form>
        </div>
        <ng-template #detailsBlock>
          <div class="links-block view-circuit-details">
              <label>{{'BP_LM_LAYOUT_NAME' | customTranslation: translationData }}</label>
              <p class="loc-title" *ngIf="selectedTrackDetails" title="{{selectedTrackDetails.name}}">{{selectedTrackDetails.name}}</p>
            <div class="">
              <p class="loc-desc" *ngIf="selectedTrackDetails && selectedTrackDetails?.description">{{selectedTrackDetails.description}}</p>
            </div>
          </div>
        </ng-template>
          <div class="links-block" *ngIf="showLayoutUrl">
            <label>{{'BP_LM_LAYOUT_URL' | customTranslation: translationData }}</label>
            <div class="link-section" *ngIf="showLayoutUrl">
              <a title="{{selectedTrackDetails.layoutUrl}}" id="create-exercise-form-full-leaderboard-link" target="_blank" rel="noopener" href="{{selectedTrackDetails.layoutUrl}}">{{selectedTrackDetails.layoutUrl}}</a>
              <span role="button" class="copy-link"  (click)="copyUrl(selectedTrackDetails.layoutUrl ?? '')"></span>
            </div>
          </div>
          <div class="info-item-block double-clmn">
            <div class="form-item material non-editable stable">
              <input type="text" value="{{(selectedTrackDetails ? selectedTrackDetails.trackLength : 0) | number : '1.2-2'}} km"/>
              <label>{{'BP_LM_LAYOUT_LENGTH' | customTranslation: translationData }} </label>
            </div>
            <div class="form-item material non-editable stable">
              <input type="text" value="{{selectedTrackDetails ? selectedTrackDetails.noOfSectors: 0}}"/>
              <label>{{'BP_LM_SECTORS' | customTranslation: translationData }}</label>
            </div>
          </div>
          <div class="info-item-block double-clmn">
            <div class="form-item material non-editable stable">
              <input type="text" value="{{profileName ? profileName: '--'}}"/>
              <label>{{'BP_LM_PROFILE' | customTranslation: translationData }}</label>
            </div>
            <div class="form-item material non-editable stable">
              <input type="text" value="{{selectedTrackDetails ? (selectedTrackDetails.createdDate | formatDate): ''}}"/>
              <label>{{'BP_CM_CREATED_ON' | customTranslation: translationData }}</label>
            </div>
          </div>
          <div class="info-item-block single-clmn">
            <div class="form-item material non-editable stable" style="user-select: all;">
              <input type="text" value="{{selectedTrackDetails ? selectedTrackDetails.guid: ''}}" />
              <label>{{'BP_LM_TRK_GUID' | customTranslation: translationData }} (32bit)</label>
            </div>
          </div>
          <div class="button-region">
            <button class="beta" (click)="decidePrimaryBtnActn()"><span>{{ getPrimaryButtonName() | customTranslation: translationData }}</span></button>
            <button class="hallow" (click)="decideSecondaryBtnActn()"><span>{{ getSecondaryButtonName() | customTranslation: translationData }}</span></button>
            <button class="hallow" (click)="downloadSelectedTrack()" *ngIf="currentStep === 'VIEW_TRACK_DETAILS'">
              <span class="icon icon_download"></span>
              <span >{{'BP_LM_GPX_LYT' | customTranslation: translationData }}</span>
          </button>
          </div>
      </div>
    </div>
  </div>
