import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { LocationRoutingModule } from "./locations-routing.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { LocationsAppComponent } from "./locations-app.component";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { CommonModule, DecimalPipe } from "@angular/common";
import { HereMapComponent } from "./components/here-map/here-map.component";
import { MapViewCircuitListComponent } from "./pages/map-view-circuit-list/map-view-circuit-list.component";
import { EventsOverviewComponent } from "./pages/events-overview/events-overview.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { TrackuploadComponent } from "./pages/trackupload/trackupload.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { FullScreenMapModalComponent } from "./components/full-screen-map-modal/full-screen-map-modal.component";
import { LayoutDetailsFormComponent } from "./components/layout-details/layout-details-form/layout-details-form.component";
import { LayoutDetailsHeaderComponent } from "./components/layout-details/layout-details-header/layout-details-header.component";
import { LayoutDetailsComponent } from "./pages/layout-details/layout-details.component";
import { LoaderService } from "./services/loader.service";
import { CircuitsServicesService } from "./services/circuits-services.service";
import { CircuitListFilterComponent } from "./components/circuit-list/circuit-list-filter/circuit-list-filter.component";
import { CircuitInfoModalComponent } from "./components/circuit-info-modal/circuit-info-modal.component";
import { SearchComponent } from "./components/search/search.component";
import { HighlightSearchPipe } from "./pipes/highlight-search.pipe";
import { BlobImageComponent } from "./components/blob-image/blob-image.component";
import { MediaUploadComponent } from "./components/media-upload/media-upload.component";
import { MediaGalleryComponent } from "./components/media-gallery/media-gallery.component";
import { LocationsSubmenuToggleComponent } from "./components/locations-submenu-toggle/locations-submenu-toggle.component";
import { NavigateBackSectionComponent } from "./components/navigate-back-section/navigate-back-section.component";
import { EmotionTourModalComponent } from "./components/emotion-tour-modal/emotion-tour-modal.component";
import { CircuitInfoComponent } from "./pages/circuit-info/circuit-info.component";
import { ProgressBarModule } from "./progressbar/progressbar.module";
import { AlertModule } from "./components/alert-modal/alert-modal.module";
import { ToastModule } from "src/app/components/toast/toast.module";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EditCircuitDetailsComponent } from './pages/edit-circuit-details/edit-circuit-details.component';
import { CircuitDetailsFormComponent } from './components/circuit-details-form/circuit-details-form.component';
import { CircuitLeafletMapComponent } from './components/circuit-leaflet-map/circuit-leaflet-map.component';
import { EditGeofenceComponent } from './pages/edit-geofence/edit-geofence.component';
import { StatusModalComponent } from './components/status-modal/status-modal.component';
import { PageNotFoundModule } from "../../components/page-not-found/page-not-found.module";
import { CommonAlertModalModule } from "src/app/components/common-alert-modal/common-alert-modal.module";
import { CreateDropdownComponent } from "./components/create-dropdown/create-dropdown.component";
import { ImageCardCarouselModule } from "src/app/components/image-card-carousel/image-card-carousel.module";
import { ImageCropperModule } from "ngx-image-cropper";
import { GeofenceToolbarComponent } from './components/geofence-toolbar/geofence-toolbar.component';
import { MediaThumbnailComponentComponent } from './components/media-thumbnail-component/media-thumbnail-component.component';
import { TrackListComponent } from './components/track-list/track-list.component';
import { TranslationModule } from "../translation/translation.module";
import { CreateDealerFlowComponent } from './pages/create-dealer-flow/create-dealer-flow.component';
import { CreateDealerflowModalComponent } from './components/create-dealerflow-modal/create-dealerflow-modal.component';
import { DealerFlowCarouselComponent } from './components/dealer-flow-carousel/dealer-flow-carousel.component';
import { DealerCategoriesComponent } from './components/dealer-categories/dealer-categories.component';
import { SharedModule } from "src/app/shared/shared.module";
import { FormatDatePipe } from './pipes/format-date.pipe';
import { ShortenTextPipe } from './pipes/shorten-text.pipe';
import { ClustersListComponent } from "./components/clusters-list/clusters-list.component";
import { ClusterCardComponent } from "./components/cluster-card/cluster-card.component";
import { CreateMapService } from "./services/create-map.service";import { TrackDetailModalComponent } from "./components/track-detail-modal/track-detail-modal.component";
import { TrackCardsCarouselComponent } from "./components/track-cards-carousel/track-cards-carousel.component";
import { LoadingSpinnerModule } from "src/app/components/loading-spinner/loading-spinner.module";
import { FleetManagementComponent } from './components/fleet-management/fleet-management.component';
import { AudioUploadComponent } from './components/audio-upload/audio-upload.component';
import { GeoPathModule } from "src/app/components/geo-path/geo-path.module";
import { SentencecasePipe } from './pipes/sentencecase.pipe';

@NgModule({
  declarations: [
    LocationsAppComponent,
    EventsOverviewComponent,
    LoaderComponent,
    TrackuploadComponent,
    HereMapComponent,
    MapViewCircuitListComponent,
    LayoutDetailsComponent,
    LayoutDetailsHeaderComponent,
    LayoutDetailsFormComponent,
    FullScreenMapModalComponent,
    CircuitListFilterComponent,
    CircuitInfoModalComponent,
    SearchComponent,
    HighlightSearchPipe,
    BlobImageComponent,
    MediaUploadComponent,
    // AlertModalComponent,
    MediaGalleryComponent,
    LocationsSubmenuToggleComponent,
    NavigateBackSectionComponent,
    EmotionTourModalComponent,
    CircuitInfoComponent,
    EditCircuitDetailsComponent,
    CircuitDetailsFormComponent,
    CircuitLeafletMapComponent,
    EditGeofenceComponent,
    StatusModalComponent,
    CreateDropdownComponent,
    GeofenceToolbarComponent,
    MediaThumbnailComponentComponent,
    TrackListComponent,
    CreateDealerFlowComponent,
    CreateDealerflowModalComponent,
    DealerFlowCarouselComponent,
    DealerCategoriesComponent,
    FormatDatePipe,
    ShortenTextPipe,
    ClustersListComponent,
    ClusterCardComponent,
    TrackDetailModalComponent,
    TrackCardsCarouselComponent,
    FleetManagementComponent,
    AudioUploadComponent,
    SentencecasePipe
    // ToastComponent
  ],
  imports: [
    CommonModule,
    LocationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    NgxSkeletonLoaderModule,
    ToastModule,
    AlertModule,
    MatDialogModule,
    SlickCarouselModule,
    PageNotFoundModule,
    CommonAlertModalModule,
    ImageCardCarouselModule,
    ImageCropperModule,    
    TranslationModule,
    LoadingSpinnerModule,
    SharedModule,
    GeoPathModule
  ],
  providers: [
    CircuitsServicesService,
    LoaderService,
    DecimalPipe,
    CreateMapService,
    // { provide: HTTP_INTERCEPTORS, useClass: AuthHelpers, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [LocationsAppComponent],
})
export class LocationsModule {}