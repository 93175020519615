import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent  {

  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef | any;
  
  @Input() componentName: string | undefined; 
  @Input() title:string=''
  @Input() btnSecondary:string=''
  @Input() btnPrimary:string=''
  @Input() isModalOpen:any
  componentsMapping: any;
  componentRef: any;
  content:any
  
  closeModal(){
    this.isModalOpen=false
  }

}
