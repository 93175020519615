<div id="header-contents" class="web-portal-container header" [ngClass]="{'show-m-menu': isMobileMenuVisible}">
    <div id="header-left">
        <div id="brand-reg">
            <div role="button" (click)="logoClicked()" class="brand-logo-mb"><span title="Mercedes-Benz"></span></div>
            <div class="brand-logo-amg"><span title="AMG"></span></div>
        </div>
    </div>

    <div id="header-right">
        <app-main-nav></app-main-nav>
       

        <div id="main-menu-btn" >
            <div class="menu-btn" (click)="toggleMobileMenu()">
                <span>
                    <em></em>
                    <em></em>
                    <em></em>
                </span>
            </div>
        </div>
    </div>

</div>



