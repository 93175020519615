import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/shared/constants/constants';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
import { SharingDataService } from 'src/app/shared/service/sharing-data.service';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { TranslationService } from 'src/app/shared/service/translation.service';

@Component({
  selector: 'app-fleets',
  templateUrl: './fleets.component.html',
  styleUrls: ['./fleets.component.scss']
})
export class FleetsComponent implements OnInit , OnDestroy {

  constructor(
    private cdr: ChangeDetectorRef,
    private translationService:TranslationService,
    private sharingData:SharingDataService,
    private toastService:ToastService,
    private loadingSpinnerService:LoadingSpinnerService,
    
  ) { }
  addCarClicked:boolean=false
  editCarClicked:boolean=false
  fleetCount:number=0
  subscriptions: Subscription[] = [];
  translationData:any;
  showToast: boolean = false;
  toastMessage: string='';
  isSuccess: any;
  toastType: any = 0;
  position: any;
  leftPosition: any;
  updatedCarDetails:boolean=false
  childComponent:string=''
  ngOnInit(): void {
    this.toastService.toastDetails.subscribe((status:any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
    this.subscriptions.push(this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
    }));
    this.subscriptions.push(this.sharingData.getToasterToBeDisplayed().subscribe((data)=>{
      if(data){
        this.showToast=true
        let toastMessage='BP_EM_ITEM_DELETED'
        this.toastService.show(ToastType.Success,`${toastMessage}`,"1%" , "40%")
        setTimeout(()=>{
        this.showToast=false
        }, APP_CONSTANTS.MIN_TIME_TOASTER)
      }
    }))
    this.subscriptions.push(this.sharingData.getCarAddedSuccessfully().subscribe((data)=>{
      if(data){
        this.showToast=true
        this.toastMessage=this.updatedCarDetails?'BP_PM_CAR_DETAILS_UPDATED':'BP_PM_CAR_ADDED_SUCCESSFULLY'
        this.toastService.show(ToastType.Success,`${this.toastMessage}`,"1%" , "40%")
        setTimeout(()=>{
        this.showToast=false
        this.updatedCarDetails=false
        }, APP_CONSTANTS.MIN_TIME_TOASTER)
      }
    }))
  }
  editCarDetailsEmitter(event:any){
    if(event){
    this.updatedCarDetails=true
    }
  }

  componentEmitter(event:string){
  if(event){
    this.childComponent=event
  }
  }

  addCar(){
    this.addCarClicked=true
    this.cdr.detectChanges()
  }
  cancelClicked(event:any){
    if(event){
    this.addCarClicked=false
    }
  }
  carCount(event:any){
   this.fleetCount=event
  }

  ngOnDestroy(): void {
    this.sharingData.setCarAddedSuccessfully(false)
    this.sharingData.setToasterToBeDisplayed(false)
    this.subscriptions.forEach((subscription:any)=>subscription.unsubscribe())
  }
}

