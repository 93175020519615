

<div
  [ngClass]="imageType == 'thumbnail' ? 'mainthumbnail-container' : 'container'"
>
  <div
    *ngIf="this.url.type == 'image'"
    [ngClass]="[
      imageType === 'thumbnail' ? 'thumbnail-container' : 'fullscreen-container',
      (this.count>0 && url.count === 7 && imageType === 'thumbnail' && !isUploadMediaEnabled && showMediaCount )||
      (this.count>0 && url.count === 6 && isUploadMediaEnabled && showMediaCount )
        ? 'last-media'
        : '',
      (url.count > 7 && imageType === 'thumbnail' && !isUploadMediaEnabled && showMediaCount ) ||
      (url.count > 6 && isUploadMediaEnabled && showMediaCount )
        ? 'media-disable'
        : ''
    ]"
  >
    <div
      *ngIf="isImageLoaded"
      class="spinner-border spinner"
      role="status"
    ></div>
    <img
      *ngIf="!isImageLoaded && imgSrc"
      [src]="url.thumbnailUrl | safe"
      alt="blob"
      class="blob-image"
      [ngClass]="[
        imageType === 'thumbnail' ? 'image-thumbnail' : 'image-fullscreen',
        (this.count>0 && url.count === 7 && imageType == 'thumbnail' && !isUploadMediaEnabled && showMediaCount ) ||
        (this.count>0 && url.count === 6 && isUploadMediaEnabled && showMediaCount )
          ? 'last-media'
          : '',
        (url.count > 7 && imageType === 'thumbnail' && !isUploadMediaEnabled && showMediaCount ) ||
        (url.count > 6 && isUploadMediaEnabled && showMediaCount )
          ? 'media-disable'
          : ''
      ]"
    />
  </div>
  <div
    *ngIf="this.url.type == 'video'"
    [ngClass]="[
      imageType === 'thumbnail' ? 'thumbnail-container' : 'fullscreen-container',
      (this.count>0 && url.count === 7 && imageType === 'thumbnail' && !isUploadMediaEnabled && showMediaCount ) ||
      (this.count>0 && url.count === 6 && isUploadMediaEnabled && showMediaCount )
        ? 'last-media'
        : '',
      (url.count > 7 && imageType === 'thumbnail' && !isUploadMediaEnabled && showMediaCount ) ||
      (url.count > 6 && isUploadMediaEnabled && showMediaCount )
        ? 'media-disable'
        : ''
    ]"
  >
    <div
      *ngIf="isVideoLoaded"
      class="spinner-border spinner"
      role="status"
    ></div>
    <video
      [id]="divId"
      loop
      autoplay
      [muted]="true"
      *ngIf="!isVideoLoaded"
      [ngClass]="[
        imageType === 'thumbnail' ? 'image-thumbnail' : 'image-fullscreen',
        (this.count>0 && url.count === 7 && imageType === 'thumbnail' && !isUploadMediaEnabled && showMediaCount ) ||
        (this.count>0 && url.count === 6 && isUploadMediaEnabled && showMediaCount )
          ? 'last-media'
          : '',
        (url.count > 7 && imageType === 'thumbnail' && !isUploadMediaEnabled && showMediaCount ) ||
        (url.count > 6 && isUploadMediaEnabled && showMediaCount)
          ? 'media-disable'
          : ''
      ]"
    ></video>
  </div>
  <span
    class="media-count"
    *ngIf="
      ((this.count>0 && url.count===7 && imageType === 'thumbnail' && !isUploadMediaEnabled) ||
        (this.count>0 && url.count===6 && isUploadMediaEnabled && showMediaCount )) &&
      ((!isVideoLoaded && videoSrc) || (!isImageLoaded && imgSrc))
    "
    >+ {{this.count}}</span
  >





</div>
























