<ng-template #editPopup>
    <div class="info-section">
        <form [formGroup]="aliasForm">
            <div class="amg-row info-content">
                <div class="clm-9">
                    <div class="profile-alias-form">
                        <div class="form-row">
                            <div class="form-item material full-w">
                                <input id="alias-info-alias-name" maxlength="50" formControlName="profileName" type="text"
                                    autocomplete="off"
                                    [ngClass]="{'input-text required':!aliasForm.get('profileName')?.valid, 
                                    'not-empty': aliasForm.value.profileName.length}"
                                    />
                                <label>{{'BP_PM_AN' | customTranslation:translationData}}*</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-item material full-w">
                                <textarea id="alias-info-alias-desc" formControlName="profileDescription"
                                [ngClass]="{'content-available': aliasForm.value.profileDescription.length}"
                                ></textarea>
                                <label for="">{{ appConstants.COMMON_LABELS.DESCRIPTION | customTranslation:translationData}}</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-item material full-w">
                                <input id="alias-info-alias-website" maxlength="50" formControlName="businessWebsite"
                                    type="text" [ngClass]="{'not-empty': aliasForm.value.businessWebsite.length}"/>
                                <label>{{'BP_PM_BW' | customTranslation:translationData}}*</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clm-3">
                    <div class="upload-img">
                        <div class="img-reg" role="button">
                        <img #filedata [src]="imageUrl" class="image" alt="profile image" (click)="file.click()">
                        </div>
                        <div>
                        <input id="filedata" type="file" name="filedata" accept="image/*"
                        (change)="handleFileInput($event.target)"  #file
                        />
                        <label for="filedata" role="button">{{imageUrl?'Update Image':'Add Image'}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<app-common-modal [isCloseModal]="false" [closeButtonTitle]="buttonNames.CANCEL" [saveButtonTitle]="buttonNames.DONE"
    [isHeaderVisible]="true" [isFooterVisible]="true" component="create-edit-alias" [showModalContent]="editPopup"
    (saveModalEmitter)="saveProfileData()" (closeModalEmitter)="closeModal()" *ngIf="showEditPopup" [type]="popupType"></app-common-modal>