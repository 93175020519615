<div class="left-panel-wrapper">
  <div class="circuit-widget-main">
    <div class="nav-back">
      <app-navigate-back-section [navigateMessage]="navigateMessage" (isShowMapView)="showSearch($event)">
      </app-navigate-back-section>
    </div>
    <div class="modal-class-circuit" (click)="hideDropDown()">
      <div class="circuit-details-main">
        <div class="overlay-image">
          <img [src]="imageDataUrl" alt="..." />
          <div class="circuit-info-txt-main">
            <div class="header-name">
              <ng-container *ngIf="copyrightInfo">
                <div class="bottom-left" [innerHTML]="'BP_LM_COPYRIGHT_INFO' | customTranslation:{'{placeholderImgURl}': copyrightInfo.subject_url, '{placeholderName}': copyrightInfo.attribution, '{placeholderImgLicense}': copyrightInfo.license_url, '{placeholderLicense}': copyrightInfo.license }: translationData">
                </div>
              </ng-container>
              <span class="circuit-title">{{ this.circuitDetails?.name }}</span>
              <span class="menu-container">
                <span class="icon icon_kebab" data-toggle="dropdown" (click)="dropdownFixPosition($event)" #menuLink
                  [ngClass]="this.isMenuDropdownVisible && isshowDropDown? 'active-menu':'menu'"></span>
                <div [style.display]="isMenuDropdownVisible && isshowDropDown ? 'block' : 'none'"
                  class="dropdown kebab-dropdown dropdown-sm" #dropdown>
                  <div class="dropdown-menu dropdown-menu-right">
                    <button (click)="goToCircuitDetails()" [ngClass]="isEditSelected ? 'selected-menu':''"
                      class="edit-circuit-button">
                      <span>{{buttonNames.EDIT | customTranslation:translationData}}</span>
                    </button>
                    <button (click)="deleteCircuit()" [ngClass]="isDeleteSelected ? 'selected-menu':''"
                      class="delete-circuit-button">
                      <span>{{buttonNames.DELETE | customTranslation: translationData }}</span>
                    </button>
                  </div>
                </div>
              </span>
            </div>
            <div class="circuit-type">
              <span>{{ this.circuitDetails?.type | titlecase }}</span>
            </div>
          </div>
        </div>
        <div class="circuit-info-main" [ngClass]="{'circuit-info-block': !this.circuitDetails?.description}">
          <div class="">
            <div class="col">
              <div class="description-text" *ngIf="this.circuitDetails?.description">
                <p id="description" >
                  {{ this.circuitDetails?.description }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="info-block">
                <span class="circuit-info" *ngIf="this.circuitDetails?.address">
                  <span class="icon icon_location_n"></span>
                  <span class="info-txt">{{ this.circuitDetails?.address }}</span>
                </span>
              </div>
              <div class="info-block">
                <span class="circuit-info" *ngIf="this.circuitDetails?.web">
                  <span class="icon icon_website"></span>
                  <span class="info-txt"><a [href]="this.circuitDetails?.web" target="_blank">{{ this.circuitDetails?.web }} </a></span>
                </span>
              </div>
              <div class="info-block">
                <span class="circuit-info" *ngIf="this.circuitDetails?.phoneNumber">
                  <span class="icon icon_call"></span>
                  <span class="info-txt">{{ this.circuitDetails?.phoneNumber }}</span>
                </span>
              </div>
              <div class="info-block">
                <span class="circuit-info" *ngIf="this.circuitDetails?.email">
                  <span class="icon icon_email"></span>
                  <span class="info-txt">{{ this.circuitDetails?.email }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="right-floating-wrapper">
  <div class="coordinates-info">
    <div class="coordinate-info-item" *ngIf="this.circuitDetails?.area">
      <div class="info-lable">{{locationConstants.HELPER_INPUT_LABELS.CIRCUIT_GEO_FENCE| customTranslation:translationData}}
      </div>
      <div class="info-text">{{ this.circuitDetails?.area |number: '1.2-2'}} km</div>
    </div>
    <div *ngIf="this.circuitDetails?.area" class="vl"></div>
    <div class="coordinate-info-item">
      <div class="info-lable">
        {{ locationConstants.HELPER_INPUT_LABELS.NO_OF_LAYOUTS | customTranslation:translationData}}
      </div>
      <div class="info-text" *ngIf="this.circuitDetails?.location">
        {{circuitDetails?.layouts.length ?? 0}}
      </div>
    </div>
  </div>
</div>
<app-common-alert-modal [title]="alertTitle" [message]="alertMessage" [isModalOpen]=isModalOpen
            [actionType]="actionType" (isModalResponseClicked)="getCancelModalResponse($event)"
            [cancelBtnTitle]="alertCancelBtnTitle" [actionBtnTitle]="alertActionBtnTitle">
          </app-common-alert-modal>