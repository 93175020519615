import { Component, Input, SimpleChange } from '@angular/core';
import { TranslationService } from 'src/app/shared/service/translation.service';

@Component({
  selector: 'app-status-modal',
  templateUrl: './status-modal.component.html',
  styleUrls: ['./status-modal.component.scss']
})
export class StatusModalComponent  {
  @Input() public statusTitle: any = 'title';
  @Input() public statusMessage: any = 'message';
  @Input() public statusSubMessage: any = '';
  @Input() public statusImage: any = '';
  @Input() public statusLargeImage: any = '';
  @Input() public isShowStatus: any = false;
  translationData: any;
  dynamicValue: string = '';

  constructor(private _translationService: TranslationService) {}

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes.currentValue.statusMessage) {
      this.dynamicValue = changes.currentValue.statusMessage.split(' ')[1];
    }
  }

}
