import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { LocationsHttpService } from "../../services/locations-http.service";
import { MediaCarouselService } from "../../services/media/media-carousel.service";
import { AlertModalService } from "../../services/modal/alert-modal.service";
import { DbService } from "../../components/blob-image/image.db";
import { APP_CONSTANTS } from "src/app/shared/constants/constants";
import { LOCATIONS_CONSTANTS } from "../../interfaces/locations-constants";

declare let $: any;

@Component({
  selector: "app-media-gallery",
  templateUrl: "./media-gallery.component.html",
  styleUrls: ["./media-gallery.component.scss"],
})
export class MediaGalleryComponent implements OnInit {
  @ViewChild("carousel", { static: true })
  carousel!: ElementRef;
  @Input() public imageUrls = [];
  @Input("selectedImgIndex") selectedImgIndex = 0;
  @Input() trackName: string = "";
  @Input() thumbnailUrls: any;
  @Input() trackNameGuid:any;
  public sendSelectedImage: any;
  public trackMedia: any;
  mediaCount: string = "";
  isModalOpen = false;
  isFromPage = "mediaGallery";
  isEditClicked = true;
  deleteMediaCount: string = "";
  deleteSelectedMedia: any;
  showConfirmation: boolean = false;
  title: string = "";
  message: string = "";
  cancelBtnTitle: string = "";
  actionBtnTitle: string = "";
  showToast: any = false;
  toastMessage: any;
  toastType: any;
  position: any;
  leftPosition: any;
  public trackGuid: any;
  public from: any;
  subscribeUploadedMedia: any;
  subscribeshareMediaUrls: any;
  cancelSelection: boolean = false;
  public enableSpinner = false;
  public isGalleryOpened = true;
  mediaFiles = [];
  isMediaUpdate:boolean=false;
  constructor(
    private mediaCarouselService: MediaCarouselService,
    private locationsHttpService: LocationsHttpService,
    private toastService: ToastService,
    private alertModalService: AlertModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private db: DbService
  ) {}
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    _event: KeyboardEvent
  ) {
    this.closeModal();
  }
  ngOnInit(): void {
    this.mediaCarouselService.openModal.subscribe((value: boolean) => {
      this.isModalOpen = value;
    });
    this.subscribeshareMediaUrls =
      this.mediaCarouselService.shareMediaUrls.subscribe((value: any) => {
        this.trackMedia = value;
        this.trackGuid = this.trackMedia[0]?.guid;
      });
    this.mediaCount = " (" + this.imageUrls.length + ")";
    this.mediaCarouselService.scrollToSelectedIndex.subscribe((value: any) => {
      this.selectedImgIndex = value.selectedMediaIndex;
    });
    this.mediaCarouselService.deleteSelectedMedia.subscribe((value: any) => {
      this.deleteSelectedMedia = value;
      this.deleteMediaCount = "";
      if (value.deleteMediaIds.length && value.checked) {
        this.deleteMediaCount = " (" + value.deleteMediaIds.length + ")";
      }
    });
    this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
  }
  ngOnChanges() {
    this.mediaCount = " (" + this.imageUrls.length + ")";
    if (this.selectedImgIndex === -1) {
      this.selectedImgIndex = 0;
    }
  }
  getMedia() {
    this.mediaFiles = [];
    this.subscribeUploadedMedia =
      this.locationsHttpService.sharemediaData.subscribe((data) => {
        this.mediaFiles = data[0];
      });
  }
  getPreviousIndex() {
    if (this.selectedImgIndex > 0) {
      this.selectedImgIndex = this.selectedImgIndex - 1;
    } else {
      this.selectedImgIndex = this.imageUrls.length - 1;
    }
  }
  getNextIndex() {
    if (this.selectedImgIndex <= this.imageUrls.length - 2) {
      this.selectedImgIndex = this.selectedImgIndex + 1;
    } else {
      this.selectedImgIndex = 0;
    }
  }
  closeModal() {
    this.mediaCarouselService.openModal.next(false);
    this.changeDetectorRef.detectChanges();
  }
  deleteMedia() {
    this.showConfirmation = true;
    this.title = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CONFIRM_DELETE;
    this.message =
      "Selected items will be permanently deleted, are you sure you want to delete?";
    this.cancelBtnTitle = APP_CONSTANTS.COMMON_BUTTON_LABELS.CANCEL;
    this.actionBtnTitle = APP_CONSTANTS.COMMON_BUTTON_LABELS.DELETE;
  }
  getModalResponse(event: any) {
    if (event.action) {
      if (this.deleteSelectedMedia) {
        this.showConfirmation = false;
        this.enableSpinner = true;
        this.locationsHttpService
          .UpdateTrackDetails("Admin", this.deleteSelectedMedia)
          .subscribe({
            next: () => {
              this.deleteSelectedMedia.deleteMediaIds.map(async (key: any) => {
                this.db.deleteMedia(key);
              });
              this.toastService.show(
                ToastType.Success,
                "Deleted Successfully",
                "1%",
                "40%"
              );
              this.enableSpinner = false;
              this.mediaCarouselService.deleteSelectedMedia.next(null);
              this.mediaCarouselService.callGetTrackDetails.next(true);

              this.locationsHttpService
                .getTrackDetails(this.trackGuid)
                .subscribe((trackData) => {
                  let count = 0;
                  this.trackMedia = [];
                  this.imageUrls = [];
                  trackData.media?.image?.map((image: any, _index: any) => {

                    let imageObj: any = {
                      originalUrl: image.originalUrl,
                      thumbnailUrl: image.thumbnailUrl,
                      type: "image",
                      count: count,
                      id: image.id,
                      guid: trackData.guid,
                    };
                    this.trackMedia.push(imageObj);

                    count = count + 1;
                  });
                  trackData.media?.video?.map((video: any, _index: any) => {
                    let vedioObj: any = {
                      originalUrl: video.originalUrl,
                      thumbnailUrl: video.thumbnailUrl,
                      type: "video",
                      count: count,
                      id: video.id,
                      guid: trackData.guid,
                    };
                    this.trackMedia.push(vedioObj);
                    count = count + 1;
                  });

                  setTimeout(() => {
                    this.toastService.hide();
                  }, 4000);
                  this.deleteMediaCount = "";
                  this.mediaCarouselService.shareMediaUrls.next(
                    this.trackMedia
                  );
                  this.selectedImgIndex = 0;
                  this.mediaCount = " (" + this.trackMedia.length + ")";
                });
            },
            error: (error: any) => {
              this.showConfirmation = false;
              this.enableSpinner=false;
              this.toastService.show(
                ToastType.Error,
                error.error["error-message"],
                "10%",
                "40%"
              );
              setTimeout(() => {
                this.toastService.hide();
              }, APP_CONSTANTS.MIN_TIME_TOASTER);
            },
          });
      } 
    } else {
      this.showConfirmation = false;
    }
  }

  getTrackDetails() {
    let count = 0;
    this.locationsHttpService
      .getTrackDetails(this.trackGuid)
      .subscribe((trackData) => {
        this.trackMedia = [];
        this.imageUrls = [];
        trackData.media?.image?.map((image: any, _index: any) => {
          let imageObj: any = {
            originalUrl: image.originalUrl,
            thumbnailUrl: image.thumbnailUrl,
            type: "image",
            count: count,
            id: image.id,
            guid: trackData.guid,
          };
          this.trackMedia.push(imageObj);

          count = count + 1;
        });
        trackData.media?.video?.map((video: any, _index: any) => {

          let vedioObj: any = {
            originalUrl: video.originalUrl,
            thumbnailUrl: video.thumbnailUrl,
            type: "video",
            count: count,
            id: video.id,
            guid: trackData.guid,
          };
          this.trackMedia.push(vedioObj);
          count = count + 1;
        });

        setTimeout(() => {
          this.toastService.hide();
        }, 4000);
        this.deleteMediaCount = "";
        this.selectedImgIndex = 0;
        this.trackMedia.map((item: any) => {
          this.db.getMedia(item.id, "thumbnail");
        });
        this.mediaCarouselService.shareMediaUrls.next(this.trackMedia);

        this.mediaCount = " (" + this.trackMedia.length + ")";
      });
  }
  ngOnDestroy() {
    if (this.subscribeUploadedMedia) {
      this.subscribeUploadedMedia.unsubscribe();
    }
    this.subscribeshareMediaUrls.unsubscribe();
  }
}
