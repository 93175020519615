import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslationService } from 'src/app/shared/service/translation.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements OnInit, OnChanges {

  isOptionsVisible: boolean = false;

  @Input()
  options: any;

  @Input()
  title: any;

  @Input()
  defaultSelection: any;

  @Input()
  selected: any;

  @Input()
  component:string=''

  @Input()
  key: any;


  @Output()
  selectedOption = new EventEmitter<any>();

  translationData: any;

  constructor(private translationService: TranslationService) { 
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selected = this.defaultSelection;
  }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.selected = this.defaultSelection;
  }

  selectOption(option: any) {
    this.selected = option;
    this.toggleOptionsVisibility();
    this.selectedOption.emit(option);
  }

  toggleOptionsVisibility() {
    this.isOptionsVisible = !this.isOptionsVisible;
  }

  closeDropdown() {
    this.isOptionsVisible = false;
  }

}
