import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { LanguageService } from 'src/app/shared/service/language.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
@Component({
  selector: 'app-locations-root',
  templateUrl: './locations-app.component.html',
  styleUrls: ['./locations-app.component.scss'],
})
export class LocationsAppComponent implements OnInit, OnDestroy{
  title = 'Locations';
  isPageAvailable: boolean = true;
  subscribe: any
  allSupportedLangCodes: any;
  constructor(private activeRoute: ActivatedRoute, private _languageService: LanguageService, private _translationService: TranslationService) {
    /* For future Ref, in case we want to animate our routes
    https://fireship.io/lessons/angular-router-animations/ */
    this.allSupportedLangCodes = this._translationService.getSupportedLanguageByCode();
  }
  
  ngOnInit(): void { 
    this.subscribe = this.activeRoute.params.subscribe((params) => {
      this.isValidLanguage(params.languageId);
    }); 
  }

  isValidLanguage(languageId: string) {
    const allowedRoutes = ['circuit', 'business-tour', 'offroad-trail'];   
    if (languageId && this.allSupportedLangCodes.includes(languageId)) {
      this.isPageAvailable = true;
      this._languageService.setUserSelected(languageId);
      this.activeRoute.children[0].params.subscribe((childParams) => {
        if (childParams.trackType && !allowedRoutes.includes(childParams.trackType)) {
          this.isPageAvailable = false;
        } else {
          this.isPageAvailable = true;
        }
      });
    } else {
      this.isPageAvailable = false;
    }
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

}
