export interface AutoSuggestResponse {
    title: string
    id: string
    resultType: string
    localityType: string
    address: Address
    position: Position
    distance: number
    mapView: MapView
    highlights: Highlights
  }
  
  export interface Address {
    label: string
  }
  
  export interface Position {
    lat: number
    lng?: number
    lon?: number
  }
  
  export interface MapView {
    west: number
    south: number
    east: number
    north: number
  }
  
  export interface Highlights {
    title: Title[]
    address: Address2
  }
  
  export interface Title {
    start: number
    end: number
  }

  export interface Address {
    country: string
    municipality: string
    state: string
    house_number: string
    street_name: string
    zip_code: string
  }
  
  export interface Address2 {
    label: Label[]
  }
  
  export interface Label {
    start: number
    end: number
  }

  export interface CategoriesResponse {
    tour_id: string
    expires: string
    category: string
    categoryTitle?: string
    backgroundImage?: string
  }

  export interface BusinessTourPreview {
    type: string
    features: Feature[]
    isRoundTrip?: boolean
  }

  export interface WaypointDataToDelete {
    index: number,
    name: string,
    annotationId: string
  };
  
  export interface Feature {
    id: string
    type: string
    properties: Properties
    geometry: Geometry,
  }
  
  export interface Properties {
    auto_annotate:boolean
    createdDate?: string
    provider?: string
    fill?: string
    stroke?: string
    centroid: number[]
    trackLength?: number
    trackVersions?: string[];
    owner?: Owner
    landmark_type: string
    guid: string
    visibility?: string
    name?: string
    description?: string
    route_distance: number
    route_position: number
    address?: Address
    "marker-symbol"?: string
    "marker-color"?: string,
    elevation_gain?: number,
    media?: {
      url?: string
      copyright?: Copyright
      source?: string
      height: number
      width: number
      thumb_url?: string
    }[]
    type: string
  }

  export interface Copyright {
    license: string
    attribution: string
    subject_name: string
    complete: boolean
    subject_url: string
    license_url: string
  }
  
  export interface Owner {
    ciamId: string
    nickName?: any,
    name?: string
    bio?: any
    link?: any
  }
  
  export interface Geometry {
    coordinates: any[]
    type: string
  }
  
  export interface UpdateTrackDetailsResponse {
    statusCode: number
    message: string
    guid: string
  }

  export interface CarouselData {
    id: string
    name: string
    description: string
    media_details: any[]
    distance: string
  }

  export interface CategoriesResponse {
    journey_categories: JourneyCategory[]
    isCuratedTour?: boolean
  }
  
  export interface JourneyCategory {
    tour_id: string
    expires: string
    category: string
  }

  export interface CuratedTourRequest {
  tourDetails: {tour_id: string, name: string}
  annotationIds?: string[]
  }

  export interface TourDetailsUpdateRequest {
    name?: string,
    guid: string,
    description:string,
    visibility?:string
  }

  export interface RecentSearch {
    name: string;
    title: string;
    position: Position;
  } 
  export interface TourDetailsUpdateRequest {
    name?: string,
    guid: string,
    description:string,
    visibility?:string
  }

  

  export enum ELAND_MARK {
    ROUTE = 'ROUTE',
    DESTINATION = 'DESTINATION',
    ORIGIN = 'ORIGIN'
  }

  export enum ETOUR_CREATION_STEPS {
    START_ADDED = 'START_ADDED',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
    DESTINATION_ADDED = 'DESTINATION_ADDED',
    ADD_WAY_POINT = 'ADD_WAY_POINT',
    WAY_POINT_ADDED = 'WAY_POINT_ADDED',
    ADD_WAY_POINT_DETAILS = 'ADD_WAY_POINT_DETAILS',
    DESTINATION_CANCEL = 'DESTINATION_CANCEL',
    EDIT_DESTINATION = 'EDIT_DESTINATION',
    EDIT_START = 'EDIT_START',
    EDITING_START = 'EDITING_START',
    EDITING_DESTINATION = 'EDITING_DESTINATION',
    EDIT_AFTER_PUBLISH = 'EDIT_AFTER_PUBLISH',
    TOUR_PUBLISHED = 'TOUR_PUBLISHED',
  }

  export enum EMODAL_PRIMARY_ACTIONS {
    CANCEL_EMOTION_TOUR = 'CANCEL_EMOTION_TOUR',
    CANCEL_INSIDER_SPOT = 'CANCEL_INSIDER_SPOT',
    DELETE_BUSINESS_TOUR = 'DELETE_BUSINESS_TOUR',
    DISABLE_EDIT = 'DISABLE_EDIT',
    DELETE_INSDIER_SPOT = 'DELETE_INSDIER_SPOT'
  }