import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  
  language: any;
  supportedLanguageList = ['en-GB', 'de-DE'];
  public showLanguageSelectionModal = new BehaviorSubject<boolean>(false);
  public showLanguageSelectionModal$ = this.showLanguageSelectionModal.asObservable();
  private _userSelectedLanguage: any;

  constructor(private _translationService: TranslationService) {
    this.supportedLanguageList = this._translationService.getSupportedLanguageByCode();
  }
  
  

  getLanguage() {
    const selectedLanguage = localStorage.getItem('language');
    const defaultBrowserLanguage = navigator.language;
    if(selectedLanguage && this.supportedLanguageList.includes(selectedLanguage)) {
      this.language = selectedLanguage;
    } else if(defaultBrowserLanguage && this.supportedLanguageList.includes(defaultBrowserLanguage)) {
      this.language = defaultBrowserLanguage;
    } else {
      this.language = this.supportedLanguageList.find(lang => lang.includes('en')) ?? 'en-GB';
    }
    return this.getFormattedLangById(this.language);
  }

  public setUserSelected(languageId: any) {
    this._userSelectedLanguage = this.getFormattedLangById(languageId);
  }

  public getUserSelectedLanguage(): string {
    return this._userSelectedLanguage;
  }

  public getFormattedLangById(langaugeId: string): string {
    switch (langaugeId) {
      case 'en':
      case 'en-GB':
        return 'en-GB';
      case 'es':
      case 'es-ES':
        return 'es-ES';
      case 'fr':
      case 'fr-FR':
        return 'fr-FR';
      case 'de':
      case 'de-DE':
        return 'de-DE';
      case 'it':
        return 'it-IT';
      case 'ko':
      case 'ko-KR':
        return 'ko-KR';
      case 'cs':
      case 'cs-CZ':
        return 'cs-CZ';
      default:
        return 'en-GB';
    }
  }
}
