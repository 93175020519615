import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from '../shared/service/language.service';

@Injectable({
  providedIn: 'root'
})
export class RouterLanguageGaurdGuard implements CanActivate {

  constructor(private router: Router, private languageService: LanguageService) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let languageId = this.languageService.getLanguage();
      const originalUrl = state.url;      
      if(originalUrl.startsWith('/events') || originalUrl.startsWith('/locations') || originalUrl.startsWith('/profile')) {
        const modifiedUrl = `/${languageId}${originalUrl}`;
        console.log('modified:', modifiedUrl);
        this.router.navigate([modifiedUrl]);
      } else {
        console.log('modified:', originalUrl);
      }
      return true;
  } 
}
