<div *ngIf="clustername" (click)="loadClusterDetailsOnClk(clustername)"
     (mouseenter)="addHeightAnimation()" (mouseleave)="removeHeightAnimation()" class="tour-card-main">

    <div id="cardContent" class="card-content">
        <div class="clustername"><span>{{clustername?.name}}</span></div>
        <div class="tag-area">
            <span *ngIf="clustername?.type ==='EMOTION_TOUR'" class="blue-tag">{{clusterTags.EMOTION_TOUR| customTranslation: translationData}}</span>
            <span *ngIf="clustername?.type ==='CIRCUIT'" class="red-tag">{{clusterTags.TRACK_PACE| customTranslation: translationData}}</span>
            <span *ngIf="clustername?.type === 'OFFROAD_TOUR'" class="offroad-tag">{{clusterTags.OFFROAD_ADVENTURE| customTranslation: translationData}}</span>

            <span *ngIf="clustername?.layouts && !clustername?.layouts[0]?.guid && clustername?.layouts > 1" class="black-tag">{{clustername?.layouts}}  {{'BP_LM_TRACKS_LBL' | customTranslation: translationData}}</span>
            <span *ngIf="clustername?.layouts && !clustername?.layouts[0]?.guid && clustername?.layouts === 1" class="black-tag">{{clustername?.layouts}} {{'BP_LM_TRACK_LBL' | customTranslation: translationData}}</span>

            <!-- <span *ngIf="clustername?.totalNumberOfLayouts" class="black-tag">{{clustername?.totalNumberOfLayouts}}  Tracks</span> -->
            <span *ngIf="clustername?.annotationsCount && clustername?.annotationsCount > 1" class="black-tag">{{clustername?.annotationsCount}} {{'BP_LM_HIGHLIGHTS' | customTranslation: translationData}}</span>
            <span *ngIf="clustername?.annotationsCount && clustername?.annotationsCount === 1" class="black-tag">{{clustername?.annotationsCount}} {{'BP_LM_HIGHLIGHT_LBL' | customTranslation: translationData}}</span>
            <span *ngIf="clustername?.trackLength" class="black-tag">{{(clustername?.trackLength / 1000).toPrecision(4)}} Km</span>
        </div>
    </div>
    <div class="tour-image-reg" *ngIf="clustername.media; else elseBlock">
        <figure>
            <img src="{{clustername.media.image[0].thumbnailUrl}}"  alt="" />
        </figure>
    </div>
    <ng-template #elseBlock>
        <div [ngSwitch]="clustername?.type" class="tour-image-reg">
            <figure *ngSwitchCase="'CIRCUIT'">
                <img src="../../../../../assets/locations/banner-images/TRACK_PACE_BANNER.png"  alt="" />
            </figure>
            <figure *ngSwitchCase="'EMOTION_TOUR'">
                <img src="../../../../../assets/locations/banner-images/EMOTION_TOUR_BANNER.png"  alt="" />
            </figure>
            <figure *ngSwitchCase="'OFFROAD_TOUR'">
                <img src="../../../../../assets/locations/banner-images/OFFROAD_BANNER.png"  alt="" />
            </figure>
        </div>
    </ng-template>
</div>