import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-media-thumbnail-component',
  templateUrl: './media-thumbnail-component.component.html',
  styleUrls: ['./media-thumbnail-component.component.scss']
})
export class MediaThumbnailComponentComponent {
  @Input() public uploadEnable:any;
  @Input() public trackName:any;
  @Input() public isShowGallery:any;
  

}
