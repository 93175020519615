<div
  class="modal outer-section"
  tabindex="-1"
  role="dialog"
  [ngClass]="isShowStatus ? 'open-modal' : 'close-modal'"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content outer-container">
      <label class="modal-title main-title"> {{ statusTitle | customTranslation: translationData }}</label>
      <div class="modal-content container">
        <div class="modal-header title-container">
   
        </div>
        <div class="modal-body body">
          <div *ngIf="statusImage">
            <img [src]="statusImage" class="status-image" alt=""/>
          </div>
          <div *ngIf="statusLargeImage">
            <img [src]="statusLargeImage" class="status-large-image" alt=""/>
          </div>
          <div *ngIf="statusImage === '' && statusLargeImage === ''" class="loader"></div>
          <label class="modal-title title"> {{ statusMessage | customTranslation: translationData }}</label>
          <h6>{{ statusSubMessage | customTranslation: translationData }}</h6>
        </div>
        <div class="modal-footer footer-container">
        </div>
      </div>
    </div>
  </div>
</div>