import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RandomNumberGeneratorService {
  randomNumber(): number {
    const crypto = window.crypto;
    let array = new Uint32Array(1);
    crypto.getRandomValues(array);
    let number = array[0];
    return number;
  }
}
