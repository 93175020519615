import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EALL_TRACKS, MapViewResponse } from '../interfaces/search-location.interface';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { APP_CONSTANTS } from 'src/app/shared/constants/constants';
import { LOCATIONS_CONSTANTS } from '../interfaces/locations-constants';

@Injectable({
  providedIn: 'root',
})
export class CircuitsServicesService {
  circuitData: any = {};
  trackData: any = {};
  public circuitContent = new BehaviorSubject<any>(this.circuitData);
  public shareCircuitData = this.circuitContent.asObservable();
  public trackContent = new BehaviorSubject<any>(this.trackData);
  public shareTrackData = this.trackContent.asObservable();
  public formContent = new Subject<any>();
  public shareformData = this.formContent.asObservable();
  public editActive = new Subject<any>();
  public shareEditActive = this.editActive.asObservable();
  public geocoordinates: any;
  public coordinatesLocation: any;
  public mediaContent$ = new BehaviorSubject<any[]>([]);
  public sharemediaData$ = this.mediaContent$.asObservable();

  public coverMediaContent$ = new BehaviorSubject<any>(null);
  public shareCovermediaData$ = this.coverMediaContent$.asObservable();
  highlightMediaList: any = [];
  public displayFleetManagement$ = new BehaviorSubject<boolean>(false);
  public displayFleetManagementObservable = this.displayFleetManagement$.asObservable();

  constructor(private httpClient: HttpClient, private _translationService: TranslationService) {}

  // Circuit Creation:- This is set for drawing polyline on map
  setCoordinateValue(data: any) {
    this.geocoordinates = data;
  }

  // Circuit Creation:- This is for getting the polyine
  getEditedCoordinates() {
    return this.geocoordinates;
  }

  setCoordinatesLocation(data: any) {
    this.coordinatesLocation = data;
  }

  getCoordinatesLocation() {
    return this.coordinatesLocation;
  }
 
  getLatestCircuitDetails(data: any) {
    this.circuitContent.next(data);
  }
  getLatestTrackDetails(data: any) {
    this.trackContent.next(data);
  }
  sendFormValue(value: any) {
    this.formContent.next(value);
  }
  getCircuitsList(pageSize: any, pageNo: any): Observable<any> {
    return this.httpClient.get('/api/getCircuitsList', {
      params: {
        size: pageSize,
        pageNumber: pageNo,
      },
    });
  }

  removeCircuits(guid: any): Observable<any> {
    return this.httpClient.delete('/api/deleteCircuits', {
      params: {
        guid: guid,
      },
    });
  }

  createCircuit(data: any): Observable<any> {
    return this.httpClient.put('/api/addCircuit', data);
  }

  updateCircuit(data: any): Observable<any> {
    return this.httpClient.put('/api/updateCircuit', data);
  }
  getCircuit(guid: any): Observable<any> {
    return this.httpClient.get('/api/getCircuit', {
      params: {
        guid: guid,
      },
    });
  }

  uploadTrack(data: any): Observable<any> {
    let formData = new FormData();
    formData.append('file', data);
    return this.httpClient.post('/api/trackUpload', formData);
  }
  searchCircuits(
    pageSize: any,
    pageNo: any,
    nameMatches: any
  ): Observable<any> {
    return this.httpClient.get('/api/searchCircuits', {
      params: {
        size: pageSize,
        pageNumber: pageNo,
        nameMatches: nameMatches,
      },
    });
  }
  getMapCircuitList(limit: any, point: any, radius: any): Observable<any> {
    return this.httpClient.get('/api/mapViewCircuitList', {
      params: {
        limit: limit,
        point: point,
        radius: radius,
      },
    });
  }
  getTrackDetails(guid: any): Observable<any> {
    return this.httpClient.get('/api/getTrackDetails', {
      params: {
        guid: guid,
      },
    });
  }
  UpdateTrackDetails(userId: any, data: any): Observable<any> {
    return this.httpClient.put('/api/UpdateTrackDetails', data, {
      params: {
        userId: userId,
      },
    });
  }

  setHighlightMediaValue(data: any) {
    this.highlightMediaList.push(data);
  }

  getHighlightMediaValue() {
    return this.highlightMediaList;
  }

  setEditActiveStatus(value:boolean){
    this.editActive.next(value);
  }

  sendMediaValueForCover(value: any[]) {
    this.mediaContent$.value.push(value);
  }

  sendMediaValue(value: any[]) {
    this.mediaContent$.next(value);
  }
  clearMediaValue() {
    this.mediaContent$.next([]);
  }

  sendCoverMediaValue(value: any) {
    this.coverMediaContent$.next(value);
  }
  clearCoverMediaValue() {
    this.coverMediaContent$.next(null);
  }

  public setSendTourToFleet(value: boolean) {
    this.displayFleetManagement$.next(value)
  }

public getSendTourToFleet(): Observable<any> {
    return this.displayFleetManagement$.asObservable();
  }

  public handleSearchApiResponse(searchResults: MapViewResponse): any[] {
    console.log('searchResults :>> ', searchResults);
    return [...searchResults.circuitInfoList, ...searchResults.emotionTourList, ...searchResults.offRoadTourList];
  }

  public checkIsCircuits(type: string): boolean {
    const circuitList: string[] = [EALL_TRACKS.CIRCUIT, EALL_TRACKS.ALL];
    return circuitList.includes(type)
  }

  public checkIsEmotionTour(type: string): boolean {
    const emotionTourList: string[] = [EALL_TRACKS.EMOTION_TOUR, EALL_TRACKS.ALL];
    return emotionTourList.includes(type);
  }

  public checkIsOffroad(type: string): boolean {
    const offroadList: string[] = [EALL_TRACKS.OFF_ROAD, EALL_TRACKS.ALL];
    return offroadList.includes(type) && this._translationService.featureFlags.locations.enableoffroadfeature;
  }

  public getToastErrMessagesBasedOnCode(code: string): string {
    let TOAST_MESSAGES = LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS;
    switch(code) {
      case '1111':
        return TOAST_MESSAGES.CIRCUIT_GUID_INVALID;
      case '1112':
        return TOAST_MESSAGES.INVALID_VALUE_ENETERED;
      case '1411':
        return TOAST_MESSAGES.TRACK_EXISTS_MSG;
      case '6005':
        return TOAST_MESSAGES.INVALID_COORDINATES;
      case '6006':
        return TOAST_MESSAGES.INVALID_CIRCUIT_TYPE;
      case '6010':
        return TOAST_MESSAGES.INVALID_GEOFENCE;
      case '6011':
        return TOAST_MESSAGES.GEO_FENCE_OVERLAP_ERR;
      case '6015':
        return TOAST_MESSAGES.CIRCUIT_CREATE_ERR;
      case '6019':
        return TOAST_MESSAGES.ADDRESS_NOT_FOUND;
      case '1010':
        return TOAST_MESSAGES.CIRCUIT_NAME_REQ;
      case '1011':
        return TOAST_MESSAGES.CIRCUIT_COORDINATES_REQ;
      case '1012':
        return TOAST_MESSAGES.CIRCUIT_TYPE_REQ;
      case '1014':
        return TOAST_MESSAGES.TOUR_DOES_NOT_EXIST;
      case '1117':
        return TOAST_MESSAGES.MAX_ONE_MEDIA_FILE;
      case '6017':
        return TOAST_MESSAGES.ET_INVALID_GUID;
      case '1418':
        return TOAST_MESSAGES.ET_INVLAID_TRACK_FORMAT;
      case '1420':
        return TOAST_MESSAGES.ET_GUID_REQ;
      case "1615":
        return TOAST_MESSAGES.ET_TOUR_ID_REQ;
      case "1438":
        return TOAST_MESSAGES.ET_NAME_REQ;
      default:
        return APP_CONSTANTS.COMMON_ERROR_MSGS.SOMETHING_WENT_WRONG_MSG;
    }
  }
}
