import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthAmplifyService } from 'src/app/shared/service/auth-amplify.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,private authAmplify:AuthAmplifyService) {
    
   }

  ngOnInit(): void {
    this.authAmplify.logout()
  }

}
