
<div id="explore-widget-main" *ngIf="completeList && !loadMoreSpinner" class="cluster-container-style">
  <ng-container *ngIf="completeList.length > 0">
  <div id="explore-list-region">
    <div class="list-parent-class" id="cluster-list">
      <ul>
        <li *ngFor="let clustername of completeList; let i = index" class="cluster-names">
          <div *ngIf="clustername.guid">
            <app-cluster-card [index]="i" [access_Token]="access_Token" [clustername]="clustername" (sendMouseStatus)="getsentMouseStatus($event, clustername)"
              (displayTrackDetails)="displayTrackDetails($event)">
            </app-cluster-card>
          </div>
  
        </li>
      </ul>
    </div>
  </div>
  </ng-container>
  <ng-container *ngIf="completeList.length === 0 && !showSpinner">
    <h6>{{'BP_EM_NCR' | customTranslation: translationData}}</h6>
  </ng-container>
  <div *ngIf="showSpinner" id="spinner"><span class="spinner-circle"></span></div>
</div>
<div *ngIf="loadMoreSpinner" id="spinner"><span class="spinner-circle"></span></div>

