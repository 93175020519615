import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataServiceServiceProfile {

  API_BASE_URL: string;
  access_token: string | null;
  api_header: any;
  qrCode_api_header: any;
  infoUrl:string='';
  constructor(
    private http: HttpClient
  ) {
    this.API_BASE_URL = environment.baseURL;
    this.access_token = localStorage.getItem('Token');
   }

  getAliasList(){
    const url=`${this.API_BASE_URL}/api/v2/profile`
    return this.http.get(url);
  }
  getMemberList(groupName:any){  // neds to be checked BE
    const url=`${this.API_BASE_URL}/api/v2/profile/members?groupname=${groupName}`
    return this.http.get(url);
  }

  switchProfile(payload?:any,isSwitched?:boolean,profileNeedToSwitch?:any){
    if(payload){
    const url=`${this.API_BASE_URL}/api/v2/profile`
    return this.http.post(url,payload);
    }else{
      const url=`${this.API_BASE_URL}/api/v2/profile?switchprofile=${isSwitched}&updatetogroup=${profileNeedToSwitch}`
      return this.http.post(url,{});
  }
    }
    
  getCarDetails(vinNumber:string){
    const url=`${this.API_BASE_URL}/api/v2/profile/cars/${vinNumber}/info`
    return this.http.get(url);
  }

  addCarToFleet(reqBody:any){
    // send profileId in headers
    const url=`${this.API_BASE_URL}/api/v2/profile/cars`
    return this.http.post(url,reqBody);
  }
  
  getFleetList(): Observable<any>{
    // send profileId in headers
    const url=`${this.API_BASE_URL}/api/v2/profile/cars`
    return this.http.get(url);
  }

  updateProfile(aliasFormData:any,selectedFile:any){
    
    const formData=new FormData()
    formData.append('data',
    new Blob([JSON.stringify(aliasFormData)])
    );
    formData.append('profileImage',selectedFile)
    const url=`${this.API_BASE_URL}/api/v2/profile/manage`
    return this.http.put(url,formData);
  }

  deleteCarFromFleet(vin:string){
    const url=`${this.API_BASE_URL}/api/v2/profile/cars/${vin}`
    return this.http.delete(url);
  }
  
}
