<ng-template #showModalContent>
  <form
  [formGroup]="addMemberForm"
  (ngSubmit)="onSubmit()"
    class="email-form"
    id="email-form"
    action=""
  >
    <label for="email" class="email">Email</label>
    <input type="email"
    class="email"
    [ngClass]="{'input-text required': formSubmitted && addMemberForm.value.email.errors?.required, 'not-empty': addMemberForm.value.email.length}"
    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" formControlName="email" />
  </form>
</ng-template>

<app-common-modal
  [isCloseModal]="isCloseModal"
  [closeButtonTitle]="closeButtonTitle"
  [saveButtonTitle]="saveButtonTitle"
  [showModalContent]="showModalContent"
  (closeModalEmitter)="closeModal()"
  (saveModalEmitter)="saveModal()"
  [type]="type"
  *ngIf="showModal"
>
</app-common-modal>
