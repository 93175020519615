import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { AuthAmplifyService } from 'src/app/shared/service/auth-amplify.service';
import { TranslationService } from 'src/app/shared/service/translation.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit, OnDestroy {

  constructor(
    private authAmplifyService:AuthAmplifyService,
    private cdr:ChangeDetectorRef,
    private translationService:TranslationService
  ) { }
  
  type:string='medium-popup';
  showModal:boolean=true
  userName:any
  timer:number=30
  subscriprions:Subscription[]=[];
  translationData:any
  @ViewChild("showModalContent") showModalContent: TemplateRef<any> | undefined;
  ngOnInit(): void {
    this.subscriprions.push(this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
      }));
    this.userName=localStorage.getItem('userName')
    this.setTimer()
  }

  setTimer(){
    this.subscriprions.push(timer(0,1000).subscribe((data:any)=>{
     this.timer--;
     this.cdr.detectChanges()
     if(this.timer==0){
      this.authAmplifyService.logout()
     }
    }))
  }

  ngOnDestroy(): void {
    this.subscriprions.forEach((sub)=>{
      sub.unsubscribe()
    })
  }



}
