import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';

@Component({
  selector: 'app-cluster-card',
  templateUrl: './cluster-card.component.html',
  styleUrls: ['./cluster-card.component.scss']
})
export class ClusterCardComponent {

  @Input() public clustername:any;
  @Input() public index:any;
  @Output() public sendMouseStatus = new EventEmitter<any>();
  sampleDesc: string = '';
  imageData: any;
  @Input() public access_Token: any;
  @Output() displayTrackDetails = new EventEmitter<any>();
  translationData: any;
  public clusterTags = LOCATIONS_CONSTANTS.CLUSTER_TAGS;

  constructor(private _translationService: TranslationService){}

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  public addHeightAnimation(): void {
    this.sendMouseStatus.emit('mouseover');
  }

  public removeHeightAnimation(): void {
    this.sendMouseStatus.emit('mouseout');
  }

  public loadClusterDetailsOnClk(selectedCluster: any): void {
      selectedCluster['isSearch'] = true;
      selectedCluster['viewType'] = selectedCluster.type;
      this.displayTrackDetails.emit(selectedCluster);
    
  }
}
