
<div *ngIf="isMediaDisable" class="image-upload-lbl">
    <span *ngIf="currentStep === 'ADD_WAY_POINT_DETAILS'; else tourGenerate">{{commonLables.ADD_MEDIA| customTranslation :translationData }}</span>
    <ng-template #tourGenerate>
        <span>{{commonLables.ADD_COVER_IMG| customTranslation :translationData }}</span>
    </ng-template>
    <span *ngIf="markMediaFieldsAsRequired">*</span>
</div>
<div class="media-area-style" >
    <div class="upload-media" for="fileField" id="add-media">
        <div class="image-upload" >

            <div class="add-img" *ngIf="isMediaDisable">
                <label for="file-input">
                    <span class="upload-icon image-upload-style"></span>
                </label>
                <input #myFileInput id="file-input" type="file" (change)="uploadMediaOnClk($event)" multiple="multiple"
                    accept=".jpg, .jpeg, .png, .gif,.mp4" />
            </div>
            <ng-container>
                <div class="media-container"
                    *ngIf="(mediaFiles && mediaFiles.length>0)">
                    <div *ngFor="let mediaFile of mediaFiles; let i = index" class="mediafile"
                        [ngClass]="{'remaining-med': i===2 && mediaFilesOverlay && mediaFilesOverlay.length>0}">
                        <span *ngIf="(i===2 && ((mediaFilesOverlay && mediaFilesOverlay.length>0) || showOverlayFlag))">+
                            {{mediaFilesOverlay.length}}</span>
                        <div class="img-tile-item">
                            <img [src]="mediaFile | safe" id="thumbnail-{{i+1}}" alt="'thumbnail'">
                            <span class="icon icon_input_close rmv-image" (click)="removeImage(i)"
                                *ngIf="(!mediaFilesOverlay?.length ? i <= 2: i < 2)"></span>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>