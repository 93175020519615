<ng-container>
    <div class="toast-style" *ngIf="showToast">
        <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition" >
        </app-toast>
    </div>
    <div class="web-portal-container">
        <div class="dealer-flow-parent-class">
            <div class="map-container-main">
                <div class="map-pane">
                    <div id="map" #mapElement [ngClass]="{'action-icon-over-map': (showAddIconMapHover && !displayPublishedDetails)}">
                    </div>
                </div>

                <div>
                    <div *ngIf=" this.tourPublishStage == false;  else tourPublishedScreen" >
                    <span class="recenter icon icon_recenter" title="Recenter" (click)="recentreToUserLoc(this.mapCoordinates, 'RECENTER_MAP', this.currentStep)"></span>
                    </div>
                    <ng-template #tourPublishedScreen>
                        <span class="recenter icon icon_recenter" title="Recenter" (click)="recenterToTourPublished()"></span>

                    </ng-template>
                   
                    <button [ngClass]="{'button-satellite-layer': selectedLayer === 'normal', 'button-normal-layer': selectedLayer === 'satellite'}" 
                    class="switch-button-style selected-layer" (click)="switchMapView()"
                    title="{{selectedLayer === 'normal' ? 'Satellite': 'Map'}}"></button>
                  </div>
            </div>
            <div class="dealerflow-create-modal">
                <app-create-dealerflow-modal
                [currentStep]="currentStep"
                (selectedLocationName)="getSelectedLocationName($event)"
                [searchResult]="locationOnMap"
                [editedPinIndex]="editedPinIndex"
                [disableGenerateButton]="disableGenerateButton"  
                [tourPublished]="tourPublishStage"              
                (sendHighlightStep)="sendHighlightStep($event)"
                (sendSearchClosed)="sendSearchClosed($event)"
                (sendEditedData)="clearMapAndAddEditedPoint($event)"
                (sendWaypointDetails)="addWaypointDetailsToArray($event)"
                (sendStartInfo)="sendStartInfo($event)"
                (sendDestinationInfo)="sendDestinationInfo($event)"
                (callFetchCategories)="fetchCategories()"
                (sendTourInfo)="sendTourInfo($event)"
                (initiateTourPublish)="initiateTourPublish($event)"
                (cancelCurrentWaypoint)="cancelCurrentWaypoint($event)"
                (abortDataSendOnClick)="abortDataSendOnClick($event)"
                (uploadedTourId)="this.publishgpxUploadedTour($event)"
                (sendEditFlowTrigger)="getEditFlowTrigger($event)"
                [disablePublishButton]="disablePublishButton"
                [displayPublishedDetails]="displayPublishedDetails"
                [tourInfoPostPublish]="tourInfoPostPublish"
                [coverImg] ="coverImg"
                (cancelButtonClicked)="cancelButtonClicked($event)"
                [editActive]="editActive"
                [editHighlightPin]="editHighlightPin"
                [editedHighlightInfo]="editedHighlightInfo"
                [disableUpdateBtn]="disableUpdateBtn"
                [autoAnnotate] = "autoAnnotate"
                (sendDeleteCardFlowTrigger)="getDeleteCardFlowTrigger($event)"
                ></app-create-dealerflow-modal>                
            </div>
            <div class="dealerflow-carousel">
                <app-dealer-flow-carousel 
                (sendRemovedTour)="getRemovedTour($event)"
                [gpxUploaded]="gpxUploaded"
                [tourPublishStage]="tourPublishStage"
                [currentStep]="currentStep" 
                (sendHighlightStep)="sendHighlightStep($event)"
                (sendEnableStatus) = "getEnableStatusForEdit($event)"
                [waypointsData]="waypointsData"
                [startPointData]="startPointData"
                [destinationPointData]="destinationPointData"
                [annotationDetails]="annotationData"
                [showLoaderUntillGeoJsonCompletes]="showLoaderUntillGeoJsonCompletes"
                [annotationDataSetArray]="annotationDataSetArray"
                [displayPublishedDetails]="displayPublishedDetails"
                (selectedAnnotation)="selectedAnnotationActionOnMap($event)"
                [selectedAnnotationPinFromMap]="selectedAnnotationPinFromMap"
                [emotionTourTrackencodedGpsPoints]="emotionTourTrackencodedGpsPoints"
                [deleteEditedPin]="deleteEditedPin"
                [isCuratedTour]="categories?.curatedTour"
                [disableEdit]="disableEdit"
                [addEditedDetails]="addEditedDetails"
                [editAmgAnnotationLoader]="editAmgAnnotationLoader"
                [autoAnnotate] = "autoAnnotate"
                [editAfterPublishActive]="editAfterPublishActive"
                [initiateWaypointSoftDelete]="initiateWaypointSoftDelete"
                >
                </app-dealer-flow-carousel>
            </div>
        </div>
    </div>
    <div *ngIf="showCategories" class="category-overlay-class" [ngClass]="{'overlay-hide': modalType === 'loader'}">
        <app-dealer-categories 
        [modalType]="modalType"
        [showLoader]="showLoader"
        (sendSelectedCategory)="getSelectedCategory($event)"
        (backClicked)="backClicked($event)"
        [categoryList]="categories?.journey_categories">
        </app-dealer-categories>
    </div>
</ng-container>
<app-alert-modal></app-alert-modal>
<div *ngIf="displayFleetManagement">
    <app-fleet-management [tourFormDetails]="tourFormDetails" [mediaFiles]="mediaFiles" (callGeoJsonApi)="callGeoJsonApi($event)" [isEditMode]="currentStep === 'EDIT_AFTER_PUBLISH'"></app-fleet-management>
</div>
