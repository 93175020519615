import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';

@Component({
  selector: 'app-geofence-toolbar',
  templateUrl: './geofence-toolbar.component.html',
  styleUrls: ['./geofence-toolbar.component.scss']
})
export class GeofenceToolbarComponent implements OnInit, OnChanges {

  @Input() notificationMessage : any = '';
  @Input() enableRadiusInputBox= false;
  @Input() selectedButton: any = 'polygon';
  @Input() radius: any = 0;

  @Output() public selectedTool = new EventEmitter<object>();
  @Output() public radiusValue = new EventEmitter<object>();

  selectedOption:any = 'polygon';
  prevValue:any = 'polygon';
  translationData: any;
  public locationConstants = LOCATIONS_CONSTANTS;

  constructor(private _translationService: TranslationService) {}

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe((translationData: any) => {
      this.translationData = translationData;
    });
  }

  ngOnChanges(changes: SimpleChanges) { 
    if(changes.selectedButton && changes.selectedButton.currentValue.substring(0,4) !== 'clea'&& changes.selectedButton.currentValue !== ''){
      this.prevValue = changes.selectedButton.currentValue;
      this.selectedOption = changes.selectedButton.currentValue;
    }   
    if(changes.selectedButton && changes.selectedButton.currentValue.substring(0,4) === 'clea') {
      if(changes.selectedButton.previousValue.substring(0,4) !== 'clea' && changes.selectedButton.previousValue !== ''){
        this.selectedOption = changes.selectedButton.previousValue;
      } else {
        this.selectedOption = this.prevValue;
      }
      
    }
  }

  optionSelected(option:any) {
    this.selectedTool.emit(option);
  }

  sendRadiusValue(_radius:any) {
    this.radiusValue.emit(this.radius);
  }

}
