<div class="modal" *ngIf="isModalOpen" tabindex="-1" role="dialog" [ngClass]="isModalOpen ? 'open-modal' : 'close-modal'">
  <div role="document">
    <div class="modal-content modal-box">
      <div class="modal-header header-section">
        <div class="header-title">{{ trackName }}{{ mediaCount }}</div>
        <div class="button-region">
          <div *ngIf="enableSpinner" class="spinner-container">
            <div class="spinner-border publish-spinner" role="status"></div>
          </div>
          <ng-container *ngIf="imageUrls && imageUrls.length > 0">
            <button class="beta" (click)="deleteMedia()" *ngIf="isEditClicked && !enableSpinner"  >
              <span>Delete <span *ngIf="deleteMediaCount" id="delete-media-count"> {{ deleteMediaCount }}</span></span> 
              
            </button>
          </ng-container>
          <button type="button" class="hallow" (click)="closeModal()" aria-label="Close" >
            <span class="icon icon_close"></span>
          </button>
        </div>
      </div>
      <div class="modal-body body">
        <div class="lt-row img-gal-box">
          <div class="add-media-section">
            <div class="media-section">
              <app-media-upload
                [uploadEnable]="isEditClicked"
                [trackName]="trackName"
                [isFromPage]="isFromPage"
                [trackNameGuid]="this.trackNameGuid"
                [getSelectedMediaIndex]="selectedImgIndex"
              ></app-media-upload>
            </div>
          </div>
          <div class="gallery-section">
            <div
              id="carouselControls"
              class="carousel slide carousel-container"
              data-bs-ride="carousel"
              data-bs-interval="false"
              #carousel
            >
              <div class="carousel-indicators"></div>
              <div class="carousel-inner carousel-image">
                <div
                  class="carousel-item image"
                  [ngClass]="i === selectedImgIndex ? 'active' : ''"
                  *ngFor="let imageUrl of imageUrls; let i = index"
                  data-bs-interval="false"
                >
                  <app-blob-image
                    [url]="imageUrl"
                    imageType="fullscreen"
                    id="{{'full-screen' + i}}"
                  ></app-blob-image>
                </div>
              </div>
              <ng-container *ngIf="imageUrls && imageUrls.length > 0">
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselControls"
                data-bs-slide="prev"
                (click)="getPreviousIndex()"
              >
                <span aria-hidden="true" class="right-arrow">
                  <img src="assets/locations/left-nav.png" alt=".."/>
                </span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselControls"
                data-bs-slide="next"
                (click)="getNextIndex()"
              >
                <span aria-hidden="true" class="left-arrow">
                  <img src="assets/locations/right-nav.png"  alt=".."/>
                </span>
                <span class="visually-hidden">Next</span>
              </button>
            </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showConfirmation">
    <app-common-alert-modal
      [isModalOpen]="isModalOpen"
      [title]="title"
      (isModalResponseClicked)="getModalResponse($event)"
      [message]="message"
      [cancelBtnTitle]="cancelBtnTitle"
      [actionBtnTitle]="actionBtnTitle"
    ></app-common-alert-modal>
  </div>
  <div class="toast-style" *ngIf="showToast">
    <app-toast
      [message]="toastMessage"
      [toastType]="toastType"
      [position]="position"
      [leftPosition]="leftPosition"
    >
    </app-toast>
  </div>
</div>
