<div class="modal" tabindex="-1" role="dialog" [ngClass]="isModalOpen ? 'open-modal' : 'close-modal'">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content alert-modal">
            <div class="title-body">
            <div class="title-container">
                <label class="modal-title title"> {{(title | customTranslation: translationData) ? (title | customTranslation: translationData): title }}</label>
            </div>
            <div class="body" [innerHTML]="(message | customTranslation: translationData) ? (message | customTranslation: placeholderObject :translationData): message">
                
            </div>
            <div class="line-seperator">

            </div>
            </div>
            <div class="footer-container">
                <div class="button-region">
                    <button *ngIf="isCancelVisible" type="button" class="delta" data-dismiss="modal" (click)="onCancel()"><span>{{(cancelBtnTitle | customTranslation: translationData) ? (cancelBtnTitle | customTranslation: translationData): cancelBtnTitle}}</span></button>
                    <button *ngIf="isConfirmVisible && actionBtnTitle" type="button" class="beta" data-dismiss="modal" (click)="onAction()"><span>{{(actionBtnTitle | customTranslation: translationData) ? (actionBtnTitle | customTranslation: translationData): actionBtnTitle }}</span></button>
                </div>
            </div>
        </div>
    </div>
</div>