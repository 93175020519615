import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AliasGroupMembersComponent } from './alias-group-members/alias-group-members.component';
import { AliasListComponent } from './alias-list/alias-list.component';
import { FleetsComponent } from './fleet-managment/fleets/fleets.component';
import { AuthGuard } from 'src/app/_helper/auth.gard';

export const routes: Routes = [
  {
    path:'',
    component:AliasListComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
    {
    path:'member-list',
    component:AliasGroupMembersComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path:'fleet',
    component:FleetsComponent,
    canActivate: [AuthGuard]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AliasRoutingModule { }
