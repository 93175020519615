<div>
  <div class="layout-title">{{locationConstants.HELPER_INPUT_LABELS.LAYOUT_DETAILS| customTranslation:translationData}}</div>
  <form *ngIf="layoutData" [formGroup]="form" (ngSubmit)="saveAndPublish()">
    <div class="form-row">
      <div class="text-label col-12">{{ locationConstants.HELPER_INPUT_LABELS.SET_DISPLAY_NAME| customTranslation:translationData}}</div>
      <div class="col-12">
        <input
          class="text-input"
          formControlName="name"
          value="{{ this.layoutData.name }}"
          [ngClass]="{ 'input-border': formEditable }"
          maxlength="50" 
        />
      </div>
    </div>
    <div class="form-row" *ngIf="showLayoutUrl">
      <div class="text-label  col-12">{{'BP_LM_LAYOUT_URL' | customTranslation:translationData}}</div>
      <div class="col-12 layoutURL-appearance">
        <span class="text-input url-text" >{{ this.layoutURL }}</span>
        <span  class="copy text-center text-md-center " (click)="copyText(this.layoutURL)" >
          <img src="assets/locations/combined-shape.svg" alt=".."/>
      </span>
      </div>
    </div>
    <div class="form-row">
      <div class="text-label col-4 middle-content">{{'BP_LM_LENGTH' | customTranslation:translationData}}
        <input
        class="text-input"
        formControlName="trackLength"
        value="{{ this.layoutData.trackLength }}"
        readonly
        disableUnderline
      />
      </div>
      <div class="text-label middle-content-right-section col-6">{{'BP_LM_SECTORS' | customTranslation:translationData}}
        <input
        type="number"
        class="text-input"
        formControlName="noOfSectors"
        value="{{ this.layoutData.noOfSectors }}"
        readonly
        disableUnderline
      />
      </div>
    </div>
      <div class="form-row">
          <div class="text-label middle-content col-4" [innerHTML]="('BP_CM_CREATED_BY' | customTranslation: {'{placeholderName}': this.layoutData.createdBy } : translationData)">
            
        </div>
          <div class="text-label middle-content-right-section col-4">{{'BP_CM_CREATED_ON' | customTranslation:translationData}}
          <input
            formControlName="createdDate"
            value="{{ this.layoutData.createdDate }}"
            readonly
            class="text-input"
            disableUnderline
          />
        </div>
      </div>
      <div class="form-row">
          <div class="text-label middle-content col-4">{{'BP_PM_MEMBER' | customTranslation: translationData }}
          <input
            formControlName="ciamId"
            name="ciamId"
            class="text-input"
            value=" {{ this.layoutData.ciamid }}"
            readonly
          />
        </div>
          <div class="text-label middle-content-right-section col-7">{{'BP_LM_TRK_GUID' | customTranslation: translationData }} (32bit)
          <input
            formControlName="guid"
            class="text-input"
            value="{{ this.layoutData.guid }}"
            readonly
          />
        </div>
      </div>
    <div class="field-top-padding" *ngIf="formEditable">
      <div class="layout-title" style="font-size: 1em">{{'BP_LM_SET_TRACK_TYPE' | customTranslation:translationData}}</div>
      <div class="content-margin-top">
        <div class="btn-group form-floating flex-display radio-padding" formControlName="visibility"
        value="this.layoutData.visibility">
        <div class="form-check radio-margin-right">
          <input class="form-check-input" type="radio" value="PERMANENT" name="type" formControlName="visibility" >
          <label class="form-check-label radio-label" for="flexRadioDefault1">
            {{'BP_LM_PERMANENT' | customTranslation: translationData }}
          </label>
      </div>
      <div class="form-check radio-margin-right">
          <input class="form-check-input" type="radio" value="TEMPORARY" name="type" formControlName="visibility">
          <label class="form-check-label radio-label" for="flexRadioDefault2">
            {{'BP_LM_TEMPORARY' | customTranslation: translationData }}
          </label>

      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" value="CLOSED" name="type" formControlName="type">
        <label class="form-check-label radio-label" for="flexRadioDefault3">
          Closed
        </label>

    </div>
    </div>
    </div>
    </div>
    <div class="field-top-padding" *ngIf="!formEditable">
      <div class="text-label">{{'BP_LM_TRACK_TYPE' | customTranslation:translationData}}</div>
      <div class="text-input">{{ this.layoutData.visibility | titlecase }}</div>
    </div>
    <div class="field-top-padding row">
        <div class="text-label col-12">{{appConstants.COMMON_LABELS.DESCRIPTION| customTranslation:translationData}}</div>
        <div class="col-12">
        <textarea
          formControlName="description"
          (keyup)="checkWordCount();textAreaAdjust()"
          value="{{ this.layoutData.description }}"
          [ngClass]="{ 'input-border': formEditable }"
          class="description-textarea text-input"
          maxlength="500" 
          id="description"
          rows="5"
        ></textarea>
        <div class="word-count" *ngIf="formEditable">{{wordCount}}/500 {{'BP_CM_CHARACTERS' | customTranslation:translationData}}</div>
      </div>
    </div>
    <ng-container *ngIf="showCopyrightData">
      <div formGroupName="copyright">
      <div class="layout-title">{{locationConstants.HELPER_INPUT_LABELS.IMAGE_COPYRIGHT_INFO| customTranslation:translationData}}</div>
      <div class="form-row">
          <div class="form-item material-underline full-w">
              <label>{{locationConstants.HELPER_INPUT_LABELS.IMAGE_ATTRIBUTION | customTranslation:translationData | sentencecase }}</label>
              <input type="text" formControlName="attribution" [ngClass]="{ 'input-border': formEditable }"
              class="text-input" value=""
              >
          </div>
          <div class="form-item material-underline full-w">
              <label>{{locationConstants.HELPER_INPUT_LABELS.IMAGE_LICENSE | customTranslation:translationData | sentencecase }}</label>
              <input type="text" formControlName="license" [ngClass]="{ 'input-border': formEditable }"
              class="text-input" value=""
            >
          </div>
          <div class="form-item material-underline full-w">
              <label>{{locationConstants.HELPER_INPUT_LABELS.LICENCE_URL | customTranslation:translationData | sentencecase }}</label>
              <input type="text" formControlName="license_url" [ngClass]="{ 'input-border': formEditable }"
              class="text-input" value=""
              >
          </div>
          <div class="form-item material-underline full-w">
              <label>{{locationConstants.HELPER_INPUT_LABELS.IMAGE_URL | customTranslation:translationData | sentencecase }}</label>
              <input type="text" formControlName="subject_url" [ngClass]="{ 'input-border': formEditable }"
              class="text-input" value=""
              >
          </div>
      </div>
      </div>
    </ng-container> 
  </form>
</div>
