import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';

@Component({
  selector: 'app-navigate-back-section',
  templateUrl: './navigate-back-section.component.html',
  styleUrls: ['./navigate-back-section.component.scss']
})
export class NavigateBackSectionComponent implements OnInit {

  @Input() navigateToUrl: any;
  @Input() navigateMessage: any = '';
  @Input() noPropagationCondition: any = true;
  @Input() isOpenModal: boolean = false;
  @Output() public isShowMapView = new EventEmitter<object>();
  @Output() public showPropagationCondition = new EventEmitter<object>();
  @Output() public showNavigationModal = new EventEmitter<any>();
  locatinBtnNames: any = LOCATIONS_CONSTANTS.BUTTON_NAMES;
  
  translationData: any;


  constructor(private router: Router, private translationService: TranslationService) { }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );
  }

  goToUrl() {
    if(this.isOpenModal) {
      this.showNavigationModal.emit();
    } else {
      if(this.navigateToUrl && this.noPropagationCondition){
        this.router.navigateByUrl(this.navigateToUrl);
      } else {
        this.showPropagationCondition.emit();
      }
      this.isShowMapView.emit({showSearch:true}); 
    }
  }

  async removeIndexedDb(){
    const dbs = await window.indexedDB.databases()
    dbs.forEach((db:any) => { window.indexedDB.deleteDatabase(db.name) })
  }

}