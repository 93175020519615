import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, finalize, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AwsCognitoService } from '../service/aws-cognito.service';
import { AuthenticationService } from '../service/authentication.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class TokenResolverService implements Resolve<any> {

  constructor(private location: Location,
              private awsCognitoService: AwsCognitoService, private authenticationService: AuthenticationService,
              private router: Router) { }

  resolve(): Observable<any | null> {

    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const code: string | null = urlParams.get('code');

    if (!code) {
      return of(null);
    }

    return this.getTokenDetailsFromCognito(code).pipe(
      finalize(() => {
        
      })
    );
  }

  getTokenDetailsFromCognito(code: string): Observable<any | null> {
    return this.awsCognitoService.getTokenDetailsFromCognito(code).pipe(
      switchMap((res: any) => {
        localStorage.setItem('access_token', res.access_token);
        localStorage.setItem('refresh_token', res.refresh_token);
        localStorage.setItem('id_token', res.id_token);
        localStorage.setItem('userLoggedIn', 'admin');

        this.authenticationService.login(res);
        let redirectUrl = localStorage.getItem('redirectUrl');
        if(redirectUrl) {
          this.router.navigate([redirectUrl]);
          localStorage.removeItem('redirectUrl');
        }else if (res) {
          let navUrl = environment.enableLocations? 'locations' : 'events';
          this.router.navigate([navUrl]);
        }

        return of(res);
      }),
      catchError ((error: any) => {
        console.log(`error dude ${error}`);
        return error;
      })
    );
  }
}
