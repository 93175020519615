import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LocationsHttpService } from '../../services/locations-http.service';
import { EALL_TRACKS, MapViewResponse } from '../../interfaces/search-location.interface';
import { CircuitsServicesService } from '../../services/circuits-services.service';
import { TranslationService } from 'src/app/shared/service/translation.service';

@Component({
  selector: 'app-clusters-list',
  templateUrl: './clusters-list.component.html',
  styleUrls: ['./clusters-list.component.scss']
})
export class ClustersListComponent implements OnInit {
  @Input() public clusterList: any[] = [];
  @Output() public sendHoverToMap = new EventEmitter<object>();
  @Input() public searchResults: MapViewResponse[] = [];
  @Input() public displaySpinnerForClusters: boolean = false;
  @Input() public selectedType: string = EALL_TRACKS.ALL;
  @Output() loadTrackDetailsPage = new EventEmitter<any>()
  loadMoreSpinner: boolean = false;
  totalElements: number = 0;
  translationData: any;

  constructor(private _locationService: LocationsHttpService, 
    private _circuitService: CircuitsServicesService,
    private _translationService: TranslationService) {}

  public completeList: any[] = [];
  public access_Token: any;
  public index: number = 0;
  public pageNumber: number = 0;
  public pageSize: number = 15;
  public totalPages = 1;
  public showSpinner:boolean = false;

  /**
   * @description called when load more is clicked, this calls search API with pagination
   * Initially pagenumber has to be 0 and incrementing by 1 for each load more click
   */
  public fetchNextResults(): void {
    this.index = this.index + 1;
    if (this.index <= (this.totalPages - 1)) {
      this.pageNumber = this.index;
      this.showSpinner = true;
    }
  }

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    // Yet to be updated, functionality in progress.
    if (this.clusterList.length > 0) {
      this.completeList = this.clusterList;
    }
    this.totalPages = this.completeList.length;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // add length check
    if (changes.clusterList?.currentValue.length) {
      this.completeList = this.clusterList;
      this.totalPages = this.completeList.length;
    } else {
      this.completeList = [];
    }
    this.showSearchResults(changes);
    this.showLoaderForClusters(changes);
    this.resetOnTypeChange(changes);
  }

  /**
   * @description Eachtime, we toggle between circuits, emotion tour and offroad,
   * We will reset our page number, page size to original value.
   * Note that pageSize need to start with 0 to get first results
   * @param changes 
   */
  public resetOnTypeChange(changes: SimpleChanges): void {
    if (changes.selectedType?.currentValue) {
      this.index = this.pageNumber = 0;
      this.pageSize = 15;
      this.totalPages = 1;
    }
  }

  public showSearchResults(changes: SimpleChanges): void{
    if (changes.searchResults) {
      this.clusterList = this.completeList;
      this.completeList = [...changes.searchResults.currentValue];
      this.showSpinner = false;
    }
  }

  public showLoaderForClusters(changes: SimpleChanges): void {
    if (changes.displaySpinnerForClusters) {
      this.clusterList = this.completeList;
      this.showSpinner = changes.displaySpinnerForClusters.currentValue
      this.loadMoreSpinner = changes.displaySpinnerForClusters.currentValue
    }
  }

  getsentMouseStatus(event: any, tourInfo: any) {
    const tourStatus = {
      mouseStatus: event,
      tourInfo: tourInfo
    }
    this.sendHoverToMap.emit(tourStatus);
  }

  displayTrackDetails(event: any): void {
    this.loadTrackDetailsPage.emit(event);
  }

}