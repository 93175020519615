<div class="modal open-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content container">
            <div class="modal-header title-container">
               <h6>{{title}}</h6> 
                <div class="button-region">
                   <button (click)="closeModal()" class="hallow">
                    <span>{{btnSecondary}}</span>
                   </button>
                   <button class="beta">
                    <span>{{btnPrimary}}</span>
                   </button>
                </div>
            </div>
            <div class="modal-body body">
                <div class="test">
               {{content}}
                </div>

            </div>            
        </div>
    </div>
</div>
