<ng-container *ngIf="modalType==='spinner'">
    <div class="category-container-spinner">
        <div class="spinner-container">
            <div class="spinner-border spinner loader"></div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="modalType==='category'">
    <div class="category-container">
        <div class="category-back-btn">
            <div (click)="sendBackClick()" class="button trans">
                <span class="icon icon_back_arrow arrow-alignmnent"></span><span>{{buttonNames.BACK|
                    customTranslation:translationData}}</span>
            </div>
            </div>
        <div class="choose-category-block" *ngIf="categoryList?.length > 0">
            <h2 class="category-main-title">{{'BP_LM_PICK_CTGRY' | customTranslation:translationData}}</h2>
            <div class="category-sub-message">{{'BP_LM_DRIVE_EXPRIENCE' | customTranslation:translationData}}</div>
            <div class="category-images-container" *ngIf="categoryList?.length !== 0">
                <div class="category-item" *ngFor="let item of categoryList">
                    <div (click)="sendCategory(item, false)" class="category-images"
                        [ngStyle]="{'background-image':'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.63)),url(' + item.backgroundImage+ ')'}">
                    </div>
                    <div class="category-title">
                        <span>{{ formatCategoryTitle(item.categoryTitle) | customTranslation: translationData |titlecase }}</span>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="isCuratedTourEnabled && categoryList?.length > 0">
            <span class="or-label">{{'BP_LM_OR' | customTranslation: translationData}}</span>
            <div class="df-wd-button-reg-main">
                <div class="button-region">
                    <button type="button" class="alpha cancel-button" (click)="sendCategory(null, true)">
                        <span>{{'BP_LM_CUTARED_TOUR_BTN_LABEL' | customTranslation: translationData}}</span>
                        <span class="icon icon_fwd_arrow"></span>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
