import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocationsHttpService } from "../../services/locations-http.service";
import { CircuitListService, TrackLayoutAction } from "../../services/circuit-list/circuit-list.service";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { APP_CONSTANTS } from "src/app/shared/constants/constants";
import { LOCATIONS_CONSTANTS } from "../../interfaces/locations-constants";
import { CircuitsServicesService } from "../../services/circuits-services.service";

@Component({
  selector: "app-trackupload",
  templateUrl: "./trackupload.component.html",
  styleUrls: ["./trackupload.component.scss"]
})
export class TrackuploadComponent implements OnInit {
  translationData: any;
  trackType: string = 'offroad-trail';
  hideHeadersConditionally: boolean = true;
  constructor(
    private locationsHttpService: LocationsHttpService,
    private router: Router,
    private circuitListService: CircuitListService,
    private toastService: ToastService,
    private _translationService: TranslationService,
    private _circuitService: CircuitsServicesService,
    private _actRoute: ActivatedRoute
  ) {}
  @ViewChild("fileField") fileField: any;
  progress: number = 0;
  eventTotal: number = 100;
  showProgress = false;
  showSuccessDailog = false;
  progressTimeInterval: number = 100;
  @Output() closeModal = new EventEmitter<any>();
  @Input() public isShowModal:boolean=false;
  @Input() public page:any;
  @Input() public heading:string = 'Add a new layout';
  trackDetails: any;
  browse:string=APP_CONSTANTS.COMMON_BUTTON_LABELS.BROWSE
  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  addprogressBar() {
    if(this.page === 'emotiontourCreate') {
      this.progressTimeInterval = 5000;
    }
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        this.progress += Math.round(this.eventTotal / 2);
        this.eventTotal /= 2;
        if (this.progress >= 100) {
          this.progress = 100;
          clearInterval(progressInterval);
        } else if (this.progress == 0) {
          this.addprogressBar();
        }
      }, this.progressTimeInterval);
    }, this.progressTimeInterval);
  }

  async fileChange(file: any) {
    this.trackType = this._actRoute.snapshot.params['trackType'];
    const allowedExtensions = ["gpx", "zip", "kml"];
    let fileExtension: any;

    
    if (file.target) {
      file = file.target.files[0];
      this.fileField.nativeElement.value = "";
    }
    fileExtension = file.name.split(".");
    if (allowedExtensions.includes(fileExtension[fileExtension.length - 1])) {
      this.hideHeadersConditionally = (this.trackType !== 'offroad-trail' && this.trackType !== 'business-tour')
      this.isShowModal = true;
      this.showProgress = true;
      this.progress = 0;
      this.addprogressBar();
      this.uploadGpxFile(file);
    } else {
      this.toastService.show(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.WRONG_FILE_UPLOADED_ERR, '8%','45%');
      setTimeout(() => {
        this.toastService.hide();
      }, LOCATIONS_CONSTANTS.MIN_TIME);
    }
  }

  hideModal(){
    this.progress = 0;
    this.showProgress = false;
    this.closeModal.emit({showModal:false});
  }
  uploadGpxFile(file: any) {
    this.locationsHttpService.uploadTrack(file).subscribe({
      next: (data) => {
        if (data?.guid) {
          this.locationsHttpService
            .getTrackDetails(data.guid)
            .subscribe((trackData) => {
              this.trackDetails = trackData;
              this.trackDetails.guid = data.guid;
              this.locationsHttpService.getLatestTrackDetails(
                this.trackDetails
              );
              if (this.trackDetails.guid) {
                this.showProgress = false;
                this.showSuccessDailog = true;
                setTimeout(() => {
                  this.closeModal.emit({showModal:false,gpxuploaded:true,uploadedguid:trackData.guid,uploadedName:trackData.name, trackLength:trackData.trackLength});
                  if (this.page === 'emotiontourCreate') {
                    console.log('trackData', trackData)
                  } else {
                    this.circuitListService.trackLayoutAction.next(TrackLayoutAction.New);
                    this.router.navigateByUrl(
                      `locations/circuit/${trackData.circuitGuid}/layout/${trackData.guid}`
                    );
                  }
                  
                }, 200);
              }
            });
        }
      },
      error: (error) => {
        console.log(error, "erroe");
        const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error.error['error-code']);
        this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
        setTimeout(() => {
          this.toastService.hide();
        }, APP_CONSTANTS.MIN_TIME_TOASTER);
        this.progress = 0;
        this.eventTotal = 100;
        this.showProgress = false;
        // this.isShowModal = false;
        this.hideModal();
      },
    });
  }
}