import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, delay, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public translatedArray: any = [];
  public languageType: string = '';
  public translationContent = new BehaviorSubject<any>(this.translatedArray);
  public sharetranslationData = this.translationContent.asObservable();
  public languageContent = new BehaviorSubject<any>(this.languageType);
  public sharelanguageType = this.languageContent.asObservable();
  private _featureFlags: any = null;
  private _supportedLanguagesForDisplay: any[] = [];
    supportedLanguages: any;

  constructor(private httpClient: HttpClient) { }
  sendtranslationValue(value: any) {
    this.translationContent.next(value);
  }
  sendSelectedLanguage(value: any) {
    this.languageContent.next(value);
  }

  setTranslationData(data: any) {
    this.translatedArray = data;
  }
  getTranslationData() {
    return this.translatedArray;
  }

  getTranslationDataAsObservable() : Observable<boolean> {    
      return this.translationContent.asObservable();
  }

  public set featureFlags(flags: any) {
    this._featureFlags = flags;
  }

  public get featureFlags() {
    return this._featureFlags;
  }

  public getAllLanguages(): Observable<any> {
    //https://api.phrase.com/v2/projects/34c48f0fd4a5cb7a8fe4f772a4c4b7fd/locales
    return this.httpClient.get(`https://api.phrase.com/v2/projects/34c48f0fd4a5cb7a8fe4f772a4c4b7fd/locales?access_token=9c15ce1695ebdb090d4b805ebf953b282b6fabdae03ad2555a42adc034c74553`);
  }

  /**
   * @description To be replaced by actual API. Gives list of all supported languages
   * @returns list of languages
   * !IMPORTANT: While testing with actual API, ensure error scenarios are also handled
   */
  public getAllSupportedLanguages(): Observable<any> {
    const res = require('../../shared/components/language-selection/language-list.json');
    return of(res).pipe(delay(800));
  }

  public set supportedLanguagesForDisplay(languages: any[]) {
    const defaultLangs = ['en', 'de'];
    const langCodes = languages.map(lang => lang.localeCode);
    langCodes.push(...defaultLangs);
    this.setSupportedLanguageByCode(langCodes);
    this._supportedLanguagesForDisplay = languages;
  }

  public get supportedLanguagesForDisplay(): any[] {
    return this._supportedLanguagesForDisplay;
  }

  phraseApiCallForTranslations(languageId: string): Observable<any> {
    let endPoint: any = environment.phraseUrl;
    endPoint = endPoint.replace('@languageId', languageId)
    let page1 = this.httpClient.get(`${endPoint}&page=1&per_page=100`);
    let page2 = this.httpClient.get(`${endPoint}&page=2&per_page=100`);
    let page3 = this.httpClient.get(`${endPoint}&page=3&per_page=100`);
    let page4 = this.httpClient.get(`${endPoint}&page=4&per_page=100`);
    let page5 = this.httpClient.get(`${endPoint}&page=5&per_page=100`);
    let page6 = this.httpClient.get(`${endPoint}&page=6&per_page=100`);
    let page7 = this.httpClient.get(`${endPoint}&page=7&per_page=100`);
    
    return concat(page1, page2, page3, page4, page5, page6, page7);
  }

  public getAllFeatureFlags(): Observable<any> {
    let featureFlags = require('../../shared/feature-toggle/feature-toggle.prod.json')
    if (environment.environment==='dev') { 
      featureFlags = require('../../shared/feature-toggle/feature-toggle.json');
    }else if(environment.environment==='int'){
      featureFlags = require('../../shared/feature-toggle/feature-toggle.int.json');
    }
    return of(featureFlags).pipe(delay(800));
  }

  public setSupportedLanguageByCode(languages: string[]) {
    this.supportedLanguages = languages;
  }

  public getSupportedLanguageByCode(): string[] {
    this.supportedLanguages = this.supportedLanguages ?? ['en-GB', 'de-DE'];
    return this.supportedLanguages;
  }
}
