import { Location } from '@angular/common';
import * as L from "leaflet";
import "leaflet.markercluster";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  SimpleChanges,
  HostListener,
} from "@angular/core";
import { LocationsHttpService } from "../../services/locations-http.service";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import "@geoman-io/leaflet-geoman-free";
import { CircuitsServicesService } from "../../services/circuits-services.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LOCATIONS_CONSTANTS } from "../../interfaces/locations-constants";
import { CreateMapService } from "../../services/create-map.service";
import { EALL_TRACKS, ELOCATION_SCREENS } from "../../interfaces/search-location.interface";
import { APP_CONSTANTS } from "src/app/shared/constants/constants";
import { TranslationService } from 'src/app/shared/service/translation.service';
declare let H: any;
let polyline = require("google-polyline");
@Component({
  selector: "app-here-map",
  templateUrl: "./here-map.component.html",
  styleUrls: ["./here-map.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HereMapComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("map") public mapElement!: ElementRef;
  @Input() public apiKey: any;
  @Input() public width: any;
  @Input() public isShowCircuitInfo: any = false;
  @Input() public isShowTrackInfo: any = false;
  @Input() public trackInfo: any;
  @Input() public sectorInfo: any;
  @Input() public circuitInfo: any;
  @Input() public emotionTourInfo: any;
  @Input() public offRoadTourList: any;
  @Input() public circleCoordinates: any;
  @Input() public radius: any;
  @Input() public height: any;
  @Input() public isFullScreenPolyline = false;
  @Input() public istrackDetailsPolyline = false;
  @Input() public isCircuitDetailsModal = false;
  @Input() public selectedType = "";
  @Input() public isRadiusUpdated = false;
  @Input() public emotionTourTrackencodedGpsPoints: any;
  @Input() public isCircuitDetailsPage: any = false;
  @Input() public backToLocationsFromCircuitDetails: any = false;
  @Input() public backToLocationsFromEmotionTour: any = false;
  @Input() public emotionTourDetails: any;
  @Input() public trackLength = 0;
  @Input() public isShowEmotionTour: any = false;
  @Input() public searchLocation: any = false;
  @Input() public deletedCircuitGuid: any;
  @Input() public isShowAddCircuitSearch: any = false;
  @Input() selectedOption: any = "";
  @Input() setZoomInLocation: any = "";
  @Output() public showLoader = new EventEmitter<object>();
  @Output() public pannedCircuitData = new EventEmitter<object>();
  @Output() public selectedLocation = new EventEmitter<object>();
  @Output() public showLocationInfo = new EventEmitter<object>();
  @Output() public disableSearchBar = new EventEmitter<object>();
  @Output() public selectedAnnotationObj = new EventEmitter<object>();
  @Input() public selectedAnnotationsId: any;
  @Input() public selectedLocationAddress: any;
  @Input() public radiusValueFromTool: any;
  @Output() emitRadius = new EventEmitter<any>();
  emitNotification = new EventEmitter<any>();
  @Output() enableRadiusBar = new EventEmitter<any>();
  @Input() public clearDrawnItems: any;
  @Input() public selectedUserType: any = EALL_TRACKS.ALL;
  @Output() backToHome = new EventEmitter<any>();
  @Input() showLayerSwitchMenu: any = true;
  @Input() recenterlocation: any;

  private platform: any;
  private map: any;
  private polyline: any;
  public activeNoiseIcon: any;
  private barIcon: any;
  private barWidth = 40;
  private barHeight = 40;
  private barMargin = -15;
  private startLineWidth = 50;
  private startLineHeight = 50;
  public CircuitRadius = 0;
  public clusteringLayer: any;
  public removedLayers: any = [];
  private isMapPanned = true;
  public isMapZoomed = false;
  public isMarkerClicked = false;
  public zoomControl: any;
  public zoomlevel: any = [];
  public deletedLayers: any = [];
  public deletedSectorPoints: any = [];
  public dummyLayers: any = [];
  public geoSearch: any;
  public previousMarker: any = [];
  public spiderfyMarkers: any = [];
  public isApiSuccess: any = false;
  public CustomIcon: any;
  public CustomMarker: any;
  public selectedGeoPoints: any = "";
  public coordinatesInProgress: any;
  public inputText: any = "";
  public locateUserLocation: any = "";
  public myclassName: string = '';
  coordinates: any;
  shapes: any = [];
  geofence: any;
  circle: any;
  polygon: any;
  polyCoordinates: any;
  initialCircle: any;
  drawnItems = L.featureGroup();
  encodedDetails: any;
  circleGeoCoordinates: any;
  myTextLabel: any;
  myCircleTextLabel: any;
  undoArray: any = [];
  redoArray: any = [];
  undoGeofence: any;
  undoCircle: any;
  varRadius: any;
  myTempCircleTextLabel: any;
  progressPolygon: any;
  interactiveRadiusLength: any;
  sessionRadius$: any;
  drawEnableCount: any = 1;
  newpolycoordinates: any = [];
  public EmotionTourMarkers: any;
  previousOption: any;
  mapZoom: number = LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_CURR_LOC;
  mapInitialCoordinates: any = [52.520007, 13.404954];
  initialCoordinates: any = {
    coords: {
      latitude:52.520007,
      longitude: 13.404954
    }
  };
  layerControl: any;
  normalView: any;
  satelliteView: any;
  selectedLayer: string = 'normal';
  mapCoordinates: any;
  streatViewZoomlevel: number = LOCATIONS_CONSTANTS.STREET_LEVEL_ZOOM;
  public url1: any;
  screenChanged = false;
  tracklen: any;
  selectedLanguage: string;
  enableSatelliteView: boolean = false;
  enableRecenterFeature: boolean = false;
  public circlularCircuit: any = null;
  
  constructor(
    private locationsHttpService: LocationsHttpService,
    private toastService: ToastService,
    private circuitService: CircuitsServicesService,
    private _actRoute: ActivatedRoute,
    private _createMapService: CreateMapService,
    private router: Router,
    private _translationService: TranslationService,
    private location: Location
  ) {
    let selectedLanguage = localStorage.getItem('language');
    if (!selectedLanguage) {
      selectedLanguage = 'en';
    }
    this.selectedLanguage = selectedLanguage;
    this.selectedLayer = localStorage.getItem('selectedLayer') || 'normal';
    const mapCoordsFromSession = sessionStorage.getItem('mapCoords');
    if (mapCoordsFromSession) {
      this.initialCoordinates = {
        coords: {
          latitude: mapCoordsFromSession.split(',')[0],
          longitude: mapCoordsFromSession.split(',')[1]
        }
      }
    }
   }
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    _event: KeyboardEvent
  ) {
    this.selectedLocation.emit({ selectedOption: "escape" });
  }
  public ngOnInit() {
    this.enableSatelliteView = this._translationService.featureFlags.locations.enableSatelliteView;
    this.enableRecenterFeature = this._translationService.featureFlags.locations.enableRecenter;
    this.platform = new H.service.Platform({
      apikey: this.apiKey,
    });
    this.url1= this.router.url;
  }

  ngAfterViewInit() {
    this.initalizeMap();
    // Issue: Each time we land on this screen with this URL, we were navigating to our base location.
    // Location access dialog logic
    if (this._actRoute.snapshot.routeConfig?.path !== 'circuit/:guid' && !this.trackInfo) {
      this._createMapService.getUserLocation().then((res) => { // Fix: Provide only one argument in the callback function
        this.mapCoordinates = res;
        sessionStorage.setItem('mapCoords', this.mapCoordinates);
        this.recentreToUserCurrentLocation(this.mapCoordinates, ELOCATION_SCREENS.LANDING_PAGE);
      }).catch(() => { // Fix: Remove the unused 'rej' parameter and add an empty catch block
        // Handle reject scenari o here
        const result = this.calculateDynamicRadius();
      this.pannedCircuitData.emit({
        lat: result.lat,
        lng: result.lng,
        radius: result.radius,
      });
      });
    }
  }

  public recentreToUserCurrentLocation(coordinates: any, source: string): void {
    this.isMapPanned = false;
    let coords = {
      lat: '',
      lng: ''

    };
    if(!coordinates){
      coordinates=this.initialCoordinates.coords;
      coords.lat= coordinates.latitude;
      coords.lng= coordinates.longitude;
    } else {
      coords.lat = coordinates[0];
      coords.lng = coordinates[1];
    }
    let zoom = ELOCATION_SCREENS.LANDING_PAGE === source ? this.mapZoom : 7; // Zoom Level on recenter: TBD
    if (coordinates && !this.screenChanged && this.selectedOption == '') {
      // Main page code go here
      if (!sessionStorage.getItem('mapCoords')) {
        zoom = LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_NO_LOC
      }
      this.map.setView(coords, zoom, { animate: false });
    }
    if (this.recenterlocation && this.selectedOption == "" && this.screenChanged ) {
      coords.lat = this.recenterlocation.lat;
      coords.lng = this.recenterlocation.lng;
      this.map.flyTo(coords, this.streatViewZoomlevel, { duration: 1 });
    }
    if (this.selectedOption != "") {   
      let array = this.circuitService.coordinatesLocation.split(',');
      coords.lat = array[0];
      coords.lng = array[1];
      this.map.flyTo(coords, LOCATIONS_CONSTANTS.CIRCUIT_PUBLISHED_ZOOM, { duration: 1 });
    }
    else if (coordinates == undefined) {
      let coord = sessionStorage.getItem('mapCoords');
      let c1: string[] | undefined = coord?.split(",");     
      if (c1 != undefined) {
        coords.lat = c1[0];
        coords.lng = c1[1];
      }
      this.map.setView(coord, zoom, { animate: false });
    }
    const latLng = coords.lat + ',' + coords.lng;
    this._createMapService.mapCurrentData = {
      latLng,
      zoom: LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_CURR_LOC
    }
    setTimeout(() => {
      // If the customer has given location access and already have map view response for same lat and long,
      // then we should not call the API again.
      const result = this.calculateDynamicRadius();
      this.pannedCircuitData.emit({
        lat: coords.lat,
        lng: coords.lng,
        radius: result.radius,
      });
    }, 200);
  }

  /**
   * @description Recenters to circuit or track based on user selection
   * @param circleCoordinates 
   */
  public recenterUserToCircuitSelected(circleCoordinates: any, screen?: string): void {
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const emToPx = (em: number) => em * fontSize;
    const topLeftPaddingEm: number[] = [parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--left-floating-panel-width')), parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--header-height')))];
    const bottomRightPaddingEm: number[] = [parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--gutter-space-large'))), parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--carousel-reg-height'))) - parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--gutter-space-large')))]
    const topLeftPaddingPx = topLeftPaddingEm.map(emToPx);
    const bottomRightPaddingPx = bottomRightPaddingEm.map(emToPx);
    // If track is selected, type is different. Once track is selected, receter should take to that track
    // once track is unselected, it should take to the circuit. So remove the track polyline and add a circuit polyline
    if (this.polyline && this.polyline.type !== "geo-Polyline" && (!this.trackInfo || !this.sectorInfo)) {
      let coordinates = this.circleCoordinates?.split(",");
      this.map.removeLayer(this.polyline);
      if (this.circlularCircuit) {
        this.map.removeLayer(this.circlularCircuit);
      }
      this.circlularCircuit = this.polyline = null;
      this.addCircleGeofence(this.map, coordinates, this.radius);
    }
    if (this.polyline) {
      if (screen !== ELOCATION_SCREENS.TRACK_INFO) {
        this.loadPolylineForCircuitInfo(topLeftPaddingPx, bottomRightPaddingPx);
      } else {
        this.loadPolylineForTrackInfo();
      }
    } else if (this.circlularCircuit) {
      this.loadCircularPolylineForCircuitInfo(topLeftPaddingPx, bottomRightPaddingPx);
    }   
  }

  private loadCircularPolylineForCircuitInfo(topLeftPaddingPx: number[], bottomRightPaddingPx: number[]) {
    this.map.flyToBounds(this.circlularCircuit.getBounds(), {
      paddingTopLeft: topLeftPaddingPx,
      paddingBottomRight: bottomRightPaddingPx,
      zoom: LOCATIONS_CONSTANTS.CIRCUIT_PUBLISHED_ZOOM,
      duration: 2.5
    });
  }

  private loadPolylineForTrackInfo() {
    const newZoomLevel = LOCATIONS_CONSTANTS.TRACK_LAYOUT_ZOOM;
    const center = this.map.getCenter();
    this.map.setView(center, newZoomLevel, { animate: true });
  }

  private loadPolylineForCircuitInfo(topLeftPaddingPx: number[], bottomRightPaddingPx: number[]) {
    this.map.flyToBounds(this.polyline.getBounds(), {
      paddingTopLeft: topLeftPaddingPx,
      paddingBottomRight: bottomRightPaddingPx,
      zoom: LOCATIONS_CONSTANTS.CIRCUIT_PUBLISHED_ZOOM,
      duration: 2.5
    });
  }

  public ngOnChanges(changes: SimpleChanges) {  
    if (changes.clearDrawnItems) {
      this.clearPreviousData();
      this.clearAllLayers();
      this.resetMapZooms();
    }
    if (changes.radiusValueFromTool) {
      this.clearAllLayers();
      this.circleGeoCoordinates = this.circleGeoCoordinates
        ? this.circleGeoCoordinates
        : this.circleCoordinates;
      let editCircleCoordinates = {
        name: "circle",
        radius: changes.radiusValueFromTool.currentValue * 1000,
        coordinates: this.circleGeoCoordinates,
      };
      this.undoArray.push(editCircleCoordinates);
      if (
        editCircleCoordinates.coordinates != undefined &&
        editCircleCoordinates.coordinates != null
      ) {
        this.createUndoCircle(editCircleCoordinates);
      }
    }
    if (
      changes.selectedLocationAddress?.currentValue !==
      changes.selectedLocationAddress?.previousValue
    ) {
      if (this.geoSearch) {
        this.geoSearch = null;
      }
      if (this.selectedType == EALL_TRACKS.CIRCUIT) this.screenChanged = true;
    }
    if (changes?.selectedOption) {
      if (
        changes.selectedOption.previousValue?.substring(0, 4) !== "clea" &&
        changes.selectedOption.previousValue != ""
      ) {
        this.previousOption = changes.selectedOption.previousValue;
      }
      if (
        changes.selectedOption.currentValue != "" &&
        changes.selectedOption.currentValue != undefined
      ) {
        if (
          changes.selectedOption.currentValue === "polygon" ||
          changes.selectedOption.currentValue === "circle" ||
          changes.selectedOption.currentValue === "escape"
        ) {
          if (this.encodedDetails || this.circleGeoCoordinates) {
            this.selectedLocation.emit({
              encodedDetails: this.encodedDetails,
              circleGeoCoordinates: this.circleGeoCoordinates,
              Option: changes.selectedOption.currentValue,
              showAlert: true,
            });
          } else {
            if (
              changes.selectedOption.currentValue !==
              changes.selectedOption.previousValue?.split("change")[0]
            )
              this.drawShape(changes.selectedOption.currentValue);
            this.selectedLocation.emit({
              showLabel: true,
              Option: changes.selectedOption.currentValue,
            });
          }
        } else {
          this.drawShape(changes.selectedOption.currentValue);
        }
      }
    } else if (changes && this.setZoomInLocation?.locations?.length) {
      let coordinate = this.setZoomInLocation.locations.split(",");
      if (this.map.getZoom() < 15) {
        this.map.flyTo([coordinate[0], coordinate[1]], 15, { duration: 1 });
        this.setZoomInLocation = "";
        changes.setZoomInLocation.currentValue = "";
        if (!this.encodedDetails && !this.circleGeoCoordinates) {
          this.map.pm.enableDraw();
        }
      } else {
        this.map.flyTo([coordinate[0], coordinate[1]], this.map.getZoom(), {
          duration: 1,
        });
        this.map.pm.enableDraw();
      }
    }
   
    if(this.url1=="" && !this.screenChanged) this.url1=this.router.url;
    else if( this.selectedType == EALL_TRACKS.CIRCUIT &&  this.isShowAddCircuitSearch ) this.url1= this._actRoute.snapshot.routeConfig?.path;
    this.tracklen = this.trackLength;
    this.rerenderMap();
  }

  /**
   * This function is responsible for clearing the previous data on the map
   * Also when we click on cancel from Create Circuit screen, so that map zoom is set correctly
   * and also disable the draw functionality
   */
  public resetMapZooms(): void {
    if (this.map && !this.isShowCircuitInfo) {
      this.map.pm.disableDraw();
      this.map.options.maxZoom = LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_CURR_LOC;
      this.map.setZoom(LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_CURR_LOC);
      this.circleCoordinates = this.circleGeoCoordinates = this.encodedDetails = null;
      this.selectedOption = "";
      // this.circlularCircuit = null;
    }
  }

  /**
   * @description Called when we click on map layer - Switches the map view from normal to satellite and vice-versa
   */
  public switchMapView(): void {
    if (this._createMapService.selectedLayer === 'normal') {
      this.map.addLayer(this._createMapService.satelliteView);
      this.map.removeLayer(this._createMapService.normalView);
      this._createMapService.selectedLayer = this.selectedLayer = 'satellite';
    } else if (this._createMapService.selectedLayer === 'satellite') {
      this.map.addLayer(this._createMapService.normalView);
      this.map.removeLayer(this._createMapService.satelliteView);
      this._createMapService.selectedLayer = this.selectedLayer = 'normal';
    }
    localStorage.setItem('selectedLayer', this.selectedLayer)
  }

  public loadMapForLocation(from: string): void {
    this.map = L.map(this.mapElement.nativeElement, this._createMapService.loadMap(this.apiKey, from));
    this.layerControl = L.control.layers(this._createMapService.getBaseMapDetails()).addTo(this.map);
    if (from !== ELOCATION_SCREENS.TRACK_INFO) {
      this.zoomControl = L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(this.map);
    }
     // If there is no loacation access set current lat and long as initial coordinates it self
     if (!this.mapCoordinates && from !== ELOCATION_SCREENS.TRACK_INFO) {
      const latLng = this._createMapService.getLatLngBasedOnPage(from);
      this._createMapService.mapCurrentData = {
        latLng: latLng.lat + ',' + latLng.lon,
        zoom: LOCATIONS_CONSTANTS.MIN_MAP_ZOOM_FOR_NO_LOC
      }
    }
  }

  /**
   * @description The mapZoomIn function is an event handler that gets 
   * triggered when the user starts zooming in on the map. It listens for 
   * the zoomstart event on the map object.
   */
  public mapZoomEventHandler(): void {
    this.map.on("zoomstart", (_ev: any) => {
      if (
        this.isMarkerClicked ||
        this.backToLocationsFromCircuitDetails ||
        this.backToLocationsFromEmotionTour
      ) {
        if (this.searchLocation) {
          this.zoomlevel[0] = 2;
        } else {
          this.zoomlevel.push(this.map.getZoom());
          localStorage.setItem("zoomlevel", JSON.stringify(this.zoomlevel));
        }
        if (!this.isShowCircuitInfo && !this.isShowEmotionTour) {
          let layers: any = [];
          this.spiderfyMarkers.map((marker: any) => {
            this.map.removeLayer(marker);
          });

          this.map.eachLayer((layer: any) => {
            return layers.push(layer);
          });
          layers = layers.slice(1);

          layers?.map((item: any) => {
            this.map.removeLayer(item);
            this.deletedLayers.push(item);
            this.removedLayers.push(item);
          });
          if (this.searchLocation) {
            this.deletedLayers = this.dummyLayers;
            this.zoomlevel[0] = 3;
          }
        }
      }
      if (
        !this.isMarkerClicked &&
        !this.isShowCircuitInfo &&
        !this.isShowAddCircuitSearch &&
        !this.isShowEmotionTour &&
        !this.backToLocationsFromCircuitDetails &&
        !this.backToLocationsFromEmotionTour
      ) {
        this.isMapPanned = true;
        this.circuitInfo = this.emotionTourInfo = this.offRoadTourList = null;
        let result = this.calculateDynamicRadius();
        // this.circleCoordinates = result.lat + "," + result.lng;
        this.pannedCircuitData.emit({
          lat: result.lat,
          lng: result.lng,
          radius: result.radius,
        });
      }
      this.removedLayers = [];
      this.map.on("zoomend", () => {
        if (
          !this.isMarkerClicked &&
          !this.backToLocationsFromCircuitDetails &&
          !this.backToLocationsFromEmotionTour
        ) {
          let layers: any = [];
          this.map.eachLayer(function (layer: any) {
            layers.push(layer);
          });
          if (this.selectedType == EALL_TRACKS.EMOTION_TOUR) {
            layers?.map((layer: any) => {
              if (layer.type == EALL_TRACKS.CIRCUIT || layer.type === EALL_TRACKS.OFF_ROAD) {
                this.removedLayers.push(layer);
                this.map.removeLayer(layer);
              }
            });
            // } else if (this.selectedType == EALL_TRACKS.CIRCUIT || this.selectedType == "mycircuits") {
          } else if (this.selectedType == EALL_TRACKS.CIRCUIT) {
            layers?.map((layer: any) => {
              if (layer.type == EALL_TRACKS.EMOTION_TOUR || layer.type === EALL_TRACKS.OFF_ROAD) {
                this.removedLayers.push(layer);
                this.map.removeLayer(layer);
              }
            });
          } else if (this.selectedType == EALL_TRACKS.OFF_ROAD) {
            layers?.map((layer: any) => {
              if (layer.type == EALL_TRACKS.EMOTION_TOUR || layer.type === EALL_TRACKS.CIRCUIT) {
                this.removedLayers.push(layer);
                this.map.removeLayer(layer);
              }
            });
          }
        }
        if (this.isShowTrackInfo) {
          let layers: any = [];
          this.map.eachLayer(function (layer: any) {
            layers.push(layer);
          });
          if (this.map.getZoom() < 13) {
            if (layers.length > 4) {
              layers = layers.slice(4);
              layers?.map((layer: any) => {
                this.deletedSectorPoints.push(layer);
                this.map.removeLayer(layer);
              });
            }
          } else {
            this.deletedSectorPoints.map((item: any) => {
              this.map.addLayer(item);
            });
          }
        }
        if (!this.isShowAddCircuitSearch && this.selectedOption === "") {
          this.map.pm.disableDraw();
          if (this.coordinatesInProgress) {
            this.coordinatesInProgress = null;
          }
          if (this.myTempCircleTextLabel) {
            this.map.removeLayer(this.myTempCircleTextLabel);
            this.myTempCircleTextLabel = null;
          }
        }
        if (!this.isShowCircuitInfo) {
          this._createMapService.mapCurrentData = {
            latLng: this._createMapService.mapCurrentData.latLng,
            zoom: this.map.getZoom()
          }
        }
      });
    });
  }

  /**
   * @description The mapDragEnd function is an event handler that gets 
   * triggered when the user stops dragging the map. It listens for the dragend
   *  event on the map object. When this event is fired, another event listener
   *  is added for the mouseup event.
   */
  public mapDragEndEventHandler(): void {
    this.map.on(
      "dragend",
      () => {
        let count = 1;
        this.map.on(
          "mouseup",
          (event: any) => {
            const latLng = event.latlng.lat + "," + event.latlng.lng;
            if (!this.isShowCircuitInfo) {
              this._createMapService.mapCurrentData = {
                latLng,
                zoom: this.map.getZoom()
              }
            }
            this.isMapPanned = true
            if (
              this.isMapPanned &&
              !this.isMarkerClicked &&
              !this.isShowCircuitInfo &&
              !this.isShowEmotionTour
            ) {
              if (count) {
                this.circuitInfo = this.emotionTourInfo = this.offRoadTourList = null;

                let result = this.calculateDynamicRadius();
                this.pannedCircuitData.emit({
                  lat: result.lat,
                  lng: result.lng,
                  radius: result.radius,
                });
                count = 0;
              }
            }
          },
          false
        );
      },
      false
    );
  }

  /* intialize map for map-view, circuit-d
  etails,layout-details based on the input */
  public initalizeMap(): void {
    // The map needs initialization only for main screen and circuit details screen
    if (!this.trackInfo) {
      this.loadMapForLocation(ELOCATION_SCREENS.LANDING_PAGE);
      this.mapDragEndEventHandler();
      this.mapZoomEventHandler();
      this.setupGeoman();
      this.setupEventListeners();
    } else {
      this.loadMapForTrackDetails();
    } 
    // This is needed to load the track in circuit details screen. Needs to run
    // wheneever we are in circuit details screen 
    if ((this.circuitInfo?.length > 0 || this.circleCoordinates) &&
    this._actRoute.snapshot.routeConfig?.path == 'circuit/:guid') {
      /* map-view intialize map for displaying circuits and pins */
      if (this.circuitInfo) {
        // Responsible for landing page zoom and splitting cluserts on zoom
        this.map.options.maxZoom = LOCATIONS_CONSTANTS.STREET_LEVEL_ZOOM;
        this.startClustering(this.map, this.circuitInfo, EALL_TRACKS.CIRCUIT);
        this.startClustering(this.map, this.emotionTourInfo, EALL_TRACKS.EMOTION_TOUR);
        this.startClustering(this.map, this.offRoadTourList, EALL_TRACKS.OFF_ROAD)
      } else {
        let coordinate: any;
        if (this.circuitInfo) {
          coordinate = [48, 9];
        } else {
          coordinate = this.circleCoordinates.split(",");
        }
        this.addControlPlaceholders(this.map);
        this.map.options.minZoom = 10;
        // Change the position of the Zoom Control to a newly created placeholder.
        this.zoomControl.setPosition("verticalcenterright");
        this.addCircleGeofence(this.map, coordinate, this.radius);
      }
      this.setupGeoman();
      this.setupEventListeners();
    }
  }

  private loadMapForTrackDetails(): void {
    let fillColor = "#d9252b";
    this._createMapService.mapCurrentData = {
      latLng: this.trackInfo[0][0] + "," + this.trackInfo[0][1],
      zoom: LOCATIONS_CONSTANTS.TRACK_LAYOUT_ZOOM
    }
    this.loadMapForLocation(ELOCATION_SCREENS.TRACK_INFO);  
    this.disableMapBehaviors();
    this.addPolylineToLeafLet(this.trackInfo, this.sectorInfo, fillColor, 5, ELOCATION_SCREENS.TRACK_INFO);
  }
  
  /**
   * @description This function is responsible for disabling map behaviors like dragging, zooming, etc.
   * This is used in track details screen - on uploading the track details
   */
  private disableMapBehaviors(): void {
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
  }

  /* reintialize map when there is change in any of the properties */
  rerenderMap() {
    if (this.map) {
      this.map.options.minZoom = 2;
    }
    if (this.isShowAddCircuitSearch && !this.geoSearch) {
      this.map.options.maxZoom = LOCATIONS_CONSTANTS.LANDING_PAGE_MAX_ZOOM_SAT_NOR;
      //Geocoder options
      //covert svg  to iconUrl
      this.selectedGeoPoints = null;
      let layers: any = [];
      this.map.eachLayer(function (layer: any) {
        layers.push(layer);
      });
      layers?.map((layer: any) => {
        if (layer.type == EALL_TRACKS.EMOTION_TOUR) {
          this.removedLayers.push(layer);
          this.map.removeLayer(layer);
        }
      });
      //Initialize the geocoder
      if (this.selectedLocationAddress) {
        this.selectedGeoPoints = "";
        this.geoSearch = this.selectedLocationAddress;

        this.map.flyTo(
          [this.geoSearch.position.lat, this.geoSearch.position.lng],
          15,
          {
            duration: 1,
          }
        );
        if (this.encodedDetails) {
          this.clearAllLayers();
        }
        this.selectedGeoPoints =
          this.geoSearch.position.lat + "," + this.geoSearch.position.lng;
        this.selectedLocation.emit({
          showNotification: true,
          selectedGeoPoints: this.selectedGeoPoints
            ? this.selectedGeoPoints
            : null,
          isMoreZoom: this.map.getZoom() >= 15,
          selectedOption: "",
        });
      }

      this.map.on("zoomend", () => {
        if (this.map.getZoom() >= 15) {
          if (this.selectedOption === "circlechange") {
            this.selectedOption = "circle";
          }
          if (this.selectedOption === "polygonchange") {
            this.selectedOption = "polygon";
          }
          if (this.isShowAddCircuitSearch) {
            this.selectedLocation.emit({
              isEnableGeofence: true,
              selectedOption:
                this.selectedOption !== "clear" ? this.selectedOption : "",
              selectedGeoPoints: this.selectedGeoPoints
                ? this.selectedGeoPoints
                : null,
            });
            if (
              !this.encodedDetails &&
              !this.circleGeoCoordinates &&
              !this.polyline &&
              !this.newpolycoordinates.length &&
              !this.coordinatesInProgress &&
              !this.myTempCircleTextLabel
            ) {
              this.map.pm.enableDraw();
            }
          }
        } else if (this.map.getZoom() < 15 && this.isShowAddCircuitSearch) {
          this.selectedLocation.emit({
            isEnableGeofence: false,
            selectedOption:
              this.encodedDetails || this.circleGeoCoordinates
                ? this.selectedOption
                : "",
            selectedGeoPoints: this.selectedGeoPoints
              ? this.selectedGeoPoints
              : null,
            isMoreZoom: false,
          });
          if (this.polyline) {
            this.map.removeLayer(this.polyline);
            this.polyline = null;
          }
          this.map.pm.disableDraw();

          if (this.myTempCircleTextLabel) {
            this.map?.removeLayer(this.myTempCircleTextLabel);
            this.myTempCircleTextLabel = null;
          }
          if (this.coordinatesInProgress) {
            this.coordinatesInProgress = null;
          }
        }
      });
    }
    if (this.deletedCircuitGuid) {
      this.removedLayers = [];
      this.deletedLayers = [];
    }
    if (this.searchLocation && this.map) {
      if (this.map.options) {
        this.map.options.maxZoom = LOCATIONS_CONSTANTS.LANDING_PAGE_MAX_ZOOM_SAT_NOR;
      }
      let coordinate = this.searchLocation.coordinates?.split(",");
      this.circleCoordinates = this.searchLocation.coordinates;
      this.pannedCircuitData.emit({
        guid: this.searchLocation.guid,
        type: this.searchLocation.type,
        selectedTrack: this.searchLocation.selectedTrack
      });
      this.onMarkerClick(this.searchLocation);
      this.map.flyTo([coordinate[0], coordinate[1]], 15);
    }
    if (this.isShowCircuitInfo && this.map) {
      if (this.geoSearch) {
        this.geoSearch = null;
      }
      this.map.options.maxZoom = LOCATIONS_CONSTANTS.LANDING_PAGE_MAX_ZOOM_SAT_NOR;
      this.map.options.minZoom = 10;
      this.addControlPlaceholders(this.map);
      // Change the position of the Zoom Control to a newly created placeholder.
      this.zoomControl.setPosition("verticalcenterright");
      // You can also put other controls in the same placeholder.
      if (this.isShowTrackInfo) {
        this.map.options.maxZoom = LOCATIONS_CONSTANTS.LANDING_PAGE_MAX_ZOOM_SAT_NOR;
        let layers: any = [];
        this.deletedSectorPoints = [];
        this.map.eachLayer(function (layer: any) {
          layers.push(layer);
        });
        if (layers.length > 3) {
          layers = layers.slice(3);
          layers?.map((layer: any) => {
            this.map.removeLayer(layer);
          });
        }
        this.addPolylineToLeafLet(this.trackInfo, this.sectorInfo, "#d9252b", 5, ELOCATION_SCREENS.CIRCUIT_INFO);
      } else {
        let coordinates = this.circleCoordinates?.split(",");
        if (this.isMarkerClicked) {
          this.addCircleGeofence(this.map, coordinates, this.radius);
        }
      }
    }
    if (
      !this.isShowCircuitInfo &&
      !this.isShowEmotionTour &&
      !this.searchLocation &&
      !this.isShowAddCircuitSearch &&
      ((this.isMarkerClicked && this.isApiSuccess) ||
        this.backToLocationsFromCircuitDetails ||
        this.backToLocationsFromEmotionTour)
    ) {
      this.backToLocations();
    }
    if (this.isShowEmotionTour && this.map && !this.selectedAnnotationsId) {
      this.map.options.maxZoom = LOCATIONS_CONSTANTS.LANDING_PAGE_MAX_ZOOM_SAT_NOR;
      this.addControlPlaceholders(this.map);
      this.zoomControl.setPosition("verticalcenterright");
      // Change the position of the Zoom Control to a newly created placeholder.

      let layers: any = [];

      this.map.eachLayer(function (layer: any) {
        layers.push(layer);
      });
      if (layers.length > 3) {
        layers = layers.slice(3);

        layers?.map((layer: any) => {
          this.map.removeLayer(layer);
        });
      }
    }
    if (this.selectedType && this.map) {
      if (!this.isShowAddCircuitSearch) {
        if (this.geoSearch) {
          this.map.pm.disableDraw();
          this.map.options.maxZoom = LOCATIONS_CONSTANTS.STREET_LEVEL_ZOOM;
          this.map.setZoom(this._createMapService.mapCurrentData.zoom);
          this.clearAllLayers();
          this.geoSearch = null;
          this.selectedLocationAddress = null;
        }
      }
      this.filterData();
    }
  }
  
  /**
   * @description This function is called when we click on the submenu toggle
   */
  public filterData(): void {
    if
      (!this.emotionTourTrackencodedGpsPoints?.length) {
      let layers: any = [];

      if (this.removedLayers && !this.isMapPanned && !this.isMarkerClicked) {
        this.removedLayers?.map((item: any) => {
          this.map.addLayer(item);
          this.removedLayers = [];
        });
      }
      if (!this.isShowEmotionTour) {
        this.map.eachLayer(function (layer: any) {
          layers.push(layer);
        });
      }
      if (this.selectedType == EALL_TRACKS.ALL && !this.isMarkerClicked) {
        if (
          this.removedLayers &&
          !this.isMapPanned &&
          // !this.deletedCircuitGuid && this.selectedUserType != 'you'
          !this.deletedCircuitGuid
        ) {
          this.removedLayers.map((item: any) => {
            this.map.addLayer(item);
            this.removedLayers = [];
          });
        }
        if (
          (this.isMapPanned ||
            this.deletedCircuitGuid ||
            !this.backToLocationsFromCircuitDetails ||
            !this.backToLocationsFromEmotionTour) &&
          (this.circuitInfo?.length || this.emotionTourInfo?.length)
        ) {
          if (this.deletedCircuitGuid) {
            this.map.eachLayer(function (layer: any) {
              layers.push(layer);
            });
            layers.map((layer: any) => {
              if (
                layer.type == "circle-geofence" ||
                layer.type == "geo-Polyline"
              ) {
                this.map.removeLayer(layer);
              }
            });
            this.map.setZoom(this._createMapService.mapCurrentData.zoom);
          }
          this.startClustering(this.map, this.circuitInfo, EALL_TRACKS.CIRCUIT);
          this.startClustering(this.map, this.emotionTourInfo, EALL_TRACKS.EMOTION_TOUR);
          this.startClustering(this.map, this.offRoadTourList, EALL_TRACKS.OFF_ROAD);
          this.deletedCircuitGuid = null;
        } else if (
          this.emotionTourInfo?.length == 0 ||
          this.circuitInfo?.length == 0
        ) {
          this.isMapPanned = false;
        }
        // } else if (this.selectedType == EALL_TRACKS.CIRCUIT || this.selectedType == "mycircuits") {
      } else if (this.selectedType == EALL_TRACKS.CIRCUIT) {
        if (this.removedLayers) {
          layers?.map((layer: any) => {
            if (layer.type == EALL_TRACKS.EMOTION_TOUR || layer.type == EALL_TRACKS.OFF_ROAD || layer.options.color == "#222") {
              this.removedLayers.push(layer);
              this.map.removeLayer(layer);
            }
          });
        }
        if (this.isMapPanned) {
          this.startClustering(this.map, this.circuitInfo, EALL_TRACKS.CIRCUIT);
        } else if (this.circuitInfo.length == 0) {
          this.isMapPanned = false;
        }
        // } else if (this.selectedType == EALL_TRACKS.EMOTION_TOUR || this.selectedType == "myemotiontour") {
      } else if (this.selectedType == EALL_TRACKS.EMOTION_TOUR) {
        if (this.removedLayers) {
          layers?.map((layer: any) => {
            if (layer.type == EALL_TRACKS.CIRCUIT || layer.type == EALL_TRACKS.OFF_ROAD || layer.options.color == "#222") {
              this.removedLayers.push(layer);
              this.map.removeLayer(layer);
            }
          });
        }
        if (this.isMapPanned) {
          this.startClustering(this.map, this.emotionTourInfo, EALL_TRACKS.EMOTION_TOUR);
        } else if (this.emotionTourInfo.length == 0) {
          this.isMapPanned = false;
        }
      }  else if (this.selectedType == EALL_TRACKS.OFF_ROAD) {
        if (this.removedLayers) {
          layers?.map((layer: any) => {
            if (layer.type == EALL_TRACKS.EMOTION_TOUR || layer.type == EALL_TRACKS.CIRCUIT || layer.options.color == "#222") {
              this.removedLayers.push(layer);
              this.map.removeLayer(layer);
            }
          });
        }
        if (this.isMapPanned) {
          this.startClustering(this.map, this.circuitInfo, EALL_TRACKS.CIRCUIT);
        } else if (this.circuitInfo.length == 0) {
          this.isMapPanned = false;
        }
        // } else if (this.selectedType == EALL_TRACKS.EMOTION_TOUR || this.selectedType == "myemotiontour") {
      }
    }
  }

  /**
   * @description BacktoLocationsFunction is used for navigating back to map view by maintaining the previous zoom level and data
   */
  public backToLocations(): void {
    if (this.polyline) {
      this.map?.removeLayer(this.polyline);
      this.polyline = null;
    }
    this.backToLocationsFromEmotionTour = false;
    this.backToLocationsFromCircuitDetails = false;
    this.selectedAnnotationObj.emit();

    this.selectedAnnotationsId = null;

    this.zoomControl?.setPosition("bottomright");
    const mapZoom = this._createMapService.mapCurrentData.zoom;
    this.map.setZoom(mapZoom);

    this.zoomlevel = [];
    let layers: any = [];
    if (this.removedLayers && !this.isMapPanned && !this.isMarkerClicked) {
      this.removedLayers?.map((item: any) => {
        this.map.addLayer(item);
        this.removedLayers = [];
      });
    }
    this.map.eachLayer(function (layer: any) {
      layers.push(layer);
    });
    layers = layers.slice(1);

    layers.map((layer: any) => {
      this.map.removeLayer(layer);
    });
    this.deletedLayers.map((layer: any) => {
      if (
        layer.type !== "sector" &&
        layer.type !== "circuitPolyline" &&
        layer.type !== "emotionTourPolyline" &&
        layer.type !== "endMarker" &&
        layer.type !== "startMarker" &&
        layer.type !== "circle-geofence" &&
        layer.type !== "geo-Polyline" &&
        layer.type !== "annotationsMarker" &&
        layer.type !== "roundtripMarker" &&
        layer.options?.color != "#222"
      ) {
        if (this.selectedType == EALL_TRACKS.ALL) {
          this.map.addLayer(layer);
          // } else if (this.selectedType == EALL_TRACKS.CIRCUIT || this.selectedType == "mycircuits") {
        } else if (this.selectedType == EALL_TRACKS.CIRCUIT) {
          if (layer.type == EALL_TRACKS.CIRCUIT) {
            this.map.addLayer(layer);
          } else {
            this.removedLayers.push(layer);
          }
          // } else if (this.selectedType == EALL_TRACKS.EMOTION_TOUR || this.selectedType == "myemotiontour") {
        } else if (this.selectedType == EALL_TRACKS.EMOTION_TOUR) {
          if (layer.type == EALL_TRACKS.EMOTION_TOUR) {
            this.map.addLayer(layer);
          } else {
            this.removedLayers.push(layer);
          }
        }
      }
      this.isMarkerClicked = false;
      this.isApiSuccess = false;
      this.backToLocationsFromCircuitDetails = false;
      this.backToLocationsFromEmotionTour = false;
    });
    this.deletedLayers = [];
    this.resetMapZooms();
  }

  /**
   * Display clustered markers on a map
   *
   * Note that the maps clustering module https://js.api.here.com/v3/3.1/mapsjs-clustering.js
   * must be loaded to use the Clustering
   * 
   */
  startClustering(map: any, data: any, type: any) {
    // First we need to create an array of DataPoint objects,
    // for the ClusterProvider
    let clusterSvgTemplate: any;
    // Remove existing clusters
    this.dummyLayers = [];
    this.map.eachLayer((layer: any) => {
    })
    let dataPoints = data.map((item: any) => {
      return item.coordinates.split(",");
    });

    let noiseSvg;
    let color: any;
    let myIcon: any;
    if (type == EALL_TRACKS.CIRCUIT) {
      color = "rgb(178, 30, 35)";
      noiseSvg = `<svg width="50" height="47" viewBox="0 0 61 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
          <filter x="-12.8%" y="-7.4%" width="125.6%" height="118.5%" filterUnits="objectBoundingBox" id="amjux2wi2a">
              <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
              <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1"/>
          </filter>
          <path d="M25.5 51c-1.119-2.237-2.76-4.21-4.552-6.16l-.99-1.064-1.859-1.98-.647-.707-.64-.716-.315-.364-.623-.737C12.903 35.668 10.5 31.475 10.5 25.5 10.5 14.73 19.23 6 30 6l.46.005C41.017 6.25 49.5 14.885 49.5 25.5c0 5.975-2.403 10.168-5.374 13.772l-.623.737c-.104.122-.21.243-.316.364l-.64.716-.646.706-2.357 2.512C37.564 46.434 35.72 48.56 34.5 51a33.923 33.923 0 0 0-1.67 3.938l-.247.722-.216.666-.447 1.424-.158.472c-.392 1.111-.77 1.702-1.591 1.771L30 60c-.884 0-1.291-.52-1.683-1.563l-.157-.444-.08-.243-.447-1.424-.216-.666-.246-.722A33.923 33.923 0 0 0 25.5 51z" id="pwulttvzkb"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
          <path d="M.604 0h60v60h-60z"/>
          <use filter="url(#amjux2wi2a)" xlink:href="#pwulttvzkb" fill-rule="nonzero" fill="#000" transform="translate(.604)"/>
          <path d="M26.104 51c-1.118-2.237-2.76-4.21-4.552-6.16l-.99-1.064-1.858-1.98-.647-.707-.64-.716-.316-.364-.623-.737c-2.97-3.604-5.374-7.797-5.374-13.772 0-10.77 8.73-19.5 19.5-19.5l.46.005c10.557.245 19.04 8.88 19.04 19.495 0 5.975-2.403 10.168-5.374 13.772l-.622.737-.316.364-.64.716-.647.706-2.357 2.512c-1.98 2.127-3.824 4.253-5.044 6.693a33.923 33.923 0 0 0-1.67 3.938l-.247.722-.216.666-.447 1.424-.158.472c-.392 1.111-.77 1.702-1.591 1.771l-.17.007c-.884 0-1.292-.52-1.684-1.563l-.157-.444-.08-.243-.447-1.424-.215-.666-.247-.722A33.923 33.923 0 0 0 26.105 51z" fill="#B21E23"/>
          <path d="M30.598 6.25c5.421.123 10.222 2.3 13.674 5.752A18.997 18.997 0 0 1 49.854 25.5c0 5.907-2.38 10.05-5.315 13.61l-.619.734-.953 1.076-.645.704-2.357 2.513c-1.998 2.145-3.854 4.29-5.084 6.751a34.176 34.176 0 0 0-1.684 3.969l-.248.726-.217.669-.444 1.416c-.27.779-.493 1.31-.757 1.647-.21.27-.447.399-.768.428-.418.001-.72-.088-.955-.284-.28-.235-.464-.608-.651-1.104l-.682-2.106-.217-.67-.248-.725a34.17 34.17 0 0 0-1.682-3.966c-1.129-2.257-2.784-4.249-4.593-6.22l-.99-1.063-1.857-1.979-.645-.703-.636-.713-.936-1.097c-2.937-3.563-5.317-7.706-5.317-13.613a19.19 19.19 0 0 1 5.638-13.612A19.187 19.187 0 0 1 30.598 6.25z" stroke="#FFF" stroke-width=".5"/>
          <path d="M18.604 13.5h24v24h-24z"/>
          <path d="m34.993 18.496.154.15 1.262 1.299.761-.748 1.315 1.337-2.942 2.892-1.315-1.337.71-.698-1.27-1.306a1.72 1.72 0 0 0-2.252-.188l-.124.101-7.818 6.945a2.397 2.397 0 0 0-.332 3.222l.111.138.114.126c.823.855 2.148.98 3.115.3l.143-.107 3.036-2.483a3.785 3.785 0 0 1 4.177-.41l.19.108.649.396c.89.543 2.023.435 2.794-.245l.132-.126.31-.32a2.218 2.218 0 0 0 .118-2.96l-.12-.132-.23-.236 1.473-1.448.238.245a4.283 4.283 0 0 1 .159 5.799l-.156.17-.31.32a4.427 4.427 0 0 1-5.292.806l-.19-.11-.65-.396a1.72 1.72 0 0 0-1.86.044l-.125.093-3.036 2.483a4.473 4.473 0 0 1-6.062-.368l-.152-.167-.127-.152a4.462 4.462 0 0 1 .344-5.977l.168-.157 7.818-6.944a3.785 3.785 0 0 1 5.072.04z" fill="#FFF"/>
      </g>
  </svg>
  `;

      myIcon = L.divIcon({
        html: noiseSvg,
        className: "mynoise",

        iconSize: L.point(50, 50),
      });
    } else if (type == EALL_TRACKS.EMOTION_TOUR) {
      color = "rgb(1, 72, 128)";
      noiseSvg = `<svg width="50" height="47" viewBox="0 0 61 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
          <filter x="-12.8%" y="-7.4%" width="125.6%" height="118.5%" filterUnits="objectBoundingBox" id="aps8rsh9fa">
              <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
              <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1"/>
          </filter>
          <path d="M25.5 51c-1.119-2.237-2.76-4.21-4.552-6.16l-.99-1.064-1.859-1.98-.647-.707-.64-.716-.315-.364-.623-.737C12.903 35.668 10.5 31.475 10.5 25.5 10.5 14.73 19.23 6 30 6l.46.005C41.017 6.25 49.5 14.885 49.5 25.5c0 5.975-2.403 10.168-5.374 13.772l-.623.737c-.104.122-.21.243-.316.364l-.64.716-.646.706-2.357 2.512C37.564 46.434 35.72 48.56 34.5 51a33.923 33.923 0 0 0-1.67 3.938l-.247.722-.216.666-.447 1.424-.158.472c-.392 1.111-.77 1.702-1.591 1.771L30 60c-.884 0-1.291-.52-1.683-1.563l-.157-.444-.08-.243-.447-1.424-.216-.666-.246-.722A33.923 33.923 0 0 0 25.5 51z" id="5agnxvaufb"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
          <path d="M.529 0h60v60h-60z"/>
          <use filter="url(#aps8rsh9fa)" xlink:href="#5agnxvaufb" fill-rule="nonzero" fill="#000" transform="translate(.529)"/>
          <path d="M25.529 51c-1.119-2.237-2.76-4.21-4.552-6.16l-.99-1.064-1.859-1.98-.647-.707-.64-.716-.316-.364-.622-.737c-2.971-3.604-5.374-7.797-5.374-13.772 0-10.77 8.73-19.5 19.5-19.5l.46.005c10.557.245 19.04 8.88 19.04 19.495 0 5.975-2.403 10.168-5.374 13.772l-.623.737-.316.364-.64.716-.647.706-2.356 2.512c-1.98 2.127-3.824 4.253-5.044 6.693a33.923 33.923 0 0 0-1.67 3.938l-.248.722-.215.666-.447 1.424-.159.472c-.391 1.111-.768 1.702-1.59 1.771l-.171.007c-.884 0-1.291-.52-1.683-1.563l-.157-.444-.08-.243-.447-1.424-.216-.666-.247-.722A33.923 33.923 0 0 0 25.53 51z" fill="#014880"/>
          <path d="m31.058 24.62.578 1.063-3.31 1.928a.322.322 0 0 0 .016.57s4.553 1.915 4.939 2.2c.386.287.538.84.015 1.297-.625.545-6.282 4.204-6.282 4.204h-7.497s10.282-4.403 10.488-4.506c.322-.16.408-.55.098-.742-.154-.096-.963-.494-1.817-.916l-.428-.212c-.71-.352-1.382-.69-1.662-.85-.44-.254-.4-.923.004-1.176l4.858-2.86zm4.672-6.322c2.029-.018 3.68 1.581 3.68 3.557 0 .18-.015.356-.042.528a3.21 3.21 0 0 1-.374 1.04l-3.231 5.815-3.233-5.82a3.197 3.197 0 0 1-.367-.998 3.392 3.392 0 0 1-.022-.973c.212-1.759 1.773-3.133 3.589-3.15zm.033 1.532a1.99 1.99 0 1 0 0 3.979 1.99 1.99 0 0 0 0-3.98z" fill="#FFF"/>
          <path d="M30.523 6.25c5.421.123 10.221 2.3 13.673 5.752A18.997 18.997 0 0 1 49.78 25.5c0 5.907-2.38 10.05-5.315 13.61l-.619.734-.953 1.076-.645.704-2.357 2.513c-1.998 2.145-3.854 4.29-5.085 6.751a34.176 34.176 0 0 0-1.683 3.969l-.248.726-.217.669-.444 1.416c-.27.779-.493 1.31-.757 1.647-.21.27-.447.399-.768.428-.419.001-.721-.088-.955-.284-.28-.235-.464-.608-.651-1.104l-.682-2.106-.217-.67-.248-.725a34.17 34.17 0 0 0-1.683-3.966c-1.128-2.257-2.783-4.249-4.592-6.22l-.99-1.063-1.857-1.979-.645-.703-.636-.713-.936-1.097C13.66 35.55 11.28 31.407 11.28 25.5a19.19 19.19 0 0 1 5.638-13.612A19.187 19.187 0 0 1 30.523 6.25z" stroke="#FFF" stroke-width=".5"/>
      </g>
  </svg>
  `;
      myIcon = L.divIcon({
        html: noiseSvg,
        className: "mynoise",

        iconSize: L.point(50, 50),
      });
    } else if (type == EALL_TRACKS.OFF_ROAD) {
      color = "rgb(204, 131, 41)";
      noiseSvg = `<svg width="43px" height="47px" viewBox="0 0 32 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Pins/Off Road</title>
      <defs>
          <path d="M13.6,27.2 C13.0034647,26.0069294 12.1278097,24.9548613 11.1722751,23.914244 L10.6443499,23.3469916 L10.6443499,23.3469916 L9.65305609,22.2908087 L9.65305609,22.2908087 L9.30798018,21.9143284 L9.30798018,21.9143284 L8.96674052,21.5320153 C8.91029391,21.4677408 8.85408738,21.4031712 8.79816105,21.3382802 L8.46628641,20.9448702 C6.88157973,19.0227361 5.6,16.7865189 5.6,13.6 C5.6,7.8562386 10.2562386,3.2 16,3.2 L16.245485,3.20284035 C21.8758465,3.33325733 26.4,7.93829234 26.4,13.6 C26.4,16.7865189 25.1184203,19.0227361 23.5337136,20.9448702 L23.201839,21.3382802 C23.1459126,21.4031712 23.0897061,21.4677408 23.0332595,21.5320153 L22.6920198,21.9143284 L22.6920198,21.9143284 L22.3469439,22.2908087 L22.3469439,22.2908087 L21.0901561,23.6306259 C20.033707,24.7647415 19.0507658,25.8984685 18.4,27.2 C18,28 17.7206859,28.7 17.5090433,29.3 L17.3773919,29.6851852 L17.3773919,29.6851852 L17.2623006,30.0407407 L17.2623006,30.0407407 L17.0241155,30.8 L17.0241155,30.8 L16.9394958,31.0518519 C16.730466,31.6444444 16.5294254,31.9592593 16.0909365,31.9962963 L16,32 C15.5287605,32 15.3113317,31.7222222 15.1022764,31.1666667 L15.018519,30.9296296 C15.00444,30.8876543 14.9902527,30.8444444 14.9758845,30.8 L14.7376994,30.0407407 L14.7376994,30.0407407 L14.6226081,29.6851852 L14.6226081,29.6851852 L14.4909567,29.3 C14.2793141,28.7 14,28 13.6,27.2 Z" id="path-1"></path>
          <filter x="-24.0%" y="-13.9%" width="148.1%" height="134.7%" filterUnits="objectBoundingBox" id="filter-2">
              <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
              <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
          </filter>
      </defs>
      <g id="Version-6" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Pins/Off-Road">
              <g id="Influece_Rect">
                  <rect x="0" y="0" width="32" height="32"></rect>
              </g>
              <g id="shadow" fill-rule="nonzero" fill="black" fill-opacity="1">
                  <use filter="url(#filter-2)" xlink:href="#path-1"></use>
              </g>
              <path d="M13.6,27.2 C13.0034647,26.0069294 12.1278097,24.9548613 11.1722751,23.914244 L10.6443499,23.3469916 L10.6443499,23.3469916 L9.65305609,22.2908087 L9.65305609,22.2908087 L9.30798018,21.9143284 L9.30798018,21.9143284 L8.96674052,21.5320153 C8.91029391,21.4677408 8.85408738,21.4031712 8.79816105,21.3382802 L8.46628641,20.9448702 C6.88157973,19.0227361 5.6,16.7865189 5.6,13.6 C5.6,7.8562386 10.2562386,3.2 16,3.2 L16.245485,3.20284035 C21.8758465,3.33325733 26.4,7.93829234 26.4,13.6 C26.4,16.7865189 25.1184203,19.0227361 23.5337136,20.9448702 L23.201839,21.3382802 C23.1459126,21.4031712 23.0897061,21.4677408 23.0332595,21.5320153 L22.6920198,21.9143284 L22.6920198,21.9143284 L22.3469439,22.2908087 L22.3469439,22.2908087 L21.0901561,23.6306259 C20.033707,24.7647415 19.0507658,25.8984685 18.4,27.2 C18,28 17.7206859,28.7 17.5090433,29.3 L17.3773919,29.6851852 L17.3773919,29.6851852 L17.2623006,30.0407407 L17.2623006,30.0407407 L17.0241155,30.8 L17.0241155,30.8 L16.9394958,31.0518519 C16.730466,31.6444444 16.5294254,31.9592593 16.0909365,31.9962963 L16,32 C15.5287605,32 15.3113317,31.7222222 15.1022764,31.1666667 L15.018519,30.9296296 C15.00444,30.8876543 14.9902527,30.8444444 14.9758845,30.8 L14.7376994,30.0407407 L14.7376994,30.0407407 L14.6226081,29.6851852 L14.6226081,29.6851852 L14.4909567,29.3 C14.2793141,28.7 14,28 13.6,27.2 Z" id="pin" fill="#B21E23"></path>
              <path d="M15.9943317,3.44993573 C18.8536712,3.51478221 21.3859087,4.66279698 23.2067929,6.48368116 C25.0386444,8.31553268 26.15,10.827998 26.15,13.6 C26.15,16.7188651 24.8912126,18.9053218 23.3426251,20.7836709 L23.0146282,21.1725933 L22.5077237,21.7454055 L22.1646078,22.1197721 L20.9072267,23.4602239 C19.8338087,24.612556 18.8374947,25.7659936 18.1763932,27.0881966 C17.7709375,27.899108 17.48781,28.608654 17.2711652,29.2230975 L16.7879852,30.7178874 C16.6493041,31.1166911 16.5376621,31.3898624 16.4024797,31.5631605 C15.5141008,31.5077197 15.4288477,31.3240383 15.3383,31.0840374 L14.974256,29.9598645 C14.5108424,28.6044163 14.2279855,27.8969539 13.8236068,27.0881966 C13.2169557,25.8748944 12.3283091,24.8035843 11.3552818,23.7439251 L10.8266375,23.1759032 L9.8373522,22.1218858 L9.49449157,21.7478547 L9.15700438,21.3698436 L8.65918164,20.7858374 C7.10878739,18.9053218 5.85,16.7188651 5.85,13.6 C5.85,10.7971549 6.98607745,8.25965489 8.82286617,6.42286617 C10.6596549,4.58607745 13.1971549,3.45 15.9943317,3.44993573 Z" id="outline" stroke="#FFFFFF" stroke-width="0.5" fill="#CC8329"></path>
              <path d="M18.1772266,10.9391371 C17.5913459,10.9391371 17.116174,10.4641897 17.116174,9.87808447 C17.116174,9.29220377 17.5913459,8.8172564 18.1772266,8.8172564 C18.7631073,8.8172564 19.2382793,9.29220377 19.2382793,9.87808447 C19.2382793,10.4641897 18.7631073,10.9391371 18.1772266,10.9391371 M18.1594863,8.00007746 C17.1909529,8.00861079 16.3587284,8.74180377 16.2453249,9.67979676 C16.223767,9.85854763 16.229381,10.0323582 16.2574512,10.1987582 C16.2893389,10.3858178 16.360974,10.5643441 16.4530442,10.7314178 L18.1772266,13.835081 L19.9002863,10.733888 C19.9959494,10.560302 20.069381,10.3736915 20.1001459,10.1787722 C20.1145179,10.0871511 20.1221529,9.99305991 20.1221529,9.89717219 C20.1221529,8.84375465 19.2416477,7.99064588 18.1594863,8.00007746" id="Fill-3-Copy" fill="#FFFFFF"></path>
              <path d="M15.5100646,13.5165791 L17.5028585,15.4742272 L18.1728088,14.2646848 L18.3139217,14.2771923 C19.9735956,14.8692205 20.9957127,15.3213454 21.380273,15.633567 L20.3536748,15.2828764 L18.4014197,14.8741742 L17.6697001,16.9034125 L15.9722935,15.243686 L14.7603223,17.4853705 L10.6666667,17.4853705 L13.0109286,15.243686 L13.9014679,14.3866342 L14.4155489,13.905469 L14.6914965,13.6572987 L14.8218222,13.5455132 L14.926408,13.4609651 L14.9821115,13.4194892 L15.0267944,13.389755 L15.0606247,13.3716173 C15.1819434,13.3188092 15.3198476,13.3188092 15.5100646,13.5165791 Z" id="Path-3" fill="#FFFFFF" fill-rule="nonzero"></path>
          </g>
      </g>
  </svg>
  `;
      myIcon = L.divIcon({
        html: noiseSvg,
        className: "mynoise",

        iconSize: L.point(50, 50),
      });
    }

    // you can set .my-div-icon styles in CSS
    let clusterGroup = L.markerClusterGroup({
      iconCreateFunction: function (cluster: any) {
      cluster.type = type;
      let markers = cluster.getAllChildMarkers();

      clusterSvgTemplate = `<svg xmlns="http://www.w3.org/2000/svg"  height="40px" width="40px" class="parent-svg">
       <circle cx="21px" cy="21px" r="17" fill="none" stroke="${color}" stroke-width="2" class="svg-icon"/><circle cx="21px" cy="21px" r="14" fill="${color}" class="svg-icon1"/>'
       '<text x="22" y="24" font-size="10px" font-family="MBCorpoSTextOffice-Regular"  fill="#FFF" text-anchor="middle">${markers.length}</text>'
       '</svg>`;
        return L.divIcon({
          html: clusterSvgTemplate,
          className: "mycluster",
          iconSize: L.point(40, 40),
        });
      },
      maxClusterRadius: 50,
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      removeOutsideVisibleBounds: true,
      zoomToBoundsOnClick: true,
      animate: true,
    });

    // Clear existing markers
    clusterGroup.clearLayers();
    clusterGroup.on("spiderfied unspiderfied", (event: any) => {
      this.spiderfyMarkers = event.markers;
    });

    let marker: any = [];
    dataPoints.forEach((point: any, index: any) => {
      marker = L.marker(new L.LatLng(point[0], point[1]), {
        icon: myIcon,
      });
      marker.data = data[index];
      marker.data.viewType = type;
      marker.type = type;

      clusterGroup.addLayer(marker);
      marker.on("click", (e: any) => {
          if (!this.isShowAddCircuitSearch) {
            let coordinate = e.target.data.coordinates.split(",");
            this.circleCoordinates = e.target.data.coordinates;
            this.pannedCircuitData.emit({
              guid: e.target.data.guid,
              type: e.target.data.viewType,
            });
            this.isMarkerClicked = true;
            this.onMarkerClick(e);
            this.map.flyTo([coordinate[0], coordinate[1]], 15);
          }
      });
    });
    this.dummyLayers.push(clusterGroup);
    map.addLayer(clusterGroup);
    this.isMapPanned = false;
  }
  
  /**
   * 
   * @param e 
   */
  public onMarkerClick(e: any): void {
    // Get position of the "clicked" marker
    this.disableSearchBar.emit();
    let data: any;
    if (e.target) {
      data = e.target.data;
    } else {
      data = e;
    }
    this.isMarkerClicked = true;
    if (data.viewType == EALL_TRACKS.CIRCUIT) {
      this.locationsHttpService.getCircuit(data.guid).subscribe({
        next: (item: any) => {
          item.guid = data.guid;
          this.isApiSuccess = true;
          item.viewType = data.viewType || data.type;
          this.showLocationInfo.emit(item);
        },
        error: (error: any) => {
          this.backToLocations();
          this.toastService.show(
            ToastType.Error,
            "circuit does not exist",
            "10%",
            "40%"
          );
          setTimeout(() => {
            this.location.replaceState(`/${this.selectedLanguage}/locations`);
            this.toastService.hide();
          }, APP_CONSTANTS.MIN_TIME_TOASTER);
          this.deletedCircuitGuid = data.guid;
          this.map.setZoom(this._createMapService.mapCurrentData.zoom);
          this.isMarkerClicked = false;
          this.isApiSuccess = false;
          this.searchLocation = null;
          this.circuitInfo = this.circuitInfo.filter((item: any) => {
            return item.guid !== this.deletedCircuitGuid;
          });

          if (this.selectedType == EALL_TRACKS.CIRCUIT) {
            this.startClustering(this.map, this.circuitInfo, EALL_TRACKS.CIRCUIT);
          } else if (this.selectedType == EALL_TRACKS.ALL) {
            this.startClustering(this.map, this.circuitInfo, EALL_TRACKS.CIRCUIT);
            this.startClustering(this.map, this.emotionTourInfo, EALL_TRACKS.EMOTION_TOUR);
            this.startClustering(this.map, this.offRoadTourList, EALL_TRACKS.OFF_ROAD)
          }
        },
      });
    } else if (data.viewType == EALL_TRACKS.EMOTION_TOUR || data.viewType == EALL_TRACKS.OFF_ROAD) {
      this.locationsHttpService.getEmotionTourDetails(data.guid).subscribe({
        next: (item: any) => {
          item.guid = data.guid;
          item.viewType = data.viewType || data.type;
          this.isApiSuccess = true;
          this.showLocationInfo.emit(item);
        },
      });
    }
  }

  private calculateDynamicRadius(): any {
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const emToPx = (em: number) => em * fontSize;
    const leftPaddingEm = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--left-floating-panel-width'))
    const topPaddingEm: number = parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--header-height'))) ;
    const bottomPaddingEm: number = parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--gutter-space-large')));
    const topPaddingPx = emToPx(topPaddingEm);
    const bottomPaddingPx = emToPx(bottomPaddingEm);
    const leftPaddingPx = emToPx(leftPaddingEm);
    let centerLatLng = this.map.getCenter();
    // get map center
    let pointC = this.map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
    let pointX = [pointC.x + 1, pointC.y]; // add one pixel to x
    // convert containerpoints to latlng's
    let latLngC = this.map.containerPointToLatLng(pointC);
    let mapContainer = this.mapElement.nativeElement;
    let mapContainerRect = mapContainer.getBoundingClientRect();
    let mapContainerWidth = mapContainerRect.width;
    let mapContainerHeight = mapContainerRect.height;
    let mapContainerCenterX = mapContainerRect.left + mapContainerWidth / 2;
    let mapContainerCenterY = mapContainerRect.top + mapContainerHeight / 2;
    let offsetX = pointX[0] - mapContainerCenterX;
    let offsetY = pointX[1] - mapContainerCenterY;
    let latLngXInViewPort = this.map.containerPointToLatLng(L.point(mapContainerCenterX + offsetX, mapContainerCenterY + offsetY));
    let radiusReduction  = topPaddingPx + (2 * bottomPaddingPx) + leftPaddingPx; // this is 600
    let distanceX = Math.round(latLngC.distanceTo(latLngXInViewPort)); // calculate distance between c and x (latitude)
    let adjustedRadius = distanceX > 0 && distanceX < radiusReduction ? distanceX : Math.abs(radiusReduction - distanceX);
    let resultObj = {
      lat: centerLatLng.lat,
      lng: centerLatLng.lng,
      radius: Math.round(adjustedRadius)
    };
    return resultObj;
  }

  /**
   * 
   * @param map 
   * @param center 
   * @param radius 
   */
  public addCircleGeofence(map: any, center: string[], radius: any[] | any): void {
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const emToPx = (em: number) => em * fontSize;
    const topLeftPaddingEm: number[] = [parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--left-floating-panel-width')), parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--header-height')))];
    const bottomRightPaddingEm: number[] = [parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--gutter-space-large'))), parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--carousel-reg-height'))) - parseFloat((getComputedStyle(document.documentElement).getPropertyValue('--gutter-space-large')))]
    const topLeftPaddingPx = topLeftPaddingEm.map(emToPx);
    const bottomRightPaddingPx = bottomRightPaddingEm.map(emToPx);
    let circleOptions = {
      color: "rgba(178, 30, 35, 0.12)",
      fillColor: "rgba(178, 30, 35, 0.12)",
      lineWidth: 0,
      fillOpacity: 0.43,
      radius:radius
    };
    // When will it come to this condition? and when will it come to else condition?
    if (this.radius.length) {
      this.polyline = L.polyline(this.radius, {
        color: "rgba(178, 30, 35, 0.12)",
        fillColor: "rgba(178, 30, 35, 0.12)",
        fill: true,
        fillOpacity: 0.5,
      });
      this.polyline.type = "geo-Polyline";
      this.map.addLayer(this.polyline);
      map?.fitBounds(this.polyline?.getBounds(), {
        paddingTopLeft: topLeftPaddingPx,
        paddingBottomRight: bottomRightPaddingPx,
      });
    } else {
      this.circlularCircuit = L.circle([+center[0], +center[1]], radius, circleOptions)
      this.circlularCircuit.type = "circle-geofence";
      this.map.addLayer(this.circlularCircuit);
      map?.fitBounds(this.circlularCircuit.getBounds(), {
        paddingTopLeft: topLeftPaddingPx,
        paddingBottomRight: bottomRightPaddingPx,
      });
    }
  }

  public addPolylineToLeafLet(posList: any, sectorList: any, color: any, _width: any, screen: string): void {
    if (posList) {
      const fullList = posList;
      const sectorPoints = sectorList;
      this.polyline = L.polyline(fullList, { color: color });
      this.polyline.type = "circuitPolyline";
      this.map.addLayer(this.polyline);

      sectorPoints.forEach((data: any, i: any) => {
        this.rotateleafletDomMarker(this.map, data, i, color);
      });
      if (this.map.getZoom() < 13) {
        this.deletedSectorPoints = [];
        let layers: any = [];
        this.map.eachLayer(function (layer: any) {
          layers.push(layer);
        });
        if (layers.length > 4) {
          layers = layers.slice(4);
          layers?.map((layer: any) => {
            this.deletedSectorPoints.push(layer);
            this.map.removeLayer(layer);
          });
        }
      }
    }
    this.recenterUserToCircuitSelected(this.circleCoordinates, screen)
  }

  addControlPlaceholders(map: any) {
    let corners = map._controlCorners,
      l = "leaflet-",
      container = map._controlContainer;

    function createCorner(vSide: any, hSide: any) {
      let className = l + vSide + " " + l + hSide;

      corners[vSide + hSide] = L.DomUtil.create("div", className, container);
    }

    createCorner("verticalcenter", "left");
    createCorner("verticalcenter", "right");
  }

  rotateleafletDomMarker(map: any, data: any, index: any, color: any) {
    let rotate;
    let transform = 0.0;
    if (data[3] == 0) {
      data[3] = 130;
      rotate = 36;
    } else {
      rotate = 270;
    }
    let domIconElement = document.createElement("div");

    // set the anchor using margin css property depending on the content's (svg element below) size
    // to make sure that the icon's center represents the marker's geo positon

    // add content to the element
    if (index >= 1) {
      domIconElement.setAttribute("id", "bar-image");
      domIconElement.style.margin = `${this.barMargin}px`;
      domIconElement.innerHTML = `<svg width="${this.barWidth}px" height="${this.barHeight}px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Line 5</title>
    <defs>
        <path d="M9.71777003,28.6387665 L29.71777,12.6387665" id="path-1"></path>
        <filter x="-44.1%" y="-55.1%" width="188.1%" height="210.1%" filterUnits="objectBoundingBox" id="filter-2">
            <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feMorphology radius="2" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
            <feOffset dx="0" dy="0" in="shadowInner" result="shadowInner"></feOffset>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="Line-5" transform="translate(19.717770, 20.638767) rotate(36.640192) translate(-19.717770, -20.638767) ">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use stroke="${color}" stroke-width="5" xlink:href="#path-1"></use>
        </g>
    </g>
</svg>`;
      domIconElement.style.transform = "rotate(" + data[3] + "deg)";

    } else {
      domIconElement.setAttribute("id", "start-image");
      domIconElement.style.margin = `-15px`;
      domIconElement.innerHTML = `<svg width="${this.startLineWidth}px" height="${this.startLineHeight}px" viewBox="0 0 40 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>start_line</title>
      <defs>
          <filter x="-31.0%" y="-17.0%" width="161.9%" height="133.9%" filterUnits="objectBoundingBox" id="filter-1">
              <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
              <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.704210081 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
              <feMerge>
                  <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                  <feMergeNode in="SourceGraphic"></feMergeNode>
              </feMerge>
          </filter>
          <path d="M0.697164744,2.92834653 L11.0978585,2.92834653 L9.73400514,0 L15.6053632,3.49486192 L9.91004728,7.05334489 L11.0978585,4.40087676 L0.697164744,4.40087676 C0.232388941,4.39120411 0,4.14335558 0,3.65735196 C0,3.17134834 0.232388941,2.92834653 0.697164744,2.92834653 Z" id="path-2"></path>
          <path d="M6.24041626,0 C7.38924466,0 8.32055502,0.931309323 8.32055502,2.08013875 L8.32055502,43.6829138 C8.32055502,44.8317428 7.38924466,45.7630526 6.24041626,45.7630526 L2.08013875,45.7630526 C0.931309843,45.7630526 0,44.8317428 0,43.6829138 L0,2.08013875 C0,0.931309323 0.931309843,0 2.08013875,0 L6.24041626,0 Z" id="path-4"></path>
          <path d="M4.16027751,0 L4.16027751,4.16027751 L0,4.16027751 L0,2.08013875 C0,0.931309323 0.931309843,0 2.08013875,0 L4.16027751,0 Z" id="path-6"></path>
          <polygon id="path-8" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <path d="M2.08013875,0 C3.22896715,0 4.16027751,0.931309323 4.16027751,2.08013875 L4.16027751,4.16027751 L0,4.16027751 L0,0 L2.08013875,0 Z" id="path-10"></path>
          <polygon id="path-12" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-14" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-16" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-18" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-20" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-22" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-24" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-26" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-28" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-30" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-32" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-34" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-36" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-38" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-40" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-42" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-44" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <path d="M4.16027751,0 L4.16027751,4.16027751 L2.08013875,4.16027751 C0.931309843,4.16027751 0,3.22896767 0,2.08013875 L0,0 L4.16027751,0 Z" id="path-46"></path>
          <path d="M4.16027751,0 L4.16027751,2.08013875 C4.16027751,3.22896767 3.22896715,4.16027751 2.08013875,4.16027751 L0,4.16027751 L0,0 L4.16027751,0 Z" id="path-48"></path>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="start_line" filter="url(#filter-1)"  transform="translate(19.717770, 20.638767) rotate(${rotate}) translate(-19.717770, -20.638767)">
              <g id="Group-3" transform="translate(10.223564, ${transform})">
                  <mask id="mask-3" fill="white">
                      <use xlink:href="#path-2"></use>
                  </mask>
                  <g id="Clip-2"></g>
                  <polygon id="Fill-1" fill="#D9252B" mask="url(#mask-3)" points="-1.04006938 -1.04006938 16.6454326 -1.04006938 16.6454326 8.09341427 -1.04006938 8.09341427"></polygon>
              </g>
              <g id="Group-73" transform="translate(0.000000, 1.368242)">
                  <g id="Group-6" transform="translate(0.000000, 0.000000)">
                      <mask id="mask-5" fill="white">
                          <use xlink:href="#path-4"></use>
                      </mask>
                      <g id="Clip-5"></g>
                      <polygon id="Fill-4" fill="#D8D8D8" mask="url(#mask-5)" points="-1.04006938 -1.04006938 9.3606244 -1.04006938 9.3606244 46.803122 -1.04006938 46.803122"></polygon>
                  </g>
                  <g id="Group-9" transform="translate(0.000000, 0.000000)">
                      <mask id="mask-7" fill="white">
                          <use xlink:href="#path-6"></use>
                      </mask>
                      <g id="Clip-8"></g>
                      <polygon id="Fill-7" fill="#FFFFFF" mask="url(#mask-7)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-12" transform="translate(0.000000, 4.160278)">
                      <mask id="mask-9" fill="white">
                          <use xlink:href="#path-8"></use>
                      </mask>
                      <g id="Clip-11"></g>
                      <polygon id="Fill-10" fill="#000000" mask="url(#mask-9)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-15" transform="translate(4.160278, 0.000000)">
                      <mask id="mask-11" fill="white">
                          <use xlink:href="#path-10"></use>
                      </mask>
                      <g id="Clip-14"></g>
                      <polygon id="Fill-13" fill="#000000" mask="url(#mask-11)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-18" transform="translate(4.160278, 4.160278)">
                      <mask id="mask-13" fill="white">
                          <use xlink:href="#path-12"></use>
                      </mask>
                      <g id="Clip-17"></g>
                      <polygon id="Fill-16" fill="#FFFFFF" mask="url(#mask-13)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-21" transform="translate(0.000000, 8.320555)">
                      <mask id="mask-15" fill="white">
                          <use xlink:href="#path-14"></use>
                      </mask>
                      <g id="Clip-20"></g>
                      <polygon id="Fill-19" fill="#FFFFFF" mask="url(#mask-15)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-24" transform="translate(4.160278, 8.320555)">
                      <mask id="mask-17" fill="white">
                          <use xlink:href="#path-16"></use>
                      </mask>
                      <g id="Clip-23"></g>
                      <polygon id="Fill-22" fill="#000000" mask="url(#mask-17)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-27" transform="translate(0.000000, 12.480833)">
                      <mask id="mask-19" fill="white">
                          <use xlink:href="#path-18"></use>
                      </mask>
                      <g id="Clip-26"></g>
                      <polygon id="Fill-25" fill="#000000" mask="url(#mask-19)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-30" transform="translate(4.160278, 12.480833)">
                      <mask id="mask-21" fill="white">
                          <use xlink:href="#path-20"></use>
                      </mask>
                      <g id="Clip-29"></g>
                      <polygon id="Fill-28" fill="#FFFFFF" mask="url(#mask-21)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-33" transform="translate(0.000000, 16.641110)">
                      <mask id="mask-23" fill="white">
                          <use xlink:href="#path-22"></use>
                      </mask>
                      <g id="Clip-32"></g>
                      <polygon id="Fill-31" fill="#FFFFFF" mask="url(#mask-23)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-36" transform="translate(4.160278, 16.641110)">
                      <mask id="mask-25" fill="white">
                          <use xlink:href="#path-24"></use>
                      </mask>
                      <g id="Clip-35"></g>
                      <polygon id="Fill-34" fill="#000000" mask="url(#mask-25)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-39" transform="translate(0.000000, 20.801388)">
                      <mask id="mask-27" fill="white">
                          <use xlink:href="#path-26"></use>
                      </mask>
                      <g id="Clip-38"></g>
                      <polygon id="Fill-37" fill="#000000" mask="url(#mask-27)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-42" transform="translate(4.160278, 20.801388)">
                      <mask id="mask-29" fill="white">
                          <use xlink:href="#path-28"></use>
                      </mask>
                      <g id="Clip-41"></g>
                      <polygon id="Fill-40" fill="#FFFFFF" mask="url(#mask-29)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-45" transform="translate(0.000000, 24.961665)">
                      <mask id="mask-31" fill="white">
                          <use xlink:href="#path-30"></use>
                      </mask>
                      <g id="Clip-44"></g>
                      <polygon id="Fill-43" fill="#FFFFFF" mask="url(#mask-31)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-48" transform="translate(4.160278, 24.961665)">
                      <mask id="mask-33" fill="white">
                          <use xlink:href="#path-32"></use>
                      </mask>
                      <g id="Clip-47"></g>
                      <polygon id="Fill-46" fill="#000000" mask="url(#mask-33)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-51" transform="translate(0.000000, 29.121943)">
                      <mask id="mask-35" fill="white">
                          <use xlink:href="#path-34"></use>
                      </mask>
                      <g id="Clip-50"></g>
                      <polygon id="Fill-49" fill="#000000" mask="url(#mask-35)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-54" transform="translate(4.160278, 29.121943)">
                      <mask id="mask-37" fill="white">
                          <use xlink:href="#path-36"></use>
                      </mask>
                      <g id="Clip-53"></g>
                      <polygon id="Fill-52" fill="#FFFFFF" mask="url(#mask-37)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-57" transform="translate(0.000000, 33.282220)">
                      <mask id="mask-39" fill="white">
                          <use xlink:href="#path-38"></use>
                      </mask>
                      <g id="Clip-56"></g>
                      <polygon id="Fill-55" fill="#FFFFFF" mask="url(#mask-39)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-60" transform="translate(4.160278, 33.282220)">
                      <mask id="mask-41" fill="white">
                          <use xlink:href="#path-40"></use>
                      </mask>
                      <g id="Clip-59"></g>
                      <polygon id="Fill-58" fill="#000000" mask="url(#mask-41)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-63" transform="translate(0.000000, 37.442498)">
                      <mask id="mask-43" fill="white">
                          <use xlink:href="#path-42"></use>
                      </mask>
                      <g id="Clip-62"></g>
                      <polygon id="Fill-61" fill="#000000" mask="url(#mask-43)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-66" transform="translate(4.160278, 37.442498)">
                      <mask id="mask-45" fill="white">
                          <use xlink:href="#path-44"></use>
                      </mask>
                      <g id="Clip-65"></g>
                      <polygon id="Fill-64" fill="#FFFFFF" mask="url(#mask-45)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-69" transform="translate(0.000000, 41.602775)">
                      <mask id="mask-47" fill="white">
                          <use xlink:href="#path-46"></use>
                      </mask>
                      <g id="Clip-68"></g>
                      <polygon id="Fill-67" fill="#FFFFFF" mask="url(#mask-47)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-72" transform="translate(4.160278, 41.602775)">
                      <mask id="mask-49" fill="white">
                          <use xlink:href="#path-48"></use>
                      </mask>
                      <g id="Clip-71"></g>
                      <polygon id="Fill-70" fill="#000000" mask="url(#mask-49)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
              </g>
          </g>
      </g>
  </svg>`;
      domIconElement.style.transform = "rotate(" + data[3] + "deg)";
    }
      this.barIcon = L.divIcon({
        html: domIconElement,
        className: "sector",
      });
      let marker: any;
      marker = L.marker([data[0], data[1]], { icon: this.barIcon });
      marker.type = "sector";
      this.map.addLayer(marker);
  }

  /**
   * @description Leaflet-Geoman plugin for drawing and editing geometric shapes on a map. It sets up global
   * options for the plugin, including styles for temporary lines, hint lines, and paths, 
   * and enables finishing the drawing on double-click. 
   */
  public setupGeoman(): void {
    let drawOptions = {
      templineStyle: { color: "#b21e23" },
      hintlineStyle: { color: "#b21e23", dashArray: [5, 5] },
      pathOptions: { color: "#b21e23", fillColor: "#b21e23", fillOpacity: 0.2 },
      finishOnDoubleClick: true,
    };
    this.map.pm.setGlobalOptions(drawOptions);
    if (this.selectedOption === "polygon") {
      this.drawPolyLineShape();
    }
  }

  public locateUser(): void {
    this.map.fitBounds(this.locateUserLocation, {
      paddingTopLeft: [300, 160],
      paddingBottomRight: [20, 300],
    });
  }

  /**
   * @description This method is used to draw polygon shape on map
   * This method is called when to draw circle shape on map. Each point that
   * gets clicked on the map is added to the polygon shape.
   * Similarly for circle shape, the circle is drawn on the map and the radius
   */
  public setupEventListeners(): void {
    //This event adds coordinates for polygon and circle once drawn without edits
    this.map.on("pm:create", (e: any) => {
      if (e.shape === "Polygon") {
        this.polygon = e.layer;
        let newgeoJson = e.layer._latlngs;
        newgeoJson[0].forEach((geoArray: any) => {
          this.newpolycoordinates.push([geoArray["lat"], geoArray["lng"]]);
        });
        this.newpolycoordinates.push([
          newgeoJson[0][0]["lat"],
          newgeoJson[0][0]["lng"],
        ]);
        this.encodedDetails = polyline.encode(this.newpolycoordinates);
        this.selectedLocation.emit({
          encodedDetails: this.encodedDetails,
          showAlert: false,
        });
        this.circuitService.setCoordinateValue(this.encodedDetails);
        this.circuitService.setCoordinatesLocation(this.newpolycoordinates[0][0] + "," + this.newpolycoordinates[0][1])
        let editPolygonCoordinates = {
          name: "polygon",
          coordinates: this.newpolycoordinates,
        };
        this.newpolycoordinates = [];
        this.undoArray.push(editPolygonCoordinates);
        e.layer.pm.enable({
          allowSelfIntersection: false,
          finishOnDoubleClick: true,
        });
      }
      if (e.shape === "Circle") {
        this.circle = e.layer;
        this.circle.name = "circleShape";
        this.circleGeoCoordinates = {
          radius: e.layer._mRadius / 1000,
          lat: e.layer._latlng.lat,
          lng: e.layer._latlng.lng,
        };
        this.circuitService.setCoordinateValue(this.circleGeoCoordinates);
        this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat + "," + this.circleGeoCoordinates.lng)
        this.selectedLocation.emit({
          circleGeoCoordinates: this.circleGeoCoordinates,
          showAlert: false,
        });
        let drawnCircleCoordinates = {
          name: "circle",
          radius: e.layer._mRadius,
          coordinates: [
            this.circleGeoCoordinates.lat,
            this.circleGeoCoordinates.lng,
          ],
        };
        this.undoArray.push(drawnCircleCoordinates);
        e.layer.pm.enable({
          allowSelfIntersection: false,
          finishOnDoubleClick: true,
        });
      }
    });
    this.map.on("pm:drawstart", (e: any) => {
      this.enableRadiusBar.emit(false);
      if (e.shape === "Circle") {
        this.emitRadius.emit(0);
        this.emitNotification.emit("BP_LM_CENTRE_POINT");
        const circle = e.workingLayer;
        circle.on("pm:centerplaced", (): void => {
          this.enableRadiusBar.emit(true);
          this.emitNotification.emit("BP_LM_DRAG_GEO_FENCE");
        });
        circle.on("pm:change", (layer: any): void => {
          this.emitRadius.emit((layer.layer._mRadius / 1000).toPrecision(2));
        });
      }
      if (e.shape === "Polygon") {
        this.progressPolygon = e.workingLayer;
        this.progressPolygon.on("pm:vertexadded", (layer: any): void => {
          this.coordinatesInProgress = [];
          let latLngs = layer.layer._latlngs;
          latLngs.forEach((arra: any) => {
            this.coordinatesInProgress.push([arra["lat"], arra["lng"]]);
          });
          let polygonCoordinates: any = {
            name: "polygon",
            coordinates: this.coordinatesInProgress,
          };
          this.undoArray.push(polygonCoordinates);
        });
        this.map.pm.setGlobalOptions({ hideMiddleMarkers: true });
      }
    });
    //This event add coordinates in array for polygon and circle on editing vertexes or radius
    this.map.on("pm:drawend", () => {
      if (this.polygon) {
        this.polygon.on("pm:edit", (layer: any): void => {
          let polycoordinates: any = [];
          let geoJson = layer.layer._latlngs;
          geoJson[0].forEach((arra: any) => {
            polycoordinates.push([arra["lat"], arra["lng"]]);
          });
          polycoordinates.push([geoJson[0][0]["lat"], geoJson[0][0]["lng"]]);
          this.encodedDetails = polyline.encode(polycoordinates);
          this.circuitService.setCoordinateValue(this.encodedDetails);
          this.circuitService.setCoordinatesLocation(polycoordinates[0][0] + "," + polycoordinates[0][1])
          let modifiedPolygonCoordinates = {
            name: "polygon",
            coordinates: polycoordinates,
          };
          this.undoArray.push(modifiedPolygonCoordinates);
        });
      }
      if (this.circle) {
        this.emitRadius.emit((this.circle._mRadius / 1000).toPrecision(2));
        this.emitNotification.emit(LOCATIONS_CONSTANTS.HELPER_MSGS.DRAG_AND_HOLD_GEOFENCE);

        this.circleGeoCoordinates = {
          'radius': (this.circle._mRadius),
          'lat': this.circle._latlng.lat,
          'lng': this.circle._latlng.lng
        }
        let editCircleCoordinates = {
          name: "circle",
          radius: this.circleGeoCoordinates.radius,
          coordinates: this.circleGeoCoordinates
        }
        this.circuitService.setCoordinateValue(this.circleGeoCoordinates);
        this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat + "," + this.circleGeoCoordinates.lng)
        this.clearAllLayers();
        this.createUndoCircle(editCircleCoordinates);
      }
    });
  }

  /**
   * @description This method is used to draw shape on map
   * This method also clears all layers on map
   * @param shapeValue 
   */
  public drawShape(shapeValue: any): void {
    let choice = shapeValue.substring(0, 4);
    switch (choice) {
      case "poly":
        this.emitNotification.emit(LOCATIONS_CONSTANTS.HELPER_MSGS.START_CIRCUIT_CREATION);
        this.drawPolyLineShape();
        break;
      case "clea":
      case "esca":
        this.clearAllLayers();
        if (this.previousOption.substring(0, 4) === "poly") {
          this.enableRadiusBar.emit(false);
          this.drawPolyLineShape();
        } else if (this.previousOption.substring(0, 4) === "circ") {
          this.emitNotification.emit("");
          this.drawCircularShape();
        }
        break;
      case "circ":
        this.drawCircularShape();
        break;
    }
  }

  /**
   * @description This method is used to draw polygon shape on map
   * This gives color to polygon shape, colors are defined in the method
   */
  public drawPolyLineShape(): void {
    this.clearPreviousData();
    this.clearAllLayers();
    this.coordinatesInProgress = null;
    this.undoArray.length = 0;
    this.redoArray.length = 0;
    this.map.pm.enableDraw("Polygon", {
      snappable: true,
      snapDistance: 20,
      finishOn: "dblclick",
      templineStyle: { color: "#b21e23" },
      hintlineStyle: { color: "#b21e23", opacity: 1, dashArray: [5, 5] },
      shapeOptions: {
        color: "#b21e23",
        stroke: "none",
      },
    });
  }

  public drawCircularShape(): void {
    this.clearPreviousData();
    this.clearAllLayers();
    this.coordinatesInProgress = null;
    this.undoArray.length = 0;
    this.redoArray.length = 0;
    this.map.pm.enableDraw("Circle", {
      snappable: true,
      snapDistance: 20,
      finishOnDoubleClick: true,
    });
  }

  public captureRadius(): void {
    this.interactiveRadiusLength = document.getElementById("varRadius");
    this.interactiveRadiusLength.addEventListener(
      "keypress",
      function (e: any) {
        console.log(e);
      }
    );
  }

  /**
   * @description This method is used to clear all layers on map
   */
  public clearAllLayers(): void {
    if (this.coordinatesInProgress || this.myTempCircleTextLabel) {
      if (this.myTempCircleTextLabel) {
        this.map.removeLayer(this.myTempCircleTextLabel);
        this.myTempCircleTextLabel = null;
      }
      if (this.coordinatesInProgress) {
        this.coordinatesInProgress = null;
      }
      this.circuitService.setCoordinateValue(null);
      this.circuitService.setCoordinatesLocation(null);
      this.map.pm.disableDraw();
      this.encodedDetails = "";
    }
    this.map?.eachLayer((layer: any) => {
      if (layer.type?.toLowerCase() === "create" || layer.name === "circleShape") {
        this.map.removeLayer(layer);
      }
    });
    if (this.polyline && this.selectedOption != "polygon") {
      this.coordinatesInProgress = null;
    }
    if (this.polygon) {
      this.encodedDetails = "";
      this.circleGeoCoordinates = "";
      this.circuitService.setCoordinateValue(null);
      this.circuitService.setCoordinatesLocation(null);
      this.polygon.remove();
      this.polygon = null;
    }

    if (this.circle) {
      this.encodedDetails = "";
      this.circuitService.setCoordinateValue(null);
      this.circuitService.setCoordinatesLocation(null);
      if (this.myTempCircleTextLabel) {
        this.map.removeLayer(this.myTempCircleTextLabel);
        this.myTempCircleTextLabel = null;
      }
      this.circle.remove();
      this.circle = null;
    }

    if (this.initialCircle) {
      this.initialCircle.remove();
    }

    if (this.geofence) {
      this.geofence.remove();
    }

    if (this.myTextLabel) {
      this.myTextLabel.remove();
    }

    if (this.myCircleTextLabel) {
      this.myCircleTextLabel.remove();
    }

    if (this.myTempCircleTextLabel) {
      this.myTempCircleTextLabel.remove();
    }

    if (this.undoGeofence) {
      this.undoGeofence.remove();
    }

    if (this.undoCircle) {
      this.undoCircle.remove();
    }

    if (this.progressPolygon) {
      this.progressPolygon.remove();
    }
    if (this.selectedType == EALL_TRACKS.ALL || this.isShowAddCircuitSearch == false) this.screenChanged = false;
    this.drawnItems.remove();
  }

  public clearPreviousData(): void {
    this.encodedDetails = null;
    this.circleGeoCoordinates = null;
  }

  /**
   * @param data 
   * @description this code creates a circle on a map, allows the circle to be edited, 
   * and updates the corresponding properties and values in the circuitService based on 
   * the changes made to the circle.
   */
  public createUndoCircle(data: any): void {
    this.undoCircle = L.circle(data.coordinates, {
      radius: data.radius,
      color: "rgba(178, 30, 35, 0.12)",
      fillColor: "rgba(178, 30, 35, 0.12)",
      weight: 0,
      fillOpacity: 0.5,
      className: "leaflet-editing-icon leaflet-div-icon",
    });
    this.circleGeoCoordinates = {
      radius: data.radius / 1000,
      lat: data.coordinates.lat ? data.coordinates.lat : data.coordinates[0],
      lng: data.coordinates.lng ? data.coordinates.lng : data.coordinates[1],
    };
    this.circuitService.setCoordinateValue(this.circleGeoCoordinates);
    this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat + "," + this.circleGeoCoordinates.lng)

    this.map.addLayer(this.undoCircle);
    this.undoCircle.pm.enable({
      allowSelfIntersection: false,
    });

    this.undoCircle.on('pm:change', (layer: any): void => {
      this.emitRadius.emit((layer.layer._mRadius / 1000).toPrecision(2));
      this.circleGeoCoordinates = {
        radius: layer.layer._mRadius / 1000,
        lat: layer.layer._latlng.lat,
        lng: layer.layer._latlng.lng,
      };
      this.circuitService.setCoordinateValue(this.circleGeoCoordinates);
      this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat + "," + this.circleGeoCoordinates.lng)
    });

    this.undoCircle.on("pm:edit", (layer: any): void => {
      this.circleGeoCoordinates = {
        radius: layer.layer._mRadius / 1000,
        lat: layer.layer._latlng.lat,
        lng: layer.layer._latlng.lng,
      };
      this.circuitService.setCoordinateValue(this.circleGeoCoordinates);
      this.circuitService.setCoordinatesLocation(this.circleGeoCoordinates.lat + "," + this.circleGeoCoordinates.lng)
    });
  }


}
