import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, EmbeddedViewRef, HostListener, Injector, Input, TemplateRef } from '@angular/core';
import { GenericTooltipComponent } from '../components/generic-tooltip/generic-tooltip.component';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {

  @Input() tooltip = '';

  public componentRef: ComponentRef<any> = <any>null;
  delay: number = 500;
  offset: number = 10;

  @Input() position: string = 'top'; // Decides tooltip position
  @Input() anchorPos: string = 'middle'; // Decides position of the anchor
  @Input() childTemplate: TemplateRef<any> | any; // Use in case we want to display HTML template instead of a string
  @Input() isStringInput: boolean = true; // Originally set to true, but in case we want HTML content pass this as false from Input

  constructor(
    private elementRef: ElementRef,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector) {
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.showTooltip();
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart($event: TouchEvent): void {
    $event.preventDefault();
    this.showTooltip();
  }

  @HostListener('touchend')
  onTouchEnd(): void {
    this.hideTooltip();
  }

  public showTooltip(): void {
    if (!this.componentRef) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          GenericTooltipComponent);
      this.componentRef = componentFactory.create(this.injector);
      this.applicationRef.attachView(this.componentRef.hostView);
      const domElement =
        (this.componentRef.hostView as EmbeddedViewRef<any>)
          .rootNodes[0] as HTMLElement;
      document.body.appendChild(domElement);
      this.setTooltipProperties();
    }
  }

  public setTooltipProperties() {
    if (this.componentRef) {
      this.componentRef.instance.tooltip = this.isStringInput ? this.tooltip: '';
      this.componentRef.instance.childTemplate = !this.isStringInput ? this.childTemplate: null;
      this.componentRef.instance.position = this.position;

      const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();

      switch (this.position) {
        case 'bottom': {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(bottom);
          break;
        }
        case 'top': {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(top);
          break;
        }
        case 'right': {
          this.componentRef.instance.left = Math.round(right);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        case 'left': {
          this.componentRef.instance.left = Math.round(left);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        default: {
          break;
        }
      }
      this.componentRef.instance.position = this.position + '-' + this.anchorPos;
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    console.log('Mouse Leave!!!!');
    this.hideTooltip();
  }

  public hideTooltip(): void {
    if (this.componentRef !== null) {
      this.applicationRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = <any>null;
    }
  }

  ngOnDestroy(): void {
    this.hideTooltip();
  }

}
