<div class="drop-down-container" appClickOutside (clickOutside)="closeDropdown()" [ngClass]="{'open': isOptionsVisible, 'close': !isOptionsVisible}">
    <div  class="drop-down-title">{{title?.text | customTranslation:translationData }}</div>
    <div  class="drop-down-selection" *ngIf="selected" (click)="toggleOptionsVisibility()">
        <span class="selected-title">{{(selected[key] | customTranslation:translationData) ?(selected[key] | customTranslation:translationData):(selected.title?selected.title:selected | customTranslation:translationData)}}</span>
        <span class="icon icon_down_arrow"></span>
    </div>
    <div class="drop-down-option-container">
        <div role="button" class="drop-down-option" *ngFor="let option of options" (click)="selectOption(option)">
            {{ (option[key] | customTranslation:translationData ) ? (option[key] | customTranslation:translationData):option.title}} 
        </div>
    </div>
</div>
