import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Layout } from '../../interfaces/search-location.interface';

@Injectable({
  providedIn: 'root'
})
export class CircuitListService {
  public searchBoxFilterText = new BehaviorSubject<string | null>(null);
  public circuitOnlyWithLayoutsFilter = new BehaviorSubject<boolean | null>(null);
  public trackLayoutAction = new BehaviorSubject<TrackLayoutAction>(TrackLayoutAction.Edit);

  public _showTrackDetailsSubject$ = new BehaviorSubject<{show: boolean, layout: Layout}>({show: false, layout:<any>null});
  public _selectedTrackActiveSubject$ = new BehaviorSubject<boolean | null>(false);
  private _circuitTrackState: string = '';

  public set showTrackDetails(view: {show: boolean, layout: Layout}) {
    this._showTrackDetailsSubject$.next(view);
  }
  
  public get showTrackDetails(): any {
    return this._showTrackDetailsSubject$.asObservable();
  }

  public set setSelectedTrackStatus(status:boolean) {
    this._selectedTrackActiveSubject$.next(status);
  }
  
  public get selectedTrackStatus(): any {
    return this._selectedTrackActiveSubject$.asObservable();
  }

  public set circuitTrackState(state: string) {
    this._circuitTrackState = state;
  }

  public get circuitTrackState(): string {
    return this._circuitTrackState;
  }
}


export enum TrackLayoutAction {
  New,
  Edit,
  Update
}