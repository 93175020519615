import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { Subscription } from "rxjs";
import { Layout } from "src/app/modules/locations/interfaces/search-location.interface";
import { CircuitListService } from "src/app/modules/locations/services/circuit-list/circuit-list.service";
import { LocationsHttpService } from "src/app/modules/locations/services/locations-http.service";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { LOCATIONS_CONSTANTS } from "../../interfaces/locations-constants";

@Component({
  selector: "app-track-cards-carousel",
  templateUrl: "./track-cards-carousel.component.html",
  styleUrls: ["./track-cards-carousel.component.scss"],
})
export class TrackCardsCarouselComponent implements OnInit {
  @Input() public trackData: Layout[] = [];
  @Output() public selectedTrack = new EventEmitter<object>();
  @Output() public showModal = new EventEmitter<object>();
  @ViewChild("myDiv") divView!: ElementRef;
  @Input() public trackFromSearch: any;
  @ViewChild("slickModal") slickModal!: SlickCarouselComponent;
  slideConfig: any;
  slidestoshow: number = 0;
  isShowPrevArrow: any = false;
  isShowNextArrow: any = true;
  isFrom: string = "";
  selectedTrackGuid: any = "";
  isTrackSelectionCount: any = 0;
  currentDate: any = "";
  translationData: any;
  subscriptions: Subscription[] = [];
  trackSelected: boolean = false;
  showBoostLayer: boolean = false;
  locationConstants: any = LOCATIONS_CONSTANTS;

  constructor(private router: Router, private translationService: TranslationService, 
    private _circuitListService: CircuitListService,
    private _locationService: LocationsHttpService,
  private _toastService: ToastService) {}

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );

    this.subscriptions.push(this._circuitListService.showTrackDetails.subscribe((track: { show: boolean, layout: Layout, source: string }) => {
      if (!track.show) {
        this.isTrackSelectionCount = 0;
        this.trackSelected = false;
        this.selectedTrack.emit({});
      }
    }));

    this.subscriptions.push(this._circuitListService.selectedTrackStatus.subscribe((status:boolean) => {
      this.trackSelected = status;
    }));

    this.currentDate = new Date().toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    this.slideConfig = {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: false,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };
  }

  showNewBadge(slide: any): boolean {
    return slide.updatedDate == this.currentDate && slide.visibility != 'DRAFT'
  }

  ngAfterViewInit() {
    this.divView?.nativeElement.addEventListener("wheel", (ev: any) => {
      ev.preventDefault();
      if (ev.deltaY < 0) {
        this.slickModal.slickPrev();
      } else {
        this.slickModal.slickNext();
      }
    });
  }
  ngOnChanges() {
    if (this.trackFromSearch) {
      this.trackData.forEach((item: any) => {
        if (item.guid === this.trackFromSearch.guid) {
          this.showTrackPolyline(item);
          this.showModalDetails(item);
          this.showNewBadge(item);
        }
      });
    }
  }
  beforeChange(e: any) {
    if (e.nextSlide === 0) {
      this.isShowPrevArrow = false;
      this.isShowNextArrow = true;
    } else if (e.nextSlide === this.trackData.length - 1) {
      this.isShowNextArrow = false;
    } else {
      this.isShowPrevArrow = true;
      this.isShowNextArrow = true;
    }
  }

  setTrackAsSelected(slide: any) {
    if (!this.trackSelected) {
      this.showTrackPolyline(slide);
      this.showModalDetails(slide);
      this._locationService.getTrackDetails(slide.guid)
        .subscribe((response: any) => {
          slide.description = response.description;
        }, error => {
          const errorMsg = error.error["error-message"] ? error.error["error-message"].split(":")[1] : "Error while fetching track details";
          this._toastService.show(ToastType.Error, errorMsg, "4%", "40%");
          setTimeout(() => {
            this._toastService.hide();
          }, LOCATIONS_CONSTANTS.MAX_TIME);
        })
    } else if (slide.guid === this.selectedTrackGuid && this._circuitListService.circuitTrackState !== 'EDIT_TRACK_DETAILS') {
      this.trackSelected = false;
      this.showTrackPolyline(slide);
    }
  }

  showTrackPolyline(trackdetail: any) {
    this.isTrackSelectionCount = this.isTrackSelectionCount + 1;
    if (
      (this.isTrackSelectionCount &&
        this.selectedTrackGuid !== trackdetail.guid) ||
      this.isTrackSelectionCount === 1
    ) {
      this.selectedTrackGuid = trackdetail.guid;
      this.selectedTrack.emit(trackdetail);
    } else {
      this.isTrackSelectionCount = 0;
      this.selectedTrackGuid = "";
      this.selectedTrack.emit({});
    }
  }

  addNewLayout() {
    if (!this.trackSelected) {
      this.showModal.emit({showModal:true})
    }
  }

  viewDetails(trackdetail: any) {
    this.router.navigateByUrl(
      `locations/circuit/${trackdetail.circuitGuid}/layout/${trackdetail.guid}`
    );
  }

  showModalDetails(layout: Layout): void {
    this._circuitListService.showTrackDetails = { show: this.selectedTrackGuid ? true : false, layout: layout};
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
