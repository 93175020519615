import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

interface IObjectKeys {
  [key: string]: string | number;
}

interface Details extends IObjectKeys {
  grant_type: string;
  code: string;
  scope: string;
  redirect_uri: string;
}

@Injectable({
  providedIn: 'root'
})
export class AwsCognitoService {

  constructor(private http: HttpClient) { }

  public getTokenDetailsFromCognito(callbackCode: string): Observable<any> {
    const tokenUrl = ''
    //const tokenUrl = environment.cognitoTokenURL + '?redirect_uri='+environment.redirectURL+'&grant_type=authorization_code&client_id='+environment.cognitoUserPoolClientId+'&code='+callbackCode
    return this.http.post<any>(tokenUrl, {}, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
  }

  public logoutUserFromCognito(): Observable<any> {
    return this.http.get<any>(environment.logout);
  }


  getNewToken(refreshToken: string): Observable<any> {
      
      const url = '';
      return this.http.post(url, {}, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
  }

}
