import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertModalService {
  public onAction = new BehaviorSubject<any>(null);
  public onClose = new BehaviorSubject<any>(null);
  public openModal = new BehaviorSubject<boolean>(false);
  public modalData = new BehaviorSubject<any>(null);
  public spinnerActiveValue = new BehaviorSubject<boolean>(false);
}
