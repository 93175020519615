import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subject,
  map} from "rxjs";
import { AuthAmplifyService } from "src/app/shared/service/auth-amplify.service";
import { environment } from "src/environments/environment";
import { CuratedTourRequest } from "../interfaces/business-tour-create.interface";
import { AmgAnnoDtlsResponse } from "../interfaces/amg-annotation.interface";
import { TranslationService } from "src/app/shared/service/translation.service";
declare const H: any;

@Injectable({
  providedIn: "root",
})
export class LocationsHttpService {
  baseUrl: string;
  access_token: string | null;
  qrCode_api_header: any;
  private platform: any;

  circuitData: any = {};
  trackData: any = {};
  mediaData: any = [];
  public circuitContent = new BehaviorSubject<any>(this.circuitData);
  public shareCircuitData = this.circuitContent.asObservable();
  public trackContent = new BehaviorSubject<any>(this.trackData);
  public shareTrackData = this.trackContent.asObservable();
  public circuitFormContent = new Subject<any>();
  public formContent = new Subject<any>();
  public shareCircuitformData = this.circuitFormContent.asObservable();
  public shareformData = this.formContent.asObservable();
  public mediaContent = new BehaviorSubject<any[]>([]);
  public sharemediaData = this.mediaContent.asObservable();
  public deleteMediaContent = new BehaviorSubject<any[]>([]);
  public deletemediaData = this.deleteMediaContent.asObservable();
  public selectedType = new Subject<any>();
  public shareSelectedType = this.selectedType.asObservable();

  constructor(private httpClient: HttpClient, private authAmplifyService: AuthAmplifyService,
    private _translationService: TranslationService) {
    this.baseUrl = environment.apiEndPoint;
    
    const tokenData = this.authAmplifyService.getCurrentUserToken();
    this.access_token = tokenData?.access_token;
    this.platform = new H.service.Platform({
      apikey: environment.apikey,
    });
  }

  sendFormValue(value: any) {
    this.formContent.next(value);
  }
  sendCircuitFormValue(value: any) {
    this.circuitFormContent.next(value);
  }
  sendSelectedTypeValue(value: any) {
    this.selectedType.next(value);
  }
  sendMediaValue(value: any) {
    this.mediaContent.value.push(value);
  }

  clearMediaValue() {
    this.mediaContent.next([]);;
  }

  sendDeleteMediaValue(value:  any) {
    this.deleteMediaContent.value.push(value);
  }

  getLatestTrackDetails(data: any) {
    this.trackContent.next(data);
  }

  getMapCircuitList(
    emotionTourLimit: any,
    circuitLimit: any,
    offroadLimit:number,
    point: any,
    radius: any
  ): Observable<any> {
    const offroadEnabled = this._translationService.featureFlags.locations.enableoffroadfeature;
    offroadLimit = offroadEnabled ? offroadLimit: 0;
    const url = `${this.baseUrl}/v1/search/mapview?limitForEmotionTours=${emotionTourLimit}&limitForCircuits=${circuitLimit}&limitForOffRoadTours=${offroadLimit}&point=${point}&radius=${radius}&offRoadTours=${offroadEnabled}`;
    return this.httpClient.get<any>(url);
  }

  /**
   * @description This is a new API to get all the data for the map view - circuits, emotion tours and offroad tours
   * @param emotionTourLimit 
   * @param circuitLimit 
   * @param offroadLimit 
   * @param point 
   * @param radius 
   */
  public getAllMapViewData(emotionTourLimit: number, circuitLimit: number, offroadLimit: number, point: any, radius: number): Observable<any> {
    const offroadEnabled = this._translationService.featureFlags.locations.enableoffroadfeature;
    offroadLimit = offroadEnabled ? offroadLimit: 0;
    const url = `${this.baseUrl}/v4/search/mapview?limitForEmotionTours=${emotionTourLimit}&limitForCircuits=${circuitLimit}&limitForOffRoadTours=${offroadLimit}&point=${point}&radius=${radius}&offRoadTours=${offroadEnabled}&level=0`;
    return this.httpClient.get<any>(url);
  }

  getCircuitsList(
    pageSize: any,
    pageNo: any,
    circuitsWithLayout: boolean
  ): Observable<any> {
    const url = `${this.baseUrl}/v1/circuits?size=${pageSize}&pageNumber=${pageNo}&circuitsWithLayout=${circuitsWithLayout}`;
    return this.httpClient.get<any>(url);
  }

  deleteCircuit(guid: any) {
    const url = `${this.baseUrl}/v1/circuit?guid=${guid}`;
    return this.httpClient.delete<any>(url);
  }

  deleteTrack(guid: any) {
    const url = `${this.baseUrl}/v2/track?guid=${guid}`;
    return this.httpClient.delete<any>(url);
  }
  getCircuit(guid: any) {
    const url = `${this.baseUrl}/v1/circuit?guid=${guid}`;
    return this.httpClient.get<any>(url);
  }

  getLatestCircuitDetails(data: any) {
    this.circuitContent.next(data);
  }

  searchCircuits(
    pageSize: any,
    pageNo: any,
    nameMatches: any,
    circuitsWithLayout: boolean
  ) {
    const url = encodeURI(
      `${this.baseUrl}/v1/circuits?size=${pageSize}&pageNumber=${pageNo}&nameMatches=${nameMatches}&circuitsWithLayout=${circuitsWithLayout}`
    );
    return this.httpClient.get<any>(url);
  }

  updateCircuit(data: any): Observable<any> {
    const url = `${this.baseUrl}/v1/circuit/update`;
    return this.httpClient.put(url, data);
  }

 
  /**
   * @description This API is added to update a circuit. It takes name, guid, description
   * It also takes media and copy right info as input and updates a circuit
   * @param data 
   * @param media 
   * @returns 
   */
  public updateCircuitWithMedia(data?: any, media?: any): Observable<any> {
    const url = `${this.baseUrl}/v2/circuit/update`;
    let formData = new FormData();
    formData.append(
      "circuitJson",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    for (let i = 0; i < media?.length; i++) {
      formData.append("media", media[i]);
    }
    this.mediaContent.next([]);
    this.deleteMediaContent.next([]);
    return this.httpClient.put(url, formData);
  }

  UpdateTrackDetails(userId: any, data?: any, media?: any): Observable<any> {
    const url = `${this.baseUrl}/v3/track?userId=${userId}`;
    let formData = new FormData();
    if(data){
    formData.append(
      "params",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    }
    for (let i = 0; i < media?.length; i++) {
      formData.append("media", media[i]);
    }
    console.log('media :>> ', media);
    console.log('formData :>> ', formData);
    this.mediaContent.next([]);

    return this.httpClient.put(url, formData);
  }

  saveAnnotationWithMedia(data?: any, media?: any): Observable<any> {
    const url = `${this.baseUrl}/v2/annotation/create`;
    let formData = new FormData();
    formData.append(
      "annotation",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    for (let i = 0; i < media?.length; i++) {
      formData.append("media", media[i]);
    }
    
    this.mediaContent.next([]);
    this.deleteMediaContent.next([]);

    return this.httpClient.post(url, formData);
  }

  editAnnotationWithMedia(data?: any, media?: any): Observable<any> {
    const url = `${this.baseUrl}/v1/annotation/update`;
    let formData = new FormData();
    formData.append(
      "annotationJson",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    for (let i = 0; i < media?.length; i++) {
      formData.append("media", media[i]);
    }
    this.mediaContent.next([]);
    this.deleteMediaContent.next([]);

    return this.httpClient.put(url, formData);
  }



  getCategories(data: any): Observable<any> {
    const url = `${this.baseUrl}/v1/tour/categories`;
    return this.httpClient.post(url, data);
  }

  createEmotionTourPreview(data: any): Observable<any> {
    const url = `${this.baseUrl}/v1/tour/journey`;
    return this.httpClient.post(url, data);
  }

  /**
   * @description this is a curated tour API called when we click on Category and when Curated toggle is tourned on
   * This is an alternative /journey API that does not enhance the tour
   * @param data 
   * @returns 
   */
  public createCuratedBusinessTour(curatedTourRequest: CuratedTourRequest): Observable<any> {
    const url = `${this.baseUrl}/v1/tour/curated`;
    return this.httpClient.post(url, curatedTourRequest);
  }

  /**
   * @description This API is added to create a circuit. It takes name, guid, description
   * and media files as input and creates a circuit. Also as aprt of BETRACKPRO-18495
   * we are adding a copyright info with media files
   * @param data 
   * @param media 
   * @returns Observable
   */
  public createCircuit(data?: any, media?: any): Observable<any> {
    const url = `${this.baseUrl}/v2/circuit/create`;
    let formData = new FormData();
    formData.append(
      "circuitJson",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    for (let i = 0; i < media?.length; i++) {
      formData.append("media", media[i]);
    }
    this.mediaContent.next([]);
    this.deleteMediaContent.next([]);

   
    return this.httpClient.put(url, formData);
  }

  uploadTrack(data: any): Observable<any> {
    const url = `${this.baseUrl}/v1/track/upload`;
    let formData = new FormData();
    formData.append("file", data);
    return this.httpClient.post(url, formData);
  }

  getTrackDetails(guid: any): Observable<any> {
    const url = `${this.baseUrl}/v1/track?guid=${guid}`;
    return this.httpClient.get(url);
  }

  downloadTrackDetails(guid: any, trackVersion?: string) {
    let api_headers:any = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    api_headers.responseType = "arraybuffer"
    let url = `${this.baseUrl}/v1/track/download?guid=${guid}`;
    if (trackVersion) {
      url = url.concat(`&trackVersion=${trackVersion}`);
    }
    return this.httpClient.get(url, api_headers);
  }

  getBlobImage(url: string): Observable<any> {
    

    let header: any = {
      responseType: "blob",
    };

    return this.httpClient.get(url, header);
  }

  getAddressFromCoordinates(lat: any, long: any) {
    const service = this.platform.getSearchService();
    let address: any;
    return service.reverseGeocode({
      at: lat + "," + long,
    });
  }
  
  /**
   * @param pageSize 
   * @param pageNo 
   * @param nameMatches 
   * @param isCircuits 
   * @param isEmotionTour 
   * @param isOffroad 
   * @returns list of circuits, emotion tour and offraod tours
   */
  public gobalSearch(pageSize: number, pageNo: number, nameMatches: string, isCircuits: boolean, isEmotionTour: boolean, isOffroad: boolean): Observable<any> {
    const locationFeatureFlags = this._translationService.featureFlags.locations;
    isOffroad = isOffroad && locationFeatureFlags.enableoffroadfeature;
    isEmotionTour = isEmotionTour && locationFeatureFlags.enableEmotionTourFeature;
    isCircuits = isCircuits && locationFeatureFlags.enableCircuitFeature;
    const visibility = "DRAFT,PERMANENT,TEMPORARY";
    const url = encodeURI(
      `${this.baseUrl}/v1/search?pageSize=${pageSize}&pageNumber=${pageNo}&nameMatches=${nameMatches}&circuits=${isCircuits}&emotionTours=${isEmotionTour}&offRoadTours=${isOffroad}`
    );
    return this.httpClient.get<any>(url);
  }

  dealerAnnotationSearch(
    pageSize: any,
    pageNo: any,
    nameMatches: any,
    query:any
  
  ) {
    const url = encodeURI(
      `${this.baseUrl}/v1/search/annotation?size=${pageSize}&pageNumber=${pageNo}&nameMatches=${nameMatches}&query=${query}`
    );
    return this.httpClient.get<any>(url);
  }

  getEmotionTourDetails(guid: any): Observable<any> {
    const url = `${this.baseUrl}/v1/track/encoded?guid=${guid}&precision=5`;

    return this.httpClient.get(url);
  }

  /**
   * @description Called when we save AMG annotation details from Add Details screen
   * @param annotationId 
   * @returns Observable
   */
  public getAnnotationDetails(annotationId: string): Observable<AmgAnnoDtlsResponse> {
    let api_headers:any = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const url = `${this.baseUrl}/v1/poi/annotation/details?annotationId=${annotationId}`;
    return this.httpClient.get(url, api_headers).pipe(map((res) => {
      return res as unknown as AmgAnnoDtlsResponse
    }));
  }

  deleteAnnotation(annotationId: any): Observable<any> {
    const url = `${this.baseUrl}/v1/poi/annotation?annotationId=${annotationId}`;
    return this.httpClient.delete<any>(url);
  }

  getEmotionTourWithAnnotations(guid: any, caller: string): Observable<any> {
    let api_header = {
      headers: {
        caller: caller,
        "Content-Type": "application/json",
      },
    };
    const url = `${this.baseUrl}/v1/annotation/geojson?guid=${guid}`;

    return this.httpClient.get(url, api_header);
  }
  getGeoSearchAddress(centerCoordinates:any,limit:number,lang:string,term:string): Observable<any> {
    const url = `https://autosuggest.search.hereapi.com/v1/autosuggest?at=${centerCoordinates}&limit=${limit}&lang=${lang}&q=${term}&apiKey=${environment.apikey}`;
    return this.httpClient.get(url);
  } 

  createGeofenceValidation(encodedPolyline:any):Observable<any> {


    const url = `${this.baseUrl}/v1/circuit/geofence/validate`;

  return this.httpClient.post(url,encodedPolyline);
  }

  fetchYourTracks(user:any, centerCoordinates:any) {
    const url = `${this.baseUrl}/v2/track/sharedtracks?point=${centerCoordinates}&radius=8000&visibility=CLOSED,PERMANENT,TEMPORARY`;
    let api_header = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return this.httpClient.get(url, api_header);
  }

  public sendTourToCar(tourGuid: string, vin: string): Observable<any> {
    const url = `${this.baseUrl}/v1/fleet/add?guid=${tourGuid}`;
    let api_header = {
      headers: {
        "vin": vin,
      },
    };
    return this.httpClient.post(url, null, api_header);
  }

  /**
   * @description Called to upload tour guide json data
   * @param data 
   * @param guid 
   * @param locale 
   * @returns Observable
   */
  public uploadTourSpeechJson(data: any, guid: string, locale: string): Observable<any> {
    const url = `${this.baseUrl}/v1/tourguide/upload`;
    let formData = new FormData();
    formData.append("file", data);
    formData.append('guid', guid);
    formData.append('locale', locale);
    return this.httpClient.post(url, formData);
  }
}
