import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService } from 'src/app/shared/service/translation.service';

@Component({
  selector: 'app-common-alert-modal',
  templateUrl: './common-alert-modal.component.html',
  styleUrls: ['./common-alert-modal.component.scss']
})
export class CommonAlertModalComponent {

  @Input('title') title = ''
  @Input('message') message = ''
  @Input('cancelBtnTitle') cancelBtnTitle = ''
  @Input('actionBtnTitle') actionBtnTitle = ''
  @Input('isModalOpen')isModalOpen = false;
  @Input('actionType')actionType = '';
  @Input('placeholderObject') placeholderObject : any;
  @Input('hideSecondaryBtn') hideSecondaryBtn = '';
  @Output()
  isModalResponseClicked = new EventEmitter<any>();
  translationData: any;

  constructor(private _translationService: TranslationService) {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  onCancel() {
    this.isModalResponseClicked.emit({action:false})
  }

  onAction() {
    this.isModalResponseClicked.emit({action:true, actionType:this.actionType})
  }


}
