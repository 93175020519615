import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe extends DatePipe implements PipeTransform {

  override transform(value: any, args?: any): any {
    value = value.split(' ')[0];
    return super.transform(value, 'dd.MM.YYYY');
  }

}
