import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeoPathComponent } from './geo-path.component';



@NgModule({
  declarations: [GeoPathComponent],
  imports: [
    CommonModule
  ],
  exports: [GeoPathComponent]
})
export class GeoPathModule { }
