import { APP_CONSTANTS } from 'src/app/shared/constants/constants';
import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../../service/language.service';
import { TranslationService } from '../../service/translation.service';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss']
})
export class LanguageSelectionComponent implements OnInit {

  @Input() showLanguageSelectionModal: boolean = false;
  translationData: any;
  allLanguages: any[] = [];
  BUTTON_NAMES = APP_CONSTANTS.COMMON_BUTTON_LABELS;

  constructor(private _languageService: LanguageService, private _translationService: TranslationService) { }

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe((data:any) => {
      this.translationData = data;
    });
    this.allLanguages = this._translationService.supportedLanguagesForDisplay;
  }

  public closeLangSelection(): void {
    this._languageService.showLanguageSelectionModal.next(false);
  }

  switchLanguage(language:string) {
    localStorage.setItem('language', language);
    const fullURL = window.location.href.split('/');
    fullURL[3] = language;
    window.location.href = fullURL.join('/');
  }

  ngOnDestroy(): void {
    this._languageService.showLanguageSelectionModal.next(false);
  }

}
