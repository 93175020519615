
<div class="toast-style" *ngIf="showToast">
  <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition">
  </app-toast>
  </div>

<div class="admin-console-wrapper">
  <div class="web-portal-container">
    <div class="screen-content-header">
      <div class="title-bar">
        <div class="header-left">
          <h3>{{'BP_PM_FLEET' | customTranslation:translationData}} 
            <ng-container *ngIf="fleetCount>0">({{fleetCount}})</ng-container>
          </h3>
          <span>{{'BP_PM_ADD_EDIT_FLEET' | customTranslation:translationData }}</span>
        </div>
      </div>
    </div>
    <div>
        <app-fleet-card (editCarDetailsEmitter)="editCarDetailsEmitter($event)" (componentEmitter)="componentEmitter($event)" (carCountEmitter)="carCount($event)"></app-fleet-card>
    </div>
  </div>
</div>

<ng-container *ngIf="addCarClicked">
<app-add-car (cancelClicked)="cancelClicked($event)" ></app-add-car>
</ng-container>
