<ng-container *ngIf="isAliasSelected ; else aliasNotSelected">
<div class="profile-section">
  <div [ngClass]="isprofileswitchselected?'disp-none':'name-email'">
    <ul>
      <li>
        <p>{{userName}}</p>
        <p class="email" *ngIf="userEmail">{{userEmail}}</p>
      </li>
    </ul>
  </div>

  <div>
    <app-group-section ></app-group-section>
  </div>
  <div [ngClass]="isprofileswitchselected?'disp-none':'logout'" role="button" (click)="logout()">{{'BP_PM_LOGOUT'| customTranslation:translationData }}</div>
</div>
</ng-container>

<ng-template #aliasNotSelected>
  <div class="logout-section" role="button" (click)="logout()">{{'BP_PM_LOGOUT'| customTranslation:translationData }}</div>
</ng-template>
