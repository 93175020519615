<div id="carousel-region">
  <div class="track-card-carousel" #myDiv>
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
      [class.scroll-slide]="isShowPrevArrow" (beforeChange)="beforeChange($event)">
      <div *ngIf="trackData && trackData.length > 0" class="empty-card empty-card-content " ngxSlickItem>
        <div class="empty-card-text">
          {{locationConstants.HELPER_MSGS.UPLOAD_GPX_CIRCUIT_MSG | customTranslation:translationData}}
        </div>
        <button class="upload-button" (click)="addNewLayout()" [ngClass]="trackSelected?'slide-data-selected':''">
          <span class="icon icon_add"></span>
          <span>{{locationConstants.BUTTON_NAMES.NEW_LAYOUT | customTranslation: translationData }}</span>
        </button>
      </div>
      <div class="carousel-card row" ngxSlickItem *ngFor="let slide of trackData" class="slide"
        [class.active-track]="slide.guid === selectedTrackGuid && this.isTrackSelectionCount"
        (click)="setTrackAsSelected(slide)">
        <div class="slide-data" [ngClass]="((slide.guid !== selectedTrackGuid) && trackSelected)?'slide-data-selected':''">
          <div class="liner-gradient"> </div>
          <div class="user-avtr prof-image">
            <span class="user-img"></span>
            <img src="" alt="..." />
          </div>
          <div *ngIf="slide?.boostDetails?.length" class="icon-race-track" appTooltip position="top" [anchorPos]="'center'"
            [childTemplate]="childTemplate" [isStringInput]="false"></div>
          <div *ngIf="slide?.boostDetails?.length && showBoostLayer" class="">
          </div>
          <ng-template #childTemplate>
            <ul class="list-style">
              <li *ngFor="let boostitem of slide?.boostDetails">
                <span><b>{{boostitem.modelType}}</b> - {{boostitem.updatedTime | formatDate}}</span>
              </li>
            </ul>
          </ng-template>
          <div class="track-polyline-main">
            <div class="map-column">
              <button class="new-badge" *ngIf="showNewBadge(slide)">
                <span>{{locationConstants.HELPER_INPUT_LABELS.NEW | customTranslation:translationData}}</span>
              </button>
              <button class="draft-badge" *ngIf="  slide.updatedDate == currentDate && slide.visibility == 'DRAFT' ">
                <span>{{locationConstants.HELPER_INPUT_LABELS.DRAFT | customTranslation:translationData}}</span>
              </button>
              <div class="track-polyline">
                <app-geo-path width="100%" height="100%" [coordinates]="slide.encodedGpsPoints"></app-geo-path>
              </div>
            </div>
          </div>
          <div class="card-info-txt">
            <div class="carousel-card-header">
              <div class="carousel-card-title slide-title">
                {{ slide.name }}
              </div>
            </div>
            <div class="carousel-card-content">
              <div class="area-sector-column">
                <span>{{ slide.trackLength | number:'1.2-2' }} km </span><span class="dot"></span>
                <span>{{ slide.noOfSectors }}
                  <ng-container *ngIf="slide.noOfSectors > 1; else singleSector">
                    {{('BP_LM_SECTORS' | customTranslation: translationData) | lowercase}}
                  </ng-container>
                  <ng-template #singleSector>
                    {{('BP_LM_SECTOR_LABEL' | customTranslation: translationData) | lowercase }}
                  </ng-template>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
    <button *ngIf="trackData && trackData.length >= 3 && isShowNextArrow" (click)="slickModal.slickNext()"
      class="right-arrow">
      <img src="assets/locations/right-nav.png" alt=" " />
    </button>
    <button *ngIf="isShowPrevArrow" (click)="slickModal.slickPrev()" class="left-arrow">
      <img src="assets/locations/left-nav.png" alt=" " />
    </button>
    <div *ngIf="trackData?.length == 0" class="no-layouts-section">
      <div class="empty-card empty-card-content">
        <div class="empty-card-text">
          {{locationConstants.HELPER_MSGS.NO_LAYOUTS_AVAILABLE_MSG | customTranslation:translationData}}
        </div>
        <button class="upload-button" (click)="addNewLayout()"><span
            class="add-icon-style add-icon-class"></span>{{locationConstants.BUTTON_NAMES.NEW_LAYOUT |
          customTranslation:translationData}}</button>
      </div>
    </div>
  </div>
</div>