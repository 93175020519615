import { Component, EventEmitter, Input,  OnInit, Output, ViewChild } from '@angular/core';
import { TranslationService } from '../../service/translation.service';
import { CircuitsServicesService } from 'src/app/modules/locations/services/circuits-services.service';
import { LOCATIONS_CONSTANTS } from 'src/app/modules/locations/interfaces/locations-constants';
import { ToastService, ToastType } from '../../service/toast.service';
import { ETOUR_CREATION_STEPS } from 'src/app/modules/locations/interfaces/business-tour-create.interface';
import { APP_CONSTANTS } from '../../constants/constants';

@Component({
  selector: 'app-common-media-upload',
  templateUrl: './common-media-upload.component.html',
  styleUrls: ['./common-media-upload.component.scss']
})
export class CommonMediaUploadComponent implements OnInit {
 
  translationData: any;
  selectedMedia: File[] = [];
  selectedFile: File[]=[];

  mediaFiles: any[] = [];
  mediaFilesOverlay: any[] = [];
  showOverlayFlag: boolean = false;

  @Output() mediaData= new EventEmitter<any>();
  @Output() mediaFilesUpdated = new EventEmitter<number>();
 isMediaDisable: boolean= true;
  @Input() currentStep: string="";
  @Input() tourCoverImg: string = "";
  selectedLocation: any = null;
  markFieldsAsRequired: boolean =false;
  @Input() markMediaFieldsAsRequired: boolean=false;
  @ViewChild('myFileInput', {static: false}) myFileInput: any;
  commonLables = APP_CONSTANTS.COMMON_LABELS;

  constructor(
    private translationService: TranslationService,
    private mediaService: CircuitsServicesService,
    private toastService: ToastService) { }
    
 
  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.checkForMediaFromModal();
    this.checkForImage();
    this.checkForAnnotationImage()
  }

  /**
   * @description Called when media is uploaded, checks validation and displays image
   * @param event 
   * @returns void
   */
  public uploadMediaOnClk(event: any): void {
    // Converting this to array to get access to array methods, else we can't remove files from FileList
    if((this.showAddMedia()) && event.target.files.length > 1) {
      this.showToastErrorMsg(ToastType.Error,LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.MAX_ALLOWED_MEDIA_MSG);
      return;
    };
    this.selectedMedia = [ ...event.target.files];
    if (this.currentStep === ETOUR_CREATION_STEPS.DESTINATION_ADDED ||this.currentStep === ETOUR_CREATION_STEPS.WAY_POINT_ADDED || this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH) {
      this.mediaService.sendCoverMediaValue(this.selectedMedia);
    }
    if((this.showAddMedia()) && this.selectedMedia.length>=1) this.isMediaDisable=false;
    else this.isMediaDisable=true;
    if (this.selectedMedia && (this.currentStep !== ETOUR_CREATION_STEPS.WAY_POINT_ADDED && this.currentStep !== ETOUR_CREATION_STEPS.DESTINATION_ADDED && this.currentStep !== ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH)) {     
      if (this.checkFilesValidation(this.selectedMedia.length)) {
        this.mediaData.emit([...event.target.files]);       
        if (!this.showOverlayFlag) {
          if (!this.mediaFiles[0] || this.mediaFiles.length > 0 && this.mediaFiles.length < 3) {
            this.showThumbnailsDataSet(this.selectedMedia);
          } else {
            this.showThumbnailsCounterDataSet(this.selectedMedia, 0);
          }
        } else {
          this.showThumbnailsCounterDataSet(this.selectedMedia, 0);
        }      
      }      
    }    
  }

  /**
   * @description If only 3 images are there show the 3 images as 3 boxes. If number of images exceed 3, 
   * Show 3 boxes with first 2 images and 3rd box will have an image with overlay and number of files remaining
   * @param selectedMedia 
   */
  public showThumbnailsDataSet(selectedMedia: File[]): void {
    let thumbnailsLeftToShow = (3 - this.mediaFiles.length);
    for (let i = 0; i <= thumbnailsLeftToShow; i++) {
      if (i > thumbnailsLeftToShow - 1) {
        this.showThumbnailsCounterDataSet(selectedMedia, thumbnailsLeftToShow);
      } else {
        this.fileReader(this.mediaFiles, selectedMedia[i]);
        this.showOverlayFlag = false;
      }
    }
  }

  /**
   * @description To push images into the 3rd box and cover it with an overlay.
   * Additionally display the number of images
   * @param selectedMedia 
   * @param startingIndex starts with 3 always b'cause 3rd box has the overlay
   */
  public showThumbnailsCounterDataSet(selectedMedia: File[], startingIndex: number): void {
    for (let i = startingIndex; i < selectedMedia.length; i++) {
      this.fileReader(this.mediaFilesOverlay, selectedMedia[i]);
      this.showOverlayFlag = true;
    }
  }

  public fileReader(mediaBase64Files: any[], originalMediaFiles: File): void {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      mediaBase64Files.push(e.target.result);
      let mediaArray = {
        highlightName: this.selectedLocation,
        media: this.mediaFiles
      }
      this.mediaService.setHighlightMediaValue(mediaArray);
      if (this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH) {
        this.mediaService.sendMediaValueForCover(this.selectedMedia);
      }
      console.log(this.mediaFiles)

    }
    reader.readAsDataURL(originalMediaFiles);
    this.myFileInput.nativeElement = '';
  }

  checkForImage() {
    if(this.currentStep === ETOUR_CREATION_STEPS.DESTINATION_ADDED ||this.currentStep === ETOUR_CREATION_STEPS.WAY_POINT_ADDED || this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH
      ){
        this.mediaService.shareCovermediaData$.subscribe((data) => {
          if (data) {
            this.isMediaDisable = false;
            let media = data
            const reader: any = new FileReader();
            reader.readAsDataURL(media[0]);
            reader.onload = (_event: any) => {
              if(this.mediaFiles.length < 1){
                this.mediaFiles.push(reader.result); 
              }
            }
          }
        });
      }
  }

  /**
   * @description Loads images while editing a AMG annotation
   */
  checkForAnnotationImage() {
    if (this.currentStep === ETOUR_CREATION_STEPS.ADD_WAY_POINT_DETAILS || this.currentStep === ETOUR_CREATION_STEPS.EDIT_START || this.currentStep === ETOUR_CREATION_STEPS.EDIT_DESTINATION) {
      this.mediaFiles = [];
      this.mediaService.sharemediaData$.subscribe((data: any) => {
        if (data.url) {
          this.mediaFiles.push(data['url']);
        }
        if (data.length > 0) {
          this.mediaFiles = [];
          this.isMediaDisable = true;
          data.forEach((element: any) => {
            if (element.url) {
              this.mediaFiles.push(element['url']);
            } else {
              const reader: any = new FileReader();
              reader.readAsDataURL(element);
              reader.onload = (_event: any) => {
                this.mediaFiles.push(reader.result)
              }

            }
          });
        }
      });
    }
  }

  public checkFilesValidation(numberOfFiles: number): boolean {
    let isValidFiles = true;
    for (let i = 0; i < numberOfFiles; i++) {
      let fileExtension = this.selectedMedia[i].name.split(".");
      let extension = fileExtension[fileExtension.length - 1];
      if (!LOCATIONS_CONSTANTS.ALLOWED_EXTENTIONS_IMG_UPLOAD.includes(extension)) {
        this.showToastErrorMsg(ToastType.Error,LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.ALLOWED_MEDIA_EXTENSION_MSG);
        isValidFiles = false;
      } else if (this.selectedMedia[i].size > APP_CONSTANTS.MAX_IMAGE_SIZE) {
        this.showToastErrorMsg(ToastType.Error,APP_CONSTANTS.COMMON_ERROR_MSGS.MAX_ALLOWED_IMG_SIZE);
        isValidFiles = false;
      }
    }
    return isValidFiles;
  }


  public removeImage(imageIndex: number): void {
    this.mediaFiles?.splice(imageIndex, 1);
    this.selectedMedia.splice(imageIndex, 1);
    if((this.showAddMedia()) && this.selectedMedia.length===1) {
      this.isMediaDisable=false; 
    }
    else {
      this.isMediaDisable=true;
      this.mediaService.clearMediaValue();
      } 
    if (this.mediaFiles?.length < 3 && this.mediaFilesOverlay?.length) {
      this.mediaFiles.push(this.mediaFilesOverlay[0]);
      this.selectedMedia.push(this.mediaFilesOverlay[0]);
      this.mediaFilesOverlay.shift();
      if (!this.mediaFilesOverlay?.length) {
        this.showOverlayFlag = false;
      }
    }
    this.mediaFilesUpdated.emit(imageIndex); 
    if (this.currentStep === ETOUR_CREATION_STEPS.WAY_POINT_ADDED) {
      this.mediaService.clearCoverMediaValue();
    }   
  }
  
  public showToastErrorMsg(type: any,  message: string): void {
    this.toastService.show(type, message, "8%", "40%");
    setTimeout(() => {
      this.toastService.hide();
    }, APP_CONSTANTS.MIN_TIME_TOASTER);
  }

  checkForMediaFromModal(){
    if(this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH){
      this.isMediaDisable = false;
      this.mediaFiles.push(this.tourCoverImg);
    }
  }
  
  public showAddMedia(): boolean {
    return ((this.currentStep === ETOUR_CREATION_STEPS.DESTINATION_ADDED ||this.currentStep === ETOUR_CREATION_STEPS.WAY_POINT_ADDED || this.currentStep === ETOUR_CREATION_STEPS.EDIT_AFTER_PUBLISH
  ));

  }

}
