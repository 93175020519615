
<div class="toast-style" *ngIf="showToast">
  <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition">
  </app-toast>
  </div>
<ng-template #showModalContent>
 <div class="form-section">
  <form action="" [formGroup]="carDetailsForm">
    <div class="vin-section">
        <label for="">{{'BP_PM_VIN' | customTranslation:translationData}}*</label>
        <div>
        <input type="text" placeholder="WDBXXXX123456F" formControlName="vin" (keyup)="searchCarDetails(inputValue.value);inputValue.value=inputValue.value.toUpperCase()" #inputValue maxlength="17" required
        [ngClass]="{'input-text required': (inValidText || carDetailsForm.invalid)&&carDetailsForm.value.vin.length, 'not-empty': carDetailsForm.value.vin.length}"
        >
        <span *ngIf="loaderVisible" class="loader"></span>
        </div>
        <small *ngIf="(inValidText || carDetailsForm.invalid)&&carDetailsForm.value.vin.length">Invalid VIN</small>
    </div>

    <!-- for car data -->
    <ng-container *ngIf="carDetailsAvailable && validVin && formValid">
      <div class="car-details">
      <div class="image-section">
        <div class="car-img">
          <img [src]="carDetails.carImage?carDetails.carImage:car" alt="">
        </div>
      </div>
      <div class="car-model">
        <span>{{'BP_PM_CAR_MODEL' | customTranslation:translationData}}</span>
        <p>{{(carDetails.carName?carDetails.carName:'BP_PM_MODEL_DETAILS_UNAVALIABLE') | customTranslation : translationData}}</p>
        <div [ngClass]="carDetailsAvailable?'license-section':'license-section opacity'">
          <label for="">{{'BP_PM_LICENSE' | customTranslation:translationData}}</label>
          <input (keyup)="searchLicense(license.value);license.value=license.value.toUpperCase()" #license  type="text" placeholder="00-00 0000" [value]="carDetails.licensePlate?carDetails.licensePlate:''" maxlength="16"  required>
      </div>
      </div>
      </div>
    </ng-container>
    
  </form>
 </div>

 <div class="button-region right">
   <button (click)="cancelPopup()" class="delta">
    <span>{{buttonNames.CANCEL | customTranslation:translationData}}</span>
   </button>
   <button (click)="addCarToFleet()" class="beta" [class.disabled]="!carDetailsAvailable">
    <span>{{buttonNames.CONFIRM | customTranslation:translationData}}</span>
   </button>

 </div>
</ng-template>



<app-common-modal
  [isHeaderVisible]="isHeaderVisible"
  [showModalContent]="showModalContent"
  [isCloseModal]="isCloseModal"
  [component]="component"
  [type]="type"
  *ngIf="showModal"
></app-common-modal>
