import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCardCarouselComponent } from './image-card-carousel.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';





@NgModule({
  declarations: [ImageCardCarouselComponent],
  imports: [
    CommonModule,
    SlickCarouselModule,
    
  ],
  exports: [ImageCardCarouselComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImageCardCarouselModule { }
