import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/service/language.service';
import { TranslationService } from 'src/app/shared/service/translation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  svgIconFb = './assets/images/social-icon-fb.svg';
  svgIconTwitter = './assets/images/social-icon-twitter.svg';
  svgIconInstagram = './assets/images/social-icon-instagram.svg';
  svgIconYoutube = './assets/images/social-icon-youtube.svg';
  svgIconGplus = './assets/images/social-icon-gplus.svg';
  svgIconArrow = './assets/images/arrow.svg';

  window: any;
  selectedLanguage:  string;
  isLocation:boolean=false;
  currentYear: number = new Date().getFullYear();
  allSupportedLangCodes: any[];
  public isLanguageSelctionEnabled: boolean = false;
  public showLanguageSelectionModal: boolean = false;
  public userLanguageForDisplay: string = 'English';
  // @Output()
  // setLanguage: EventEmitter<string> = new EventEmitter();

  constructor(
    //private windowRef: WindowRef, 
    public route:Router,
    private cdr:ChangeDetectorRef,
    private _translationService: TranslationService,
    private _langauageService: LanguageService
    ) {
    this.isLanguageSelctionEnabled = this._translationService.featureFlags.locations.enableLanguageSelection;
    let selectedLanguage = localStorage.getItem('language');
    this.allSupportedLangCodes = this._translationService.getSupportedLanguageByCode();
    this.userLanguageForDisplay = this.getUserSelectedLanguage();
    this.selectedLanguage = selectedLanguage?selectedLanguage:this.allSupportedLangCodes.find(lang => lang.includes('en')) ?? 'en-GB';
  }
  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.route.events.subscribe((events)=>{

      if(events instanceof NavigationEnd){
        this.isLocation=events.url.includes("locations")?true:false
      }
    })
  }
  switchLanguage(language:string) {
    localStorage.setItem('language', language);
    const fullURL = window.location.href.split('/');
    fullURL[3] = language;
    window.location.href = fullURL.join('/');
  }

  public getUserSelectedLanguage(): string {
    const selectedLanguage = localStorage.getItem('language');
    const supportedLangList = this._translationService.supportedLanguagesForDisplay;
    const langName = supportedLangList.find((lang: any) => lang.localeCode === selectedLanguage);
    return langName && langName.localeDescription ? langName.localeDescription.split('(')[0] : 'English';
  }

  public openLanguageSelection() {
    this._langauageService.showLanguageSelectionModal.next(true);
  }

  scrollToTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
      });
  }


}
