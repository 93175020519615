import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-full-screen-map-modal',
  templateUrl: './full-screen-map-modal.component.html',
  styleUrls: ['./full-screen-map-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FullScreenMapModalComponent {
  public apiKey = environment.apikey;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
