import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { environment } from 'src/environments/environment';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { EALL_TRACKS } from '../../interfaces/search-location.interface';

@Component({
  selector: 'app-create-dropdown',
  templateUrl: './create-dropdown.component.html',
  styleUrls: ['./create-dropdown.component.scss']
})
export class CreateDropdownComponent implements OnInit, OnChanges {
  @Input() dropDownStatus = false;
  @Output() public selectedOption = new EventEmitter<string>();
  isDropdownOpen: any;
  enableFeature = environment.enableFeature;
  translationData: any;
  // Feature flags
  public enableCircuitFeature: boolean = false;
  public enableEmotionTourFeature: boolean = false;
  public enableoffroadfeature: boolean = false;
  locationConstants = LOCATIONS_CONSTANTS;


  constructor(private _translationService: TranslationService) {
  }

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
        this.enableCircuitFeature = this._translationService.featureFlags.locations.enableCircuitFeature;
        this.enableEmotionTourFeature = this._translationService.featureFlags.locations.enableEmotionTourFeature;
        this.enableoffroadfeature = this._translationService.featureFlags.locations.enableoffroadfeature;
      }
    )
    this.setDropDownValue();
  }

  ngOnChanges(){
    this.setDropDownValue();
  }

  setDropDownValue() {
    this.isDropdownOpen = this.dropDownStatus;
  }

  openDropdown(){
    this.isDropdownOpen = !this.isDropdownOpen;
    this.selectedOption.emit();
  }

  public sendSelectedCreateOption(value: string): void {
    const isOffroadTourEnabled = this._translationService.featureFlags.locations.enableOffRoadCreation;
    if (value === EALL_TRACKS.OFF_ROAD && !isOffroadTourEnabled) {
      return;
    }
    this.selectedOption.emit(value);
    this.isDropdownOpen = false;
  }

}
