import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenText'
})
export class ShortenTextPipe implements PipeTransform {
  /*
    This pipe truncates a string.
    Use it like {{ String expression | truncate:50 }}
    This truncates the string to 50 letters.
  */
  transform(value: string | any, limit: number): string {
    if (value) {
      return value.length < limit
      ? value
      : value.slice(0, limit) + '...';
    } else {
      return ''
    }

  }

}
