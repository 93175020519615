import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';


@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  isLoading: Subject<boolean> = this.loadingSpinnerService.isLoading;
  constructor(
    private loadingSpinnerService: LoadingSpinnerService
  ) { }

}