import { Component, OnDestroy, OnInit } from "@angular/core";
import { interval } from "rxjs";
import { DataServiceServiceProfile } from "src/app/modules/alias/services/profile-data-service.service";
import { AuthAmplifyService } from "src/app/shared/service/auth-amplify.service";
import { SharingDataService } from "src/app/shared/service/sharing-data.service";

@Component({
  selector: "app-refresh-session",
  template: ``,
  styles: [],
})
export class RefreshSessionComponent implements OnInit, OnDestroy {
  subscribtionVar: any;
  groupName:any;

  constructor(private authAmplifyService: AuthAmplifyService,
    private dataService:DataServiceServiceProfile,
    private sharinDataService:SharingDataService
    ) {}

  ngOnInit(): void {
    const source = interval(1000);
    this.subscribtionVar = source.subscribe((_val) => {
      const tokenData = this.authAmplifyService.getCurrentUserToken();
      if (tokenData) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (tokenData.expires_in == currentTime) {
          this.refreshSession();
        }
      }
    });
  }

  async removeIndexedDb(){
    const dbs = await window.indexedDB.databases()
    dbs.forEach((db:any) => { window.indexedDB.deleteDatabase(db.name) })
  }

  refreshSession() {
    const profile = localStorage.getItem('aliasGroupName')
    this.groupName = {
      groupName: profile,
    };
    this.authAmplifyService.currentSession().then(
      (_res) => {
      let accessToken = _res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      let jwt_expires_in = accessToken.getExpiration();
      let refreshToken = _res.getRefreshToken();
      let jwtRefreshToken = refreshToken.getToken();
      let idToken = _res.getIdToken();
      let jwtIdToken = idToken.getJwtToken();
      let fullData = {
        id_token: jwtIdToken,
        access_token: jwt,
        refresh_token: jwtRefreshToken,
        expires_in: jwt_expires_in,
        token_type: "Bearer",
      };
      this.authAmplifyService.setUserLoginStatus(true);
      this.authAmplifyService.setCurrentUserToken(fullData);
      this.triggerProfileApi()
    });
  }
  triggerProfileApi(){
    const profile = localStorage.getItem('aliasGroupName')
    this.groupName = {
      groupName: profile,
    };
    const payload = this.groupName;   
    this.dataService.switchProfile(payload).subscribe(()=>{
      this.sharinDataService.setProfileChanged(true)
    })
  }

  ngOnDestroy(): void {
    this.subscribtionVar.unsubscribe();
  }
}
