import { Component, HostListener, OnInit, TemplateRef } from "@angular/core";
import { TranslationService } from "./shared/service/translation.service";
import { CommonModalService } from 'src/app/modules/events/services/common-modal.service';
import { Subscription } from "rxjs";
import { LanguageService } from "./shared/service/language.service";
import { AuthAmplifyService } from "./shared/service/auth-amplify.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "admin-console";
  translatedArray = [];
  language: string = 'en-GB';
  openModalFlag: boolean = false;
  public subscriptions: Subscription[] = [];
  public enableLeftPanelView: boolean = false;
  public refSessionComAvailable: boolean = false;
  routeToView: boolean = false;
  public showLanguageSelectionModal: boolean = false;
  public languageSelection!: TemplateRef<any> | null;
  public allSupportedLangCodes: any[];


  isLoggedIn:boolean=true
  isProfileAvailable:any=localStorage.getItem('profile')
  @HostListener("window:popstate", ["$event"])
  async onBrowserBackBtnClose(_event: Event) {
    const dbs = await window.indexedDB.databases();
    dbs.forEach((db: any) => {
      window.indexedDB.deleteDatabase(db.name);
    });
  }

  constructor(private translationService: TranslationService,
              private  commonModalService: CommonModalService, private languageService: LanguageService,
              private authAmplifyService:AuthAmplifyService,
              private route:Router
              ) {
    const fullURL = window.location.href.split('/');
    this.allSupportedLangCodes = this.translationService.getSupportedLanguageByCode();
    if (this.allSupportedLangCodes.includes(fullURL[3])) {
      localStorage.setItem('language', fullURL[3]);
    }    
    const selectedLang = this.languageService.getLanguage();
    if (selectedLang) {
      this.language = selectedLang;
    } else {
      this.language = this.allSupportedLangCodes.find(lang => lang.includes('en')) ?? 'en-GB';
      localStorage.setItem('language', this.language);
    }
  }


  ngOnInit() {
    this.languageService.showLanguageSelectionModal$.subscribe((data: boolean) => {
      this.showLanguageSelectionModal = data;
    });
    this.route.events.subscribe((events)=>{
      if(events instanceof NavigationEnd){
        this.isLoggedIn=this.authAmplifyService.getUserLoginStatus()
      }
    })

    this.getTranslations(this.language);
    localStorage.setItem('isDealer', 'true');

    this.route.events.subscribe((events)=>{

      if(events instanceof NavigationEnd){
        if(localStorage.getItem('aliasGroupName') && !localStorage.getItem('profileName')){
          this.route.navigate(['profile'])
        }
      }
    })
    this.subscriptions.push(this.commonModalService.participantQrModalStream$.subscribe((data: boolean) => {
      if(data !== null || data !== undefined) {
        this.openModalFlag = data;
      }
    })
    );
    
  }

  public getTranslations(languageId: string): void {
    if (languageId === 'en') {
      languageId = 'en-GB';
    }
    // We are not calling the API to get the feature flags as routing is dependent on the 
    // feature flags and guards run first. We need flags for smooth routing
    const featureFlags = this.translationService.featureFlags;
    this.enableLeftPanelView = featureFlags?.locations.enableLeftPanelView;
    this.refSessionComAvailable = featureFlags?.profile.isRefSessionComAvailable;
    if (languageId === 'de-DE' && !featureFlags?.locations.enableLanguageSelection) {
      languageId = 'de';
    }
    this.translationService.phraseApiCallForTranslations(languageId)
      .subscribe((allDeTranslationBasedOnlocle) => {
        for (const element of allDeTranslationBasedOnlocle) {
          this.translatedArray = this.translatedArray.concat(element);
        }
        this.translationService.setTranslationData(this.translatedArray);
        this.translationService.sendSelectedLanguage(this.language);
        this.translationService.sendtranslationValue(this.translatedArray);
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
