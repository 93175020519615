<div *ngIf="!isLocation">
  <div class="footer-container" id="footer-container">
    <div class="web-portal-container footer">
      
      <div class="lt-row ft-row">
        <div class="lang-swith-footer">
          <ng-container *ngIf="!isLanguageSelctionEnabled; else languageSelectionBlock">
          <ul class="language-menu">
              <li
              [ngClass]="{ active: selectedLanguage === 'de' }"
              (click)="switchLanguage('de')"
            >
              <a href="javascript:void(0)" hreflang="de-DE" title="Deutsch">
                <span class="desk-only">de</span>
                <span class="mob-only">Deutsch</span>
              </a>
            </li>
            <li
              [ngClass]="{ active: selectedLanguage === 'en' }"
              (click)="switchLanguage('en')"
            >
              <a href="javascript:void(0)" hreflang="en-US" title="English">
                <span class="desk-only">en</span>
                <span class="mob-only">English</span>
              </a>
            </li>
          </ul>
        </ng-container>
          <ng-template #languageSelectionBlock>
            <ul class="language-menu">
            <li class="lang-text active">
              <a (click)="openLanguageSelection()" id="language-text-link">
                <span class="desk-only desk-selected-lang">{{userLanguageForDisplay | titlecase}}</span>
                <span class="mob-only mob-selected-lang">{{userLanguageForDisplay | titlecase}}</span>
              </a>
            </li>
            </ul>
          </ng-template>
        </div>
        <div (click)="scrollToTop()">
          <div id="top-arrow" class="amg-footer-to-top-link">
            <span class="to-top-label">up</span>
            <span class="icon icon_down_arrow icon_down"></span>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</div>

<div *ngIf="isLocation">
  <div class="footer-container-location" id="footer-container-location">
    <div>
    <p>
      © {{currentYear}} Mercedes-AMG GmbH. Alle Rechte vorbehalten.
      <!-- &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;
      Provider&nbsp;
      |&nbsp;
      Privacy &nbsp;
      |&nbsp;
      Legal&nbsp;
      |&nbsp; Cookies -->
    </p>
  </div>
  </div>
</div>
