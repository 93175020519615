<ng-template [ngIf]="templateBtn">
    <div id="modal-container" *ngIf="!isCloseModal">
        <div class="modal open-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog-box" role="document">
                <div class="modal-content" [ngClass]="type">
                    <!--Popup Header -->
                    <div *ngIf="isHeaderVisible" class="common-modal-header">
                        <div class="form-row sp-btw">
                        <div class="form-item">
                            <h3>{{headerTitle | customTranslation: translationData}}</h3>
                        </div>   
                        </div>
                    </div>
                    <!-- Popup content -->
                    <div [ngClass]="component=='aliasComponent'?'w-auto modal-body-container':'modal-body-container'">
                        <ng-container [ngTemplateOutlet]="showModalContent"></ng-container>
                    </div>
                    <!-- Interactive Buttons -->
                    <div class="popup-footer" *ngIf="isFooterVisible">
                        <div class="button-region">
                        
                            <button *ngIf="isCloseButtonVisible" class="delta" (click)="close()">
                                <span>{{(closeButtonTitle | customTranslation: translationData) ? (closeButtonTitle | customTranslation: translationData): closeButtonTitle}}</span>
                            </button>

                            
                            <button [ngClass]="{'done-button-disable': doneButtonDisabled == 'INVALID' }" *ngIf="isSaveButtonVisible" class="beta" (click)="save()">
                                <span>{{(saveButtonTitle | customTranslation: translationData) ? (saveButtonTitle | customTranslation: translationData): saveButtonTitle}}</span>
                            </button>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template [ngIf]="!templateBtn">
    <div id="modal-container" *ngIf="!isCloseModal">
        <div class="modal open-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog-box" role="document">
                <div class="modal-content" [ngClass]="type">
                    <div [ngClass]="component=='partcipantsQrDownload'?'common-modal-header common-modal-header-qr':'common-modal-header'">
                        <div class="form-row sp-btw row-padding">
                        <div class="form-item">
                            <h3>{{headerTitle | customTranslation : translationData}}</h3>
                        </div>   
                        <div class="form-group">
                        
                        </div>
                        </div>
                    </div>
                    <div [ngClass]="component=='partcipantsQrDownload'?'partcipant-qr modal-body-container':'modal-body-container'">
                        <ng-container [ngTemplateOutlet]="showModalContent"></ng-container>
                    </div>
                    <div class="form-group footer-section" *ngIf="isFooterVisible">
                        <div class="button-region">

                            <button *ngIf="isCloseBtnVisible" class="delta" (click)="close()">
                                <span>{{closeButtonTitle}}</span>
                            </button>
                           
                            <button class="delta" (click)="close()">
                               <span>{{'BP_CM_CLOSE_BTN_LBL' | customTranslation: translationData}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</ng-template>

