<div class="emotion-modal-style section" (click)="hideDropDown()">
    <div class="navigate-section-style">
        <app-navigate-back-section [navigateToUrl]="navigateUrl" [navigateMessage]="navigateMessage" (isShowMapView)="showSearch($event)"></app-navigate-back-section>
    </div>
    <div class="emotion-detail-main">
      <div class="name-section">
        <span class="user-name-label">
          {{'BP_CM_CREATED_BY' | customTranslation:translationData}} 
          <span class="user-name-text">{{owner}}  </span> 
          <span class='user-name-label'> on</span> 
          <span class="user-name-text"> {{emotionTourDetails.properties.createdDate}}</span>
        </span>
      </div>
      <div class="tour-title-reg">
          <span class="title">{{title}}</span>
          <span class="menu-container">
              <span class="icon icon_kebab" data-toggle="dropdown"  (click)="dropdownFixPosition($event)"
              #menuLink [ngClass]="this.isMenuDropdownVisible && isshowDropDown? 'active-menu':'menu'"></span>
          
            <div [style.display]="isMenuDropdownVisible && isshowDropDown ? 'block' : 'none'"
              class="dropdown kebab-dropdown dropdown-sm"  #dropdown >
              <div class="dropdown-menu dropdown-menu-right">
              <button (click)="deleteEmotionTour()" [ngClass]="isDeleteSelected ? 'selected-menu':''" class="delete-circuit-button cursor-pointer" ><span>{{buttonNames.DELETE | customTranslation: translationData }} </span> </button>
              </div>
            </div>
          </span>
      </div>
      <div class="description" *ngIf="emotionTourDetails.properties.description">{{emotionTourDetails.properties.description}}</div>
      <div class="flex-container">
          <div class="tour-dist-info">
            <span class="label-text">{{'BP_LM_LENGTH' | customTranslation:translationData}}</span>
            <span class="label-text-data">{{trackLength|number: '1.2-2'}} km</span> 
          </div>
          <div *ngIf="emotionTourDetails.properties.highlights"><span class="label-text" >{{'BP_LM_HIGHLIGHTS' | customTranslation: translationData }}</span><span class="label-text-data">10</span></div>
          <div  *ngIf="emotionTourDetails.properties.tripphotos"><span class="label-text">TRIP PHOTOS</span><span class="label-text-data">34</span></div>  
      </div>
      <div class="bottom-section">
        <div class="button-region">
          <button class="beta" (click)="goToDetailsUrl()"><span>{{'BP_LM_SUMMARY' | customTranslation:translationData}}</span></button>
          <button class="hallow" (click)="downloadTrackDetails()"><span class="icon icon_download"></span><span>{{buttonNames.GPX_FILE_UP | customTranslation: translationData }}</span></button>
        </div>
      </div>
    </div>
<app-common-alert-modal [title]="alertTitle" [message]="alertMessage" [isModalOpen] = isModalOpen [actionType]="actionType"
    (isModalResponseClicked)="getCancelModalResponse($event)" [cancelBtnTitle]="alertCancelBtnTitle"
      [actionBtnTitle]="alertActionBtnTitle"></app-common-alert-modal>
</div>