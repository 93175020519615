import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthenticateComponent } from "./components/authenticate/authenticate.component";
import { AuthorizeComponent } from "./components/authorize/authorize.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { AuthGuard } from "./_helper/auth.gard";
import { LocationsGuardGuard } from "./_helper/locations-guard.guard";
import { RouterLanguageGaurdGuard } from "./_helper/router-language-gaurd.guard";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "auth",
    component: AuthenticateComponent,
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: "login",
    component: AuthorizeComponent,
  },
  {
    path: 'locations',    
    children: [
      {
        path: '**',
        children: [],
        canActivate: [RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [RouterLanguageGaurdGuard]
  },
  {
    path: 'events',
    children: [
      {
        path: '**',
        children: [],
        canActivate: [RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [RouterLanguageGaurdGuard]
  },
  {
    path: 'profile',
    children: [
      {
        path: '**',
        children: [],
        canActivate: [RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [RouterLanguageGaurdGuard]
  },   
  {
    path: ':languageId/profile',
    loadChildren: ()=> import("./modules/alias/profile.module").then((m) => m.AliasModule),
    canLoad:[AuthGuard]
  },     
  {
    path: ':languageId/locations',
    loadChildren: () => import('./modules/locations/locations.module').then(m => m.LocationsModule),
    canLoad: [LocationsGuardGuard],
    canActivate: [AuthGuard]
  },
  {
    path: ':languageId/events',
    loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
