<div *ngIf="showToast" class="toast-style">
    <app-toast [message]="message" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition"></app-toast>
</div>
<ng-template #audioUpload>
    <div class="form-row">
        <div class="form-group">
            <div class="form-item">
                <app-dropdown [key]="'title'" [options]="listOfLanguages" (selectedOption)="getUserSelectedLang($event)" [defaultSelection]="{ icon: 'icon_globe',title: 'BP_LM_SELECT_LANG', value: '' }"></app-dropdown>
            </div>
        </div>
    </div>
    <div class="file-upload-region-box">
        <input type="file" id="audio-upload" accept="application/json" (change)="onFileSelected($event)" [disabled]="!userLanguage" hidden />
        <label for="audio-upload" [ngClass]="!userLanguage ? 'disabled-state': ''">{{buttonNames.BROWSE | customTranslation: translationData}}</label>
        <div *ngIf="!fileUploadSuccessful && uploadedFileDetails">
            <span>{{uploadedFileDetails.name}}</span>
            <div class="status" *ngIf="showProgess">
                <span>{{'BP_LM_UPLOADING' | customTranslation: translationData}}</span>
                <span class="icon icon_spinner loading-ani"></span>
            </div>
        </div> 
             
    </div>
    <div class="file-list-main" *ngIf="fileUploadSuccessful">
        <h6><span>{{'BP_LM_ADDED_TOUR_LBL' | customTranslation: translationData}}</span></h6>
        <div class="file-list-box">
            <div class="file-info-box">
                <div class="file-type-box">
                    <span class="icon icon_audio_upload"></span>
                </div>
                <div class="file-info-txt" *ngIf="uploadedFileDetails">
                    <span>{{uploadedFileDetails.name}}</span>
                    <div class="file-sec-info-bar">
                        <small>{{uploadedFileDetails.size + 'kb'}}</small>
                        <small>{{uploadedFileDetails.dateUploaded | formatDate}}</small>
                        <small>{{ uploadedFileDetails.userLanguage | customTranslation: translationData}}</small>
                    </div>
                </div>
            </div>
            <div class="file-act-reg">
                <button class="trans"><span class="icon icon_delete"></span></button>
            </div>
        </div>
        <span class=""></span>
        
    </div>  
</ng-template>
<app-common-modal [isCloseModal]="false" [closeButtonTitle]="closeBtnTitle" 
[isHeaderVisible]="true" [isFooterVisible]="true" [showModalContent]="audioUpload" 
*ngIf="showModal" [type]="type" (closeModalEmitter)="closeModal()"></app-common-modal>