import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { SlickCarouselComponent } from "ngx-slick-carousel";

@Component({
  selector: "app-image-card-carousel",
  templateUrl: "./image-card-carousel.component.html",
  styleUrls: ["./image-card-carousel.component.scss"],
})
export class ImageCardCarouselComponent implements OnInit {
  @Input() public trackData: any;
  @Input() public selectedAnnotationIdobj: any;
  @Output() public selectedAnnotation = new EventEmitter<object>();
  @ViewChild("myDiv") divView!: ElementRef;
  @ViewChild("slickModal") slickModal!: SlickCarouselComponent;
  currentDate: string = "";
  isShowPrevArrow: any = false;
  isShowNextArrow: any = true;
  selectedAnnotationId: any = "";
  isTrackSelectionCount: any = 0;
  filteredAnnotations: any = [];
  ownerName: any = "";
  imageSrc: string = "";
  slideConfig: any;
  slidestoshow: number = 0;
  isFrom: string = "";
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.currentDate = new Date().toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    this.trackData = this.trackData.features.filter((item: any) => {
      if (
        item.geometry.type !== "Polygon" &&
        item.properties.landmark_type.toLowerCase() !== "route" &&
        item.properties.landmark_type.toLowerCase()  !== "origin" &&
        item.properties.landmark_type.toLowerCase()  !== "destination"
      ) {
        item.properties.route_position = item.properties.route_position / 1000;

        return item;
      }
    });
    if (this.trackData?.length > 8) {
      this.slidestoshow = 1;
    } else {
      this.slidestoshow = 7;
    }
    this.trackData = this.trackData.sort(
      (a: any, b: any) =>
        a.properties.route_position - b.properties.route_position
    );
    this.filteredAnnotations = this.trackData;
    this.slideConfig = {
      slidesToShow: this.slidestoshow,
      slidesToScroll: 1,
      infinite: false,
      variableWidth: true,
      swipeToSlide: true,
      adaptiveHeight: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.selectedAnnotationId =
      changes.selectedAnnotationIdobj?.currentValue?.selectedAnnotation?.id || changes.selectedAnnotationIdobj?.currentValue?.id;
    if (this.selectedAnnotationId) {
      this.isTrackSelectionCount = this.isTrackSelectionCount + 1;
    } else {
      this.isTrackSelectionCount = 0;
    }

    if(!changes.selectedAnnotationIdobj?.currentValue?.selectedAnnotation?.isFrom){
    this.filteredAnnotations.forEach((value: any, index: any) => {
      if (
        changes.selectedAnnotationIdobj?.currentValue?.selectedAnnotation
          ?.id === value.id
      ) {
        this.slickModal?.slickGoTo(index);
      }
    });
  }
  }
  ngAfterViewInit() {
    this.divView?.nativeElement.addEventListener("wheel", (ev: any) => {
      ev.preventDefault();
      if (ev.deltaY < 0) {
        this.slickModal.slickPrev();
      } else {
        this.slickModal.slickNext();
      }
    });
  }

  beforeChange(e: any) {
    if (e.nextSlide == 0) {
      this.isShowPrevArrow = false;
      this.isShowNextArrow = true;
    } else if (e.nextSlide == this.trackData.length - 7) {
      this.isShowNextArrow = false;
    } else {
      this.isShowPrevArrow = true;
      this.isShowNextArrow = true;
    }
  }

  /**
   * @description called on each click of the carousel slide, this emits the selected annotation to parent component
   * @param trackdetail 
   * @param isFrom 
   */
  showTrackPolyline(trackdetail: any,isFrom: string) {
    this.isFrom=isFrom;
    this.isTrackSelectionCount = this.isTrackSelectionCount + 1;
    if (
      (this.isTrackSelectionCount &&
        this.selectedAnnotationId !== trackdetail?.id) ||
      this.isTrackSelectionCount == 1
    ) {
      trackdetail.isFrom=isFrom;
      this.selectedAnnotationId = trackdetail.id;
      this.selectedAnnotation.emit(trackdetail);
    } else {
      this.isTrackSelectionCount = 0;
      this.selectedAnnotation.emit({isFrom:isFrom});
    }
  }
}
