
  <ng-template
    class="alias-main"
    #showModalContent
  >
    <div class="alias-list-section">
      <div class="alias">
        <div class="user-name"><p *ngIf="aliasList">{{'BP_PM_LP_HI'| customTranslation:translationData }} {{username}}</p> <p class="mt" *ngIf="!aliasList">
            <ngx-skeleton-loader class="height" animation="pulse"  [theme]="{width: '10em', height: '0.5em','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader>
        </p></div>
        <h4>{{'BP_PM_LP_SB' | customTranslation:translationData}}</h4>
        <p>{{ 'BP_PM_LP_PD' | customTranslation:translationData}}</p>
      </div>

      <div *ngIf="userData" class="list">
        <ul>
          <li
            *ngFor="let item of aliasList; let i = index"
            role="button"
            (click)="aliasSelected(i)"
          >
            <div>
              <span class="dp" alt="">
                <img [src]="item.profileImageUrl" alt="">
              </span>
              <p>{{ item.profileName }}</p>
            </div>
            <span class="right-arrow"></span>
          </li>
        </ul>
      </div>
      <div *ngIf="!userData" class="list">
         <ul>
          <li *ngFor="let _ of [].constructor(2)"  class="loader-list">
            <div>
              <span class="dp" alt=""></span>
              <p class="mt_1"><ngx-skeleton-loader class="height" animation="pulse"  [theme]="{width: '8.75em', height: '0.5em','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader></p>
              <span class="right-arrow"></span>
            </div>
          </li>
         </ul>
      </div>

      <div *ngIf="userData">
        <ng-container *ngIf="!aliasList">
          <app-access-denied></app-access-denied>
        </ng-container>
        
     </div>
    </div>
  </ng-template>


<app-common-modal
  [isHeaderVisible]="isHeaderVisible"
  [isFooterVisible]="false"
  [component]="component"
  [showModalContent]="showModalContent"
  [isCloseModal]="isCloseModal"
  [type]="type"
  *ngIf="showModal"
></app-common-modal>
