import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "../../components/page-not-found/page-not-found.component";
import { LocationsAppComponent } from "./locations-app.component";
import { CircuitInfoComponent } from "./pages/circuit-info/circuit-info.component";
import { EditCircuitDetailsComponent } from "./pages/edit-circuit-details/edit-circuit-details.component";
import { EditGeofenceComponent } from "./pages/edit-geofence/edit-geofence.component";
import { EventsOverviewComponent } from "./pages/events-overview/events-overview.component";
import { LayoutDetailsComponent } from "./pages/layout-details/layout-details.component";
import { MapViewCircuitListComponent } from "./pages/map-view-circuit-list/map-view-circuit-list.component";
import { CreateDealerFlowComponent } from "./pages/create-dealer-flow/create-dealer-flow.component";
import { AuthGuard } from "src/app/_helper/auth.gard";

export const routes: Routes = [
  {
    component: LocationsAppComponent,
    path: "",
    children: [
          { path: "circuit/create", 
          component: MapViewCircuitListComponent, 
          canActivate: [AuthGuard] 
        }, 
          {
            component: LayoutDetailsComponent,
            path: "circuit/:guid/layout/:trackGuid",
            canActivate: [AuthGuard]
          },
          {
            component: LayoutDetailsComponent,
            path: "circuit/:guid/layout/:trackGuid/edit",
            canActivate: [AuthGuard]
          },
          {
            component: EditCircuitDetailsComponent,
            path: "circuit/:guid/edit",
            canActivate: [AuthGuard]
          },
          {
            component: EditCircuitDetailsComponent,
            path: "circuit/add",
            canActivate: [AuthGuard]
          },
          {
            component:EditGeofenceComponent,
            path:"circuit/edit/geofence",
            canActivate: [AuthGuard]
          },
          {
            component:EditGeofenceComponent,
            path:"circuit/:guid/edit/geofence",
            canActivate: [AuthGuard]
          },
          {
            component: CircuitInfoComponent,
            path:  "circuit/:guid",
            canActivate: [AuthGuard]
          },   
          {
            component: CreateDealerFlowComponent,
            path: ":trackType/create",
            canActivate: [AuthGuard]
          },
          {
            component: CreateDealerFlowComponent,
            path: ":trackType/:guid",
            canActivate: [AuthGuard]
          },    
          {
            component: CreateDealerFlowComponent,
            path: ":trackType/:guid/edit",
            canActivate: [AuthGuard]
          },   
      { component: EventsOverviewComponent, path: "events-overview" },
      { component: PageNotFoundComponent, path: "not-found" },
      { path: "", component: MapViewCircuitListComponent, pathMatch: "full" },
      { path: '**', component: PageNotFoundComponent }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LocationRoutingModule {}