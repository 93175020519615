import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { CircuitListService, TrackLayoutAction } from '../../../services/circuit-list/circuit-list.service';
import { LocationsHttpService } from '../../../services/locations-http.service';
import { AlertModalService } from '../../../services/modal/alert-modal.service';

@Component({
  selector: 'app-layout-details-header',
  templateUrl: './layout-details-header.component.html',
  styleUrls: ['./layout-details-header.component.scss'],
})
export class LayoutDetailsHeaderComponent implements OnInit {
  @Input() title = '';
  @Input() primaryButtonTitle = '';
  @Input() primaryButtonColor = '';
  @Input() secondaryButtonTitle = '';
  @Input() secondaryButtonColor = '';
  @Output('primaryButtonClick') primaryButtonClickEvent = new EventEmitter<void>();
  @Output('secondaryButtonClick') secondaryButtonClickEvent = new EventEmitter<void>();
  @Input() isDisablePrimaryButton = false;
  public enableSpinner = false;
  translationData: any;

  constructor(private locationsHttpService: LocationsHttpService,
    private circuitListService: CircuitListService,
    private alertModalService: AlertModalService,
    private translationService: TranslationService
    ) { }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );

    this.alertModalService.spinnerActiveValue.subscribe((response: any) => {
      this.enableSpinner = response;
    });
  }
  primaryButtonClick() {
    switch (this.circuitListService.trackLayoutAction.value) {
      case TrackLayoutAction.New: {
        this.enableSpinner = true;
        this.primaryButtonClickEvent.emit()
        this.locationsHttpService.sendFormValue('publish');
        break;
      }
      case TrackLayoutAction.Update: {
        this.primaryButtonClickEvent.emit()
        this.locationsHttpService.sendFormValue('');   
        break;
      }
      case TrackLayoutAction.Edit: {
        this.primaryButtonClickEvent.emit()
        break;
      }
    }
  }

  secondaryButtonClick() {
    switch (this.circuitListService.trackLayoutAction.value) {
      case TrackLayoutAction.New: {
        this.enableSpinner = true;
        this.secondaryButtonClickEvent.emit()
        this.locationsHttpService.sendFormValue('draft');
        break;
      }
      case TrackLayoutAction.Update: {
        this.secondaryButtonClickEvent.emit()
        break;
      }
      case TrackLayoutAction.Edit: {
        this.secondaryButtonClickEvent.emit()
        break;
      }
    }
  }

}


