import { Injectable } from "@angular/core";
import Dexie from "dexie";
import { BehaviorSubject } from "rxjs";
import { LocationsHttpService } from "../../services/locations-http.service";

@Injectable({
  providedIn: "root",
})
export class DbService extends Dexie {
  savedBlob: any;
  public mediaSrc = new BehaviorSubject<any>("");

  constructor(private locationService: LocationsHttpService) {
    super("DexieDB"); //database name 'DexieDB'

    this.openVersion();
  }
  openVersion() {
    this.version(1).stores({
      myMediaStore: "mediaId", //'myMediaStore' table, 'image' primary key
      myTourStore:"tourId"
    });
  }
  openDb() {
    this.open() //opening the database
      .then((_data) => console.log("DB Opened"))
      .catch((err) => console.log(err.message));
  }
  async getMedia(value: any, imageType: any) {
    let imgSrc: any=2;

    await this.table("myMediaStore")
      .get({ mediaId: value.id })
      .then((data) => {
        this.savedBlob = data;
        if ((this.savedBlob?.thumbBlob && this.savedBlob?.originalBlob) || (this.savedBlob?.thumbBlob &&
          imageType.toLowerCase() === "thumbnail" )|| (this.savedBlob?.originalBlob &&
            imageType.toLowerCase() === "fullscreen")) {
          imgSrc = this.savedBlob;
          return imgSrc;
        } else {
          imgSrc = 1;
        }
      })
      .catch((err) => console.log(err.message));
    return imgSrc;
  }

  async setMedia(id: any, media: any, blob: any, imageType: any) {
    let savedBlob: any = "";
    await this.table("myMediaStore")
      .get({ mediaId: id })
      .then((data) => {
        savedBlob = data;
      });
    if (!savedBlob && imageType.toLowerCase() === "thumbnail") {
      let mediaObj = {
        thumbnailUrl: media,
        mediaId: id,
        thumbBlob: blob,
        imageType: imageType,
      };
      return this.addMedia(mediaObj);
    } else if (!savedBlob && imageType.toLowerCase() === "fullscreen") {
      let mediaObj = {
        OriginalUrl: media,
        mediaId: id,
        originalBlob: blob,
        imageType: imageType,
      };
      return this.addMedia(mediaObj);
    } else if (
      savedBlob?.mediaId === id &&
      (!savedBlob?.thumbBlob || !savedBlob?.originalBlob)
    ) {
      if (!savedBlob?.thumbBlob) {
        let mediaObj = { thumbnailUrl: media, thumbBlob: blob };
        return this.updateMedia(id, mediaObj);
      } else {
        let mediaObj = { OriginalUrl: media, originalBlob: blob };
        return this.updateMedia(id, mediaObj);
      }
    }
  }
  addMedia(obj: any) {
    this.table("myMediaStore")
      .add(obj)
      .then((data) => console.log(data))
      .catch((err) => console.log(err.message));
  }


  async addTour(obj: any) {
    await this.table("myTourStore")
      .add(obj)
      .then((data) => console.log('tour data',data))
      .catch((err) => console.log(err.message));
  }

  async getTour(id:any) {
    let tourData
    await this.table("myTourStore")
      .get({ tourId: id })
      .then((data) => {
        console.log(data);
        tourData = data;
      })
      .catch((err) => console.log(err.message));
      return tourData
  }

  async updateTour(key: any, obj: any) {
    await this.table("myTourStore")
      .update(key, obj)
      .then((data) => console.log(data))
      .catch((err) => console.log(err.message));
  }

  async updateMedia(key: any, obj: any) {
    await this.table("myMediaStore")
      .update(key, obj)
      .then((data) => console.log(data))
      .catch((err) => console.log(err.message));
  }
  deleteMedia(key: any) {
    this.table("myMediaStore")
      .delete(key)
      .then((data) => console.log(data,"delete"))
      .catch((err) => console.log(err.message));
  }

  async resetTourDatabase(tableName:any) {
    await this.table(tableName)
      .clear();
  }
}
