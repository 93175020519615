import { Component, Input } from "@angular/core";
import * as d3 from "d3";
import { RandomNumberGeneratorService } from "src/app/shared/service/random-number-generator.service";

@Component({
selector: "app-geo-path",
  templateUrl: "./geo-path.component.html",
  styleUrls: ["./geo-path.component.scss"],
})
export class GeoPathComponent {
  @Input() width: any;
  @Input() height: any;
  // Format: [[-6, 36], [33, 30], [43, 11], [51, 12], [29, -33], [18, -35], [7, 5], [-17, 14], [-6, 36]]
  @Input() coordinates: any;
  divId = "id" + this.createUUID();
constructor(private randomService: RandomNumberGeneratorService){}
 

  ngAfterViewInit() {
    this.plotTrack();
  }
 
  createUUID() {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
       (c) => {
        let r = (dt + this.randomService.randomNumber()) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }
  plotTrack() {
    if (this.coordinates?.length <= 2) {
      if (this.coordinates?.length == 1) {
        this.coordinates.push([this.coordinates[0][0], this.coordinates[0][1]]);
      }
      if (
        JSON.stringify(this.coordinates[0]) ==
        JSON.stringify(this.coordinates[1])
      ) {
        this.coordinates[1][0] = this.coordinates[1][0] + 0.1;
      }
    }
    const geojson: any = {
      type: "FeatureCollection",
      features: [
        {
          geometry: {
            type: "LineString",
            coordinates: this.coordinates,
          },
          type: "Feature",
        },
      ],
    };

    let g = d3
      .select(`#${this.divId} g.map`)
      .selectAll("path")
      .data(geojson.features);

    // fitSize makes the output take up all the space inside the svg
    let projection = d3
      .geoIdentity()
      .reflectY(true)
      .fitSize(
        [parseInt(this.width, 10)-4, parseInt(this.height, 10)-10],
        geojson
      );
    const path: any = d3.geoPath().projection(projection);
    // So that it still works if there are more features than just one
    g.enter()
      .append("path")
      .attr("d", path)
      .style("fill", "none")
      .style("stroke-width", "4")
      .style("stroke", "white");
  }
}
