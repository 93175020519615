import { Pipe, PipeTransform } from "@angular/core";
import { translationsArray } from "./translations-fallback";

@Pipe({
  name: "customTranslation",
})
export class CustomTranslationPipe implements PipeTransform {
  defaultEnTranslation = translationsArray;
  dynamicValues = {};


  transform(value: string, ...args: any[]): string {
    let placeholders: any = {};
    if (args?.length > 1) {
      placeholders = args[0];
    }
    let translatedArray = args;
    let translatedValue: string = '';
    if (translatedArray) {
      if (translatedArray?.length > 1) {
        translatedArray = translatedArray[1];
        translatedArray.forEach((data: any)=>{
            if (data.key.name === value) {
              translatedValue = data.content;
              if (args?.length > 1) {
                translatedValue = this.replacePlaceholders(translatedValue, placeholders);
              }
              return data;
            }
        })
      } else {
        translatedArray.forEach((item: any)=>{
          item?.filter((data: any) => {
            if (data.key.name === value) {
              translatedValue = data.content;
              return data;
            }
          });
        })
      }     
    }
    if (translatedValue.trim() === '') {
      this.defaultEnTranslation.forEach((data: any) => {
        if (data.key === value) {
          translatedValue = data.value;
          if (args?.length > 1) {
            translatedValue = this.replacePlaceholders(translatedValue, placeholders);
          }
        }
      })
    }
    return translatedValue;
  }

  /**
   * @description This method is used to replace the placeholders in the translated value 
   * with values that will be sent by the developer
   * @param value 
   * @param placeholders 
   * @returns translatedValue
   */
  private replacePlaceholders(value: string, placeholders: any): string {
    let placeholderRegex: any = /\{placeholder[^{}]*\}/;
    let translatedValue = value;
    for (const key in placeholders) {
      if (placeholders.hasOwnProperty(key)) {
        if (Object.keys(placeholders).length > 1) {
        placeholderRegex = new RegExp(key, "g");
        }
        translatedValue = translatedValue.replace(placeholderRegex, placeholders[key]);
      }
    }
    return translatedValue;
  }

}
