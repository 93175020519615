import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonModalService } from 'src/app/modules/events/services/common-modal.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { Subscription } from 'rxjs';
import { DataServiceServiceProfile } from '../../services/profile-data-service.service';
import { SharingDataService } from 'src/app/shared/service/sharing-data.service';
import { ToastService} from 'src/app/shared/service/toast.service';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
import { APP_CONSTANTS } from 'src/app/shared/constants/constants';
@Component({
  selector: 'app-add-car',
  templateUrl: './add-car.component.html',
  styleUrls: ['./add-car.component.scss']
})
export class AddCarComponent implements OnInit, OnDestroy {

  @ViewChild("showModalContent") showModalContent: TemplateRef<any> | undefined;
  @Output() public cancelClicked = new EventEmitter<any>();
  @Input() editCarDetails:any
  headerTitle : string = ""
  component: string = "addCarComponent";
  type:string='medium-popup'
  car="assets/images/car.png"
  vinToUppercase:string=''
  searchVinValue:string=''
  searchLicenseValue:string=''
  carDetails:any
  validVin:boolean=false
  errorOccured:boolean=false
  translationData:any
  subscriptions: Subscription[] = [];
  carDetailsAvailable:boolean=false;
  showToast: boolean = false;
  toastMessage:string='';
  isSuccess: any;
  toastType: any = 0;
  position: any;
  leftPosition: any;
  showToastDiv:boolean=false
  timer:any
  
  public buttonNames: any = APP_CONSTANTS.COMMON_BUTTON_LABELS;

  constructor(
    private commonModalService:CommonModalService,
    private formBulder:UntypedFormBuilder,
    private translationService:TranslationService,
    private dataService:DataServiceServiceProfile,
    private sharingData:SharingDataService,
    private toastService:ToastService,
    private loadingSpinnerService:LoadingSpinnerService,
  ) { 
    
  }
 
  carDetailsForm=this.formBulder.group({
    vin:['',Validators.compose(
      [
        Validators.pattern("^[a-zA-Z0-9]+"),
        Validators.required
      ]
    )]
  })
  isHeaderVisible:boolean=true
  showModal:boolean=true
  isCloseModal:boolean=false
  formValid:boolean=false
  loaderVisible:boolean=false
  inValidText:boolean=false
  carAlreadyavailable:boolean=false
  addCarReq:any
  isItInEditCarState:boolean=false
  ngOnInit(): void {
    this.subscriptions.push(this.toastService.toastDetails.subscribe((status:any) => {
      this.showToast = false;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    }));
    this.subscriptions.push(this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
      }));

    this.commonModalService._modalHeaderTitle.next('BP_PM_NEW_CAR')
    this.commonModalService._modalHeaderIsCloseBtnVisible.next(false)
    this.commonModalService._modalHeaderIsSaveBtnVisible.next(false)

    // Edit car section
    if(this.editCarDetails){
      this.isItInEditCarState=true
    this.carDetailsForm.patchValue({
      vin: this.editCarDetails.vin
    });
    this.carDetailsForm.get('vin')?.disable()
    this.validVin=true
    this.loaderVisible=false
    this.inValidText=false
    this.carDetailsAvailable=true
     this.vinToUppercase=this.editCarDetails.vin
    this.formValid=true
    this.carDetails={
      carName:this.editCarDetails.carName,
      carImage:this.editCarDetails.carImageUrl,
      carColor:this.editCarDetails.carColor,
      licensePlate:this.editCarDetails.licensePlate
    }  
    this.commonModalService._modalHeaderTitle.next('Edit car details')
  }
  }
  searchCarDetails(value:any){
    this.searchVinValue=value
    this.vinToUppercase=value.toUpperCase()
    if(value.length == 17 && this.carDetailsForm.valid){
      this.formValid=true
      this.loaderVisible=true
      clearTimeout(this.timer)
      this.timer=
      setTimeout(()=>{
        this.checkVin()
      },1000)
      
    }else{
      this.formValid=false
      this.loaderVisible=false
      this.inValidText=false
      this.carDetailsAvailable=false
    }
   
  }

  cancelPopup(){
    this.showModal=false
    this.isItInEditCarState=false
    this.cancelClicked.emit(true)
  }
  searchLicense(value:any){
   this.searchLicenseValue=value
  }
  checkVin(){
    this.subscriptions.push(this.dataService.getCarDetails(this.vinToUppercase)
    .subscribe({
      next: (value:any)=>{
      this.validVin=true
      this.loaderVisible=false
      this.inValidText=false
      this.carDetailsAvailable=true
      this.carDetails={
        carName:value.carName,
        carImage:value.carImageUrl,
        carColor:value.carColor
      }  
    },
    error: ()=>{
      this.loaderVisible=false
      this.carDetailsAvailable=false
      this.validVin=false
      this.errorOccured=true
      this.inValidText=true
    }
  }))
  }
  addCarToFleet(){
    if(this.searchLicenseValue.length){
    this.addCarReq={
      "vin": this.vinToUppercase,
      "licensePlate": this.searchLicenseValue
    }
    }else{
    this.addCarReq={
        "vin": this.vinToUppercase
      }
    }
    this.loadingSpinnerService.show()
    this.subscriptions.push(this.dataService.addCarToFleet(this.addCarReq)
    .subscribe({
      next: (data:any)=>{
      if(data){
        this.sharingData.setCarAddedSuccessfully(true)
        this.showModal=false
        this.sharingData.setCarAdded(true)
        this.cancelClicked.emit(true)
        this.isItInEditCarState=false
        this.loadingSpinnerService.hide()
      }  
    },
    error: (error)=>{
    this.loadingSpinnerService.hide()
       if(error==="Car already present for the user"){
        this.showToast=true
       }
    }
  }))
  }

  ngOnDestroy(): void {
    this.sharingData.setToasterToBeDisplayed(false)
    this.subscriptions.forEach((subscription:any)=>subscription.unsubscribe())
  }
}
