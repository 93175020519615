<ng-container *ngIf="selectedUserType === 'ALL'">
<div class="tag-row-main">
  <div *ngIf="enableCircuitFeature" (click)="setOption('CIRCUIT')" [ngClass]="selectedViewType =='CIRCUIT'? 'tag-track-selected':'' " class="tag-button">
    <span class="icon icon_track"></span> <span class="text-color">{{locationButtonNames.CIRCUITS| customTranslation: translationData}}</span>
  </div>
  <div *ngIf="enableEmotionTourFeature" (click)="setOption('EMOTION_TOUR')" [ngClass]="selectedViewType =='EMOTION_TOUR'? 'tag-tour-selected':'' " class="tag-button">
    <span class="icon icon_emotion_tour"></span> <span class="text-color">{{locationButtonNames.EMOTION_TOUR | customTranslation: translationData }}</span> 
  </div>
  <div *ngIf="enableoffroadfeature" class="tag-button" [ngClass]="selectedViewType =='OFFROAD_TOUR'? 'tag-offroad-selected':'' " (click)="setOption('OFFROAD_TOUR')">
    <span class="icon icon_off_road"></span> <span class="text-color">{{locationButtonNames.OFFROAD | customTranslation: translationData}}</span> 
  </div>
</div>
</ng-container>

<ng-container *ngIf="selectedUserType === 'you'">
  <div class="tag-row-main">
    <div (click)="setOption('mycircuits')" [ngClass]="selectedViewType =='mycircuits'? 'tag-track-selected':'' " class="tag-button">
      <span class="icon icon_track"></span> <span class="text-color">{{'BP_LM_YOUR_TRACKS_LBL' | customTranslation: translationData}}</span>
    </div>
    <div *ngIf="enableEmotionTourFeature" (click)="setOption('myemotiontour')" [ngClass]="selectedViewType =='myemotiontour'? 'tag-tour-selected':'' " class="tag-button">
      <span class="icon icon_emotion_tour"></span> <span class="text-color">{{'BP_LM_YOUR_TRACKS_LBL' | customTranslation: translationData}}</span> 
    </div>
  </div>
</ng-container>
