<div id="app-comp" [ngClass]="{'add-v1-css': !enableLeftPanelView, 'restrictBackgroundScroll': openModalFlag}">
    <header *ngIf="isLoggedIn || isProfileAvailable" id="amg-header">
        <app-header></app-header>
    </header>
    <div id="main-section">
        <main id="amg-content-region">
            <div id="content-inner">
                <ng-container *ngIf="showLanguageSelectionModal">
                    <app-language-selection></app-language-selection>
                </ng-container>
                <app-loading-spinner></app-loading-spinner>
                <router-outlet></router-outlet>
            </div>
            <ng-container *ngIf="refSessionComAvailable">
                <app-refresh-session></app-refresh-session>
            </ng-container>
            
        </main>
        <footer *ngIf="isLoggedIn || isProfileAvailable" id="amg-footer">
            <app-footer></app-footer>
        </footer>
    </div>
</div>