import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-races-time',
  templateUrl: './races-time.component.html',
  styleUrls: ['./races-time.component.scss']
})
export class RacesTimeComponent implements OnInit {

  @Input()
  time: any;

  timeObj: any = {}

  ngOnInit(): void {
    if (this.time) {
      this.getFormatedTime(this.time);
    }
  }

  getFormatedTime(time: number) {
    this.timeObj.hours = Math.floor(((time) / (1000 * 60 * 60)) % 24);
    this.timeObj.minutes = Math.floor(((time) / (1000 * 60)) % 60);
    this.timeObj.seconds = Math.floor(((time) / 1000) % 60);

    const milliseconds = time / 1000;
		const millisecondsArray = milliseconds.toFixed(2).toString().split('.');
		const finalMilliSec =  millisecondsArray[1] ? millisecondsArray[1] : '00';
    this.timeObj.milliseconds = finalMilliSec;
  }

}
