<div>
  <div class="full-screen-modal-header">
    <img
      mat-dialog-close
      class="close-logo"
      src="assets/locations/close.png"
      alt="..."
    />
  </div>
  <app-here-map
    *ngIf="data"
    #map
    [apiKey]="apiKey"
    [trackInfo]="data.encodedTrackPoints"
    [sectorInfo]="data.encodedSectorPoints"
    [trackLength]="data.trackLength"
    width="1341px"
    height="800px"
    [isFullScreenPolyline]="true"
    [showLayerSwitchMenu]="false"
  >
  </app-here-map>
</div>
