import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentencecase'
})
export class SentencecasePipe implements PipeTransform {

  transform(value: string): string {
    const splitString = value.split('.').map((s) => {
      const trimmedString = s.trim();
      if (trimmedString.length > 0) {
        return `${trimmedString[0].toUpperCase()}${trimmedString.slice(1)}`;
      }
      return '';
    });
    return splitString.join('. ');
  }

}
