import { Component, OnInit } from '@angular/core';
import { AuthAmplifyService } from 'src/app/shared/service/auth-amplify.service';
import { Auth} from "aws-amplify";
import { Router } from '@angular/router';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit {

  auth = Auth;
  isLoggedInUser = false;
  loggedInUserData:any;

  constructor(private authService: AuthAmplifyService, private router: Router) { }

  ngOnInit(): void {
    this.authUser();   
  }

  async authUser() {
    /* 
      await this.auth.currentAuthenticatedUser()  VS await this.auth.currentSession()
      currentAuthenticatedUser will always look for access tokens stored in localstorage.
      currentSession call api and get new token so better to use currentSession.      
    */
    await this.authService.currentSession().then(
      (_data) => {
        this.router.navigate(['events']);
      },
      (_res) => {
        this.login();
      }
    );
  }




  login() {
    this.authService.signIn();
  }

  async logout() {
    await this.auth.signOut()
      .then((_data) => {
        setTimeout(()=> {
          window.open('https://sso-int.mercedes-benz.com/idp/startSLO.ping?TargetResource=http://localhost:4200/logout', "_self","noopener" );
        }, 1000);
      }) 
  }

  // logout() {
  //     this.authService.logout();
  // }
}