<ng-template #showModalContent>
<div class="alias-list-none">
    <div class="alias-list-bg"></div>
    <h5 class="join-business">{{'BP_PM_JB' | customTranslation:translationData}}</h5>
    <div class="user-name">
      <p>{{'BP_PM_HI'| customTranslation:translationData}}</p> 
      <p>{{userName}}</p>
    </div>
    <p>{{'BP_PM_PCYA'| customTranslation:translationData }}.</p>
   
    <div class="timer-section">
    <p class="timer">{{'BP_PM_YWLI' | customTranslation:translationData }} :</p> 
    <h5 *ngIf="timer >=0">
      <span>{{timer}}</span> 
      <small>s</small> </h5>
    </div>
</div>
</ng-template>


<app-common-modal
  [isHeaderVisible]="false"
  [isFooterVisible]="false"
  [showModalContent]="showModalContent"
  [isCloseModal]="false"
  [type]="type"
  *ngIf="showModal"
></app-common-modal>
