import { AfterContentChecked, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { LocationsHttpService } from '../../services/locations-http.service';
import { APP_CONSTANTS } from 'src/app/shared/constants/constants';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
import { CircuitsServicesService } from '../../services/circuits-services.service';

@Component({
  selector: 'app-circuit-details-form',
  templateUrl: './circuit-details-form.component.html',
  styleUrls: ['./circuit-details-form.component.scss']
})
export class CircuitDetailsFormComponent implements OnInit, OnDestroy, AfterContentChecked, OnChanges {

  form!: UntypedFormGroup;
  @Input() circuitData: any;
  subscribeCircuitFormData: any;
  subscribeMediaData: any;
  subscribeDeleteMediaData: any;
  mediaFiles: any;
  deleteMediaFiles: any;
  wordCount = 0;
  showToast = false;
  toastMessage: any;
  position: any;
  toastType: any;
  leftPosition: any;
  translationData: any;
  commonLabels = APP_CONSTANTS.COMMON_LABELS;
  locationConstants = LOCATIONS_CONSTANTS;
  hasImage: boolean = false;
  @Input() imageUrls: any[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
    private locationsHttpService: LocationsHttpService,
    private router: Router,
    private toastService: ToastService,
    private _spinnerService: LoadingSpinnerService,
    private translationService: TranslationService,
    private _circuitService: CircuitsServicesService) { }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );
    this.subscribeCircuitFormData = this.locationsHttpService.shareCircuitformData.subscribe(
      (data) => {
        if (data === 'save') {
          this.onSubmit();
        }
      }
    );
   
    let formValue = localStorage.getItem('form-data');
    if(formValue) {
      formValue = JSON.parse(formValue)
      this.getFormDetailsFromStorage(formValue);
    } else{
      this.circuitData.website = this.circuitData.web;
      this.getFormDetailsFromStorage(this.circuitData);
    }
  }

  ngAfterContentChecked() {
    this.textAreaAdjust();  
  }

  ngOnChanges(): void {
    this.hasImage = this.checkIfImageExists();
    if (!this.hasImage) {
      this.form.controls.copyright.reset();
    }
  }

  getFormDetailsFromStorage(data:any) {
    this.form = this.formBuilder.group({
      name: data.name || '',
      guid: data.guid || '',
      description: data.description || '',
      website: data.website || '',
      wikipedia: data.wikipedia || '',
      address: data.address || '',
      type: data.type || 'TEMPORARY',
      copyright: this.formBuilder.group({
      attribution: data.media?.image[0]?.copyright?.attribution || null,
      license: data.media?.image[0]?.copyright?.license || null,
      license_url: data.media?.image[0]?.copyright?.license_url || null,
      subject_url: data.media?.image[0]?.copyright?.subject_url || null
      })
    });
    this.wordCount = this.form.value.description.length;
    this.hasImage = this.checkIfImageExists();
  }

  checkIfImageExists() {
    return this.circuitData?.media?.image.length && this.imageUrls.length
  }

  getMedia() {
    this.subscribeMediaData =
      this.locationsHttpService.sharemediaData.subscribe((data) => {
        this.mediaFiles = data[0];
      });

    this.subscribeDeleteMediaData =
      this.locationsHttpService.deletemediaData.subscribe((data) => {
        this.deleteMediaFiles = data[0];
      });
  }


  initializeFormDetails() {
    this.form = this.formBuilder.group({
      name: this.circuitData.name?this.circuitData.name: '',
      guid: this.circuitData.guid?this.circuitData.guid: '',
      description: this.circuitData.description ? this.circuitData.description : '',
      website: this.circuitData.web?this.circuitData.web: '',
      wikipedia: this.circuitData.wikipedia?this.circuitData.wikipedia: '',
      address: this.circuitData.address?this.circuitData.address: '',
      type: this.circuitData.type?this.circuitData.type: 'TEMPORARY'
    });
    this.wordCount = this.form.value.description.length;
  }

  checkWordCount() {
    this.wordCount = this.form.value.description.length;
  }

  textAreaAdjust() {
    let textbox = document.getElementById("description")
    if(textbox) {
      textbox.style.overflow = "hidden";
      textbox.style.height = "1px";
      textbox.style.height = (textbox.scrollHeight)+"px";
    }  
    if(textbox && textbox.scrollHeight > 100 ) {
      textbox.style.overflow = "auto"
      textbox.classList.add("scroll-style");
    }  
  }

  /**
   * @description On form submit, call the API to create or update the circuit
   */
  public onSubmit(): void {
    this._spinnerService.show();
    this.getMedia();
    const circuitRequest = this.form.value;
    if (this.deleteMediaFiles) {
      let deletedMedia = []
      deletedMedia.push(this.deleteMediaFiles);
      this.form.value.deleteMediaIds = deletedMedia
    }
    if (this.form.value['copyright']) {
      circuitRequest['copyright'] = {
        attribution: this.form.value.copyright.attribution,
        license: this.form.value.copyright.license,
        license_url: this.form.value.copyright.license_url,
        subject_url: this.form.value.copyright.subject_url,
        complete: true
      }
    }
    if ((!circuitRequest['copyright']?.attribution && !circuitRequest['copyright']?.license && !circuitRequest['copyright']?.license_url
      && !circuitRequest['copyright']?.subject_url)) {
        // We do not want to remove the form control, so we are firct assigning and then getting rid of it
        delete circuitRequest['copyright']
      } 
    if (!this.circuitData.isPrevPageLocation) {
      
      this.locationsHttpService
        .updateCircuitWithMedia(circuitRequest, this.mediaFiles)
        .subscribe(
          {
            next: (data) => {
              if (data.message) {
                const successMsg = LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.CIRCUIT_UPDATE_SUCCESS_MSG;
                this.toastService.show(ToastType.Success, successMsg, '1%', '40%');
                setTimeout(() => {
                  localStorage.removeItem('form-image')
                  this.toastService.hide();
                  this._spinnerService.hide();
                  this.router.navigateByUrl(
                    `/locations/circuit/${this.circuitData.guid}`
                  );
                }, APP_CONSTANTS.MIN_TIME_TOASTER);
              }
            },
            error: (error) => {
              console.log(error, "error");
              this._spinnerService.hide();
              const errorMsg = error.error['error-message'] ? error.error['error-message'].split(':')[1] : APP_CONSTANTS.COMMON_ERROR_MSGS.SOMETHING_WENT_WRONG_MSG;
              this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
              setTimeout(() => {
                this.toastService.hide();
              }, APP_CONSTANTS.MIN_TIME_TOASTER);
            }
          }


        );
    } else {
      let circuitObj = this.form.value;
      console.log('this.form.value', this.form.value)
      if (this.circuitData?.coordinates.radius) {
        circuitObj.radius = this.circuitData.coordinates.radius
        circuitObj.coordinates = this.circuitData.coordinates.lat.toString() + "," + this.circuitData.coordinates.lng
      } else {
        circuitObj.encodedPolyline = this.circuitData.coordinates
      }

      this.locationsHttpService.createCircuit(circuitRequest, this.mediaFiles).subscribe({
        next: (data: any) => {
          if (data.message) {
            this.toastService.show(ToastType.Success, LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.CIRCUIT_CREATE_SUCCESS_MSG, '1%', '40%');
            setTimeout(() => {
              this._spinnerService.hide();
              this.toastService.hide();
              this.router.navigateByUrl(
                `/locations/circuit/${data.guid}`
              );
            }, LOCATIONS_CONSTANTS.MIN_TIME);
          }
        },
        error: (error) => {
          this._spinnerService.hide();
          const errorMsg = this._circuitService.getToastErrMessagesBasedOnCode(error.error['error-code']);
          this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
          setTimeout(() => {
            this.toastService.hide();
          }, APP_CONSTANTS.MIN_TIME_TOASTER);
        },
      });
    }

  }

  ngOnDestroy() {
    localStorage.setItem('form-data', JSON.stringify(this.form.value));
    if(this.subscribeCircuitFormData){this.subscribeCircuitFormData.unsubscribe();}
    if(this.subscribeMediaData){this.subscribeMediaData.unsubscribe();}
    if(this.subscribeDeleteMediaData){this.subscribeDeleteMediaData.unsubscribe();}   
    this._circuitService.setCoordinateValue(null)
  }

}