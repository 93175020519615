import { Injectable } from "@angular/core";
import Dexie from "dexie";
import { LocationsHttpService } from "../../services/locations-http.service";

@Injectable({
  providedIn: "root",
})
export class DealerTourDbService extends Dexie {

  constructor(private locationService: LocationsHttpService) {
    super("DexieDB"); //database name 'DexieDB'
    this.openVersion();
  }
  openVersion() {
    this.version(1).stores({
      myDealerTourStore:"dealertourId"
    });
  }
  openDb() {
    this.open() //opening the database
      .then((_data) => console.log("DB Opened"))
      .catch((err) => console.log(err.message));
  }

  async addTour(obj: any) {
    await this.table("myDealerTourStore")
      .add(obj)
      .then((data) => console.log('tour data',data))
      .catch((err) => console.log(err.message));
  }

  async getTour(id:any) {
    let tourData
    await this.table("myDealerTourStore")
      .get({ dealertourId: id })
      .then((data) => {
        console.log(data);
        tourData = data;
      })
      .catch((err) => console.log(err.message));
      return tourData
  }

  async updateTour(key: any, obj: any) {
    await this.table("myDealerTourStore")
      .update(key, obj)
      .then((data) => console.log(data))
      .catch((err) => console.log(err.message));
  }

  async resetTourDatabase(tableName:any) {
    await this.table(tableName)
      .clear();
  }
}
