export class APP_CONSTANTS {
    static MAX_TIME_TOASTER: number = 5000;
    static MIN_TIME_TOASTER: number = 3000;
    static MAX_IMAGE_SIZE: number = 15000000;

    static COMMON_BUTTON_LABELS = {
        ADD: 'BP_CM_ADD_BTN_LBL',
        BACK: 'BP_CM_BACK_BTN_LBL',
        BROWSE: 'BP_CM_BROWSE_BTN_LBL',
        CANCEL: 'BP_CM_CANCEL_BTN_LBL',
        CLOSE: 'BP_CM_CLOSE_BTN_LBL', //3
        CONFIRM: 'BP_CM_CONFIRM_BTN_LBL',
        CONTINUE: 'BP_CM_CONTINUE_BTN_LBL',
        DELETE: 'BP_CM_DELETE_BTN_LBL',
        DISCARD: 'BP_CM_DISCARD_BTN_LBL',
        DONE: 'BP_CM_DONE_BTN_LBL',
        EDIT: 'BP_CM_EDIT_BTN_LBL',
        GPX_FILE_UP: 'BP_CM_GPX_FILE_LABEL',
        NO: 'BP_CM_NO_BTN_LBL',
        SAVE: 'BP_CM_SAVE_BTN_LBL',
        UPDATE: 'BP_CM_UPDATE_BTN_LBL',
        YES: 'BP_CM_YES_BTN_LBL',
        LOAD_MORE: 'BP_CM_LOAD_MORE_BTN_LBL',
    }

    static COMMON_TITLE_LABELS = { 
        PREVIEW:'BP_CM_PREVIEW',
        LOCATION_TITLE: 'BP_LM_LOCATIONS_TITLE',
        EVENTS_TITLE: 'BP_EM_EVENTS_TITLE'
    }
    
    static COMMON_ERROR_MSGS = {
        PAGE_NOT_FOUND_MSG: 'BP_CM_PAGE_NOT_FOUND_MSG',
        MAX_ALLOWED_IMG_SIZE: 'BP_CM_IMG_MAX_SIZE_MSG',
        SOMETHING_WENT_WRONG_MSG: 'BP_CM_SOMETHING_WENT_WRONG_MSG',
        INVALID_IMAGE_FORMAT: 'BP_CM_INVALID_IMAGE_FORMAT_MSG',
    }

    static COMMON_LABELS = {
        ADD_MEDIA: 'BP_CM_ADD_MEDIA',
        CHARACTERS: 'BP_CM_CHARACTERS',
        ADD_COVER_IMG: 'BP_CM_ADD_COVER_IMG',
        CREATED_BY:'BP_CM_CREATED_BY',
        DESCRIPTION:'BP_CM_DESCRIPTION',
        VIEW_DETAILS:'BP_CM_VIEW_DETAILS',
        ACTIONS:'BP_CM_ACTIONS',
        UPLOAD_COVER_IMAGE:'BP_CM_UPLOAD_COVER_IMAGE'
    }
}


