<div *ngIf="enableRadiusInputBox" class="radius-input-container">
    <span>{{locationConstants.HELPER_MSGS.ENTER_GEOFENCE_RADIUS| customTranslation: translationData }}</span>
    <span class="radius-container">
        <!--This came as a requirement from Om for 10th Jun release that when the cursor is out of focus, set radius -->
        <input maxlength="5" class="radius-input-style" [(ngModel)]="radius" #ctrl="ngModel" (keyup.enter)="sendRadiusValue($event)" (focusout)="sendRadiusValue($event)"> <span class="unit-style">Km</span>
    </span>    
</div>

<div *ngIf="notificationMessage.length > 0 " class="notification">
    <span>{{notificationMessage | customTranslation: translationData }}</span>
</div>

<div class="buttons-layer-style">
    <button (click)="optionSelected('polygon')" class="button-style"
        [ngClass]="selectedOption.substring(0,7) === 'polygon'? 'selected-button-style':'' ">
        <img src="assets/rectangle.png" alt="..."/>
        <div>{{locationConstants.HELPER_INPUT_LABELS.POLYLINE | customTranslation: translationData}}</div>
    </button>
    <div [ngClass]="selectedOption.substring(0,6) === 'circle' || selectedOption.substring(0,7) === 'polygon'? 'vertical-line-selected':'' " class="vertical-line"></div>
    <button (click)="optionSelected('circle')" class="button-style"
        [ngClass]="selectedOption.substring(0,6) === 'circle'? 'selected-button-style':'' ">
        <img src="assets/circle.png" alt="..."/>
        <div>{{locationConstants.HELPER_INPUT_LABELS.RADIUS| customTranslation: translationData}}</div>
    </button>
    <div [ngClass]="selectedOption.substring(0,6) === 'circle' || selectedOption.substring(0,4) === 'clear'? 'vertical-line-selected':'' " class="vertical-line"></div>
    <button (click)="optionSelected('clear')" class="button-style">
        <img src="assets/fencedelete.png" alt="..."/>
        <div>{{locationConstants.HELPER_INPUT_LABELS.CLEAR| customTranslation: translationData}}</div>
    </button>
</div> 