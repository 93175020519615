import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/shared/service/translation.service';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent {
  @Input() progress = 0;
  translationData: any;
  messageToDisplay: string = '';
  constructor(private _translationService: TranslationService, private _actRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this._actRoute.url.subscribe(urlSegments => {
      const url = window.location.href.split('/');
      const circuitPosition = url.indexOf('circuit');
      const eventsPosition = url.indexOf('events');
      const hasCircuit = urlSegments.some(segment => segment.path.includes('circuit'))  || circuitPosition !== -1;
      const hasEvents = urlSegments.some(segment => segment.path.includes('events')) || eventsPosition !== -1;
      if (hasCircuit || hasEvents) {
        this.messageToDisplay = 'BP_LM_ADDING_LAYOUT_MSG';
      } else {
        this.messageToDisplay =  'BP_LM_ENHANCING_TRK_MSG';
      }
    });
    this._translationService.getTranslationDataAsObservable().subscribe((translationData: any) => {
      this.translationData = translationData;
    });
  }


}
