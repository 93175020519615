import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertModalService } from '../../services/modal/alert-modal.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { EVENTS_CONSTANTS } from 'src/app/modules/events/constants/event-constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit , OnDestroy{

  @Input('title') title = ''
  @Input('message') message = ''
  @Input('cancelBtnTitle') cancelBtnTitle = ''
  @Input('actionBtnTitle') actionBtnTitle = ''
  @Input('flag') flag = '';
  @Input() isCancelVisible:boolean = true;
  @Input() isConfirmVisible: boolean = true;


  subscription : Subscription[] = [];
  isModalOpen = false;
  translationData: any;
  placeholderObject: any = null;

  constructor(private alertModalService: AlertModalService, private _translationService: TranslationService) { 
  }
  
  ngOnInit(): void {
    this.alertModalService.openModal.subscribe((value: boolean) => {
      this.isModalOpen = value
    });
    this.alertModalService.modalData.subscribe((value: any) => {
      if(value) {
        this.title = value.title;
        this.message = value.message;
        this.cancelBtnTitle = value.cancelBtnTitle;
        this.actionBtnTitle = value.actionBtnTitle;
        this.flag = value.flag
        this.placeholderObject = value.placeholderObject;
      }      
    });
    this.subscription.push(this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    ))
  }

  onCancel() {
    this.alertModalService.onClose.next(null);
    this.alertModalService.openModal.next(false);
  }

  onAction() {
    const locationsAlertPopupTitle = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE;
    let data = {page:'', action:false, flag: ''}
    switch (this.title) {
      case locationsAlertPopupTitle.DELETE_TRACK_TITLE:
        if (this.flag) {
          data['page'] = 'delete-track';
          data['action'] = true;
          data['flag'] = this.flag;
        } else {
          data['page'] = 'delete-circuit';
          data['action'] = true;
        }
        break;
      case EVENTS_CONSTANTS.ALERT_POPUP_TITLES.CONFIRM_DELETE_EVENT_TITLE:
        data['page'] = 'delete-event';
        data['action'] = true;
        break;
      case locationsAlertPopupTitle.ALERT:
        data['page'] = 'show-alert';
        data['action'] = true;
        break;      
      case EVENTS_CONSTANTS.ALERT_POPUP_TITLES.CONFIRM_DELETE_EXERCISE_TITLE:
        data['page'] = 'manage-exercise';
        data['action'] = true;
        break;
      case 'Something went wrong':
        data['page'] = 'session-expired';
        data['action'] = true;
        break;
      case locationsAlertPopupTitle.CONFIRM_CANCEL:
      case locationsAlertPopupTitle.CANCEL_EMOTION_TOUR_TITLE:
      case locationsAlertPopupTitle.DLT_INSDR_SPT_TITLE:
      case locationsAlertPopupTitle.CANCEL_EDITING_CARD_DTLS:
      case locationsAlertPopupTitle.DELETE_ET_TITLE:
      case locationsAlertPopupTitle.DELETE_OFFROAD_TITLE:
        if (this.flag) {
          data['page'] = 'cancel_current_step';
          data['action'] = true;
          data['flag'] = this.flag;
        } else {
          data['page'] = 'revert';
          data['action'] = true;
        }
        break;
      }
    this.alertModalService.onAction.next(data);
    this.alertModalService.openModal.next(false);
  }

  ngOnDestroy(): void {
    this.subscription.forEach((subs) => {
      subs.unsubscribe();
    });
  }


}
