<div class="row filer-container-row">
    <div class=" search-box-container">
        <input type='search' class="input-search" placeholder="Type name" (input)="searchTextChange($event)"  #searchInput />
    </div>
    <div class="col checkbox-container">
        <div class="form-check">
            <input class="form-check-input checkbox cursor" type="checkbox" value="" id="flexCheckDefault" (change)="handleOnlyWithLayoutsClick($event)" #onlyWithLayoutCheckbox>
            <label class="form-check-label checkbox-title cursor" for="flexCheckDefault"> {{"BP_CIRCUIT_LAYOUT_ONLY_LBL"}} </label>
        </div>
    </div>
    <div class="col-md-2 add-new-circuit-container">
        <button type="button" class="beta" (click)="handleAddCircuitClick()">
            <span>{{"BP_LM_ADD_NEW_CIRCUIT"}}</span>
        </button>
    </div>
</div>