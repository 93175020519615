import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonModalService } from '../../events/services/common-modal.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { DataServiceServiceProfile } from '../services/profile-data-service.service';
import { APP_CONSTANTS } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-create-edit-profile',
  templateUrl: './create-edit-profile.component.html',
  styleUrls: ['./create-edit-profile.component.scss']
})
export class CreateEditProfileComponent implements OnInit {

  constructor(
    private commonModalService:CommonModalService,
    private fb:UntypedFormBuilder,
    private translationService:TranslationService,
    private profileDataService:DataServiceServiceProfile
  ) { }
  public aliasForm!: UntypedFormGroup;
  @ViewChild('editPopup') editPopup: TemplateRef<any> | undefined;
  showEditPopup:boolean= true
  popupType:string='large-popup'
  imageUrl:any
  fileToUpload: any;
  translationData:any;
  public buttonNames: any = APP_CONSTANTS.COMMON_BUTTON_LABELS;
  public appConstants = APP_CONSTANTS;

  @Output() public cancelClicked = new EventEmitter<any>();
  ngOnInit(): void {
    this.commonModalService._modalHeaderIsCloseBtnVisible.next(true)
    this.commonModalService._modalHeaderIsSaveBtnVisible.next(true)
    this.commonModalService._modalHeaderTitle.next('Edit Alias Details')

    this.translationService
    .getTranslationDataAsObservable()
    .subscribe((translationData: any) => {
      this.translationData = translationData;
    });
    this.aliasForm=this.fb.group({
      'profileName':[localStorage.getItem('aliasGroupName'),Validators.required],
      'profileDescription':[''],
      'businessWebsite':['',Validators.required],
      'profileImageDeletion':["false"]
    })
  }

  public saveProfileData(): void {
    const payload=
    {
      profileName:this.aliasForm.value.profileName,
      profileDescription:this.aliasForm.value.profileDescription,
      businessWebsite: this.aliasForm.value.businessWebsite,
      profileImageDeletion:"false"
    }
    
    this.profileDataService.updateProfile(payload,this.aliasForm.value.uploadedImage).subscribe(
      (data)=>{
        this.showEditPopup=false
        this.cancelClicked.emit(true)
      }
    )
   }

  handleFileInput(file:any) {
    this.fileToUpload = file.files?.item(0);
    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.aliasForm.addControl('uploadedImage', new UntypedFormControl(this.fileToUpload))
      // this.aliasForm.addControl
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  closeModal(){
   this.showEditPopup=false
   this.cancelClicked.emit(true)
  }

}
