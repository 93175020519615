import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-track-list',
  templateUrl: './track-list.component.html',
  styleUrls: ['./track-list.component.scss']
})
export class TrackListComponent {
  @Input() public selectedUserType: any;
  @Input() public selectedViewType: any;
  @Input() public tracks:any = [1,2,3,4,5];
  @Output() public sendSearchedLocation = new EventEmitter<object>();


  goToTrack(item:any) {
    if (item.type.toLowerCase() == "circuit") {
      item.type = "circuits";
      item.viewType ="circuits"
    }
    if (item.type.toLowerCase() == "emotion_tour") {
      item.type = "emotiontour";
      item.viewType ="emotiontour"
    }
    item.isSearch = true;
    this.sendSearchedLocation.emit(item);
  }

}
