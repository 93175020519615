import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAlertModalComponent } from './common-alert-modal.component';
import { TranslationModule } from 'src/app/modules/translation/translation.module';

@NgModule({
  declarations: [CommonAlertModalComponent],
  imports: [
    CommonModule,
    TranslationModule
  ],
  exports: [CommonAlertModalComponent]
})
export class CommonAlertModalModule { }
