<div *ngIf="showToast" class="toast-style">
  <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position"  [leftPosition]="leftPosition" (selectedOption)="setCreateOption($event)" >
  </app-toast>
</div>
<div class="web-portal-container">
  <div *ngIf="!showNotFoundPage" class="circuit-details">
    <div class="geo-header-style" *ngIf="
        !isShowCircuitInfo &&
        !isShowEmotionTourInfo &&
        !showMapView &&
        isShowAddCircuitSearch
      ">
      <app-layout-details-header [primaryButtonTitle]="primaryButtonTitle" [title]="trackDetailsName"
        [secondaryButtonTitle]="secondaryButtonTitle" [primaryButtonColor]="primaryButtonColor"
        (primaryButtonClick)="primaryButtonClick()" (secondaryButtonClick)="secondaryButtonClick($event)"
        [isDisablePrimaryButton]="isDisablePrimaryButton">
      </app-layout-details-header>
    </div>
    <ng-container>
      <div class="circuit-here-map">
        <div class="toggle-button-alignment" *ngIf="showSearchAndCreate">
          <app-create-dropdown [dropDownStatus]="this.isDropdownOpen" (selectedOption)="setCreateOption($event)">
          </app-create-dropdown>
        </div>

        <div class="circuits-search-container" [ngClass]="selectedUserType =='you'? '': 'overflow-class' "
          [style.visibility]="
            !isShowCircuitInfo && !isShowEmotionTourInfo && showMapView
              ? 'visible'
              : 'hidden'
          ">
          <div class="search-bar-class">
            <h3>{{searchHeader | customTranslation:translationData}}</h3>
            <span *ngIf="!enableLeftPanelView">{{searchDescription | customTranslation:translationData}}</span>
            <ng-container *ngIf="selectedUserType === 'ALL'">
              <app-search [type]="this.selectedType" [placeholderText]="searchPlaceholderText"
                (showSearchedLocation)="getpannedCircuitData($event)"
                (emitSearchResults)="setSearchResults($event)"></app-search>
            </ng-container>
          </div>
          <div class="filter-top-section">
            <div (click)="changeUserSelectedType('ALL')" [ngClass]="selectedUserType =='ALL'? 'tab-selected':'' ">
              <span>{{'BP_EM_All' | customTranslation: translationData}}</span>
            </div>
            <div *ngIf="enableLeftPanelView && enableCreatedByYouFilter">
              <span>{{'BP_CM_CREATED_BY_YOU' | customTranslation:translationData}}</span>
            </div>
          </div>
          <div class="sub-menu-container">
            <app-locations-submenu-toggle [selectedUserType]="selectedUserType"  (selectedOption)="meunOptionClicked($event)" [selectedViewType]="selectedType">
            </app-locations-submenu-toggle>
          </div>
          <div class="location-list-main" *ngIf="enableLeftPanelView">
            <div class="cluster-style">
              <app-clusters-list [clusterList]="passedList"  
              (loadTrackDetailsPage)="getpannedCircuitData($event)"
              [displaySpinnerForClusters]="displayClusterLoader" [selectedType]="selectedType"></app-clusters-list>
            </div>
          </div>
          <ng-container *ngIf="searchResults?.length > 0">
            <app-track-list (sendSearchedLocation)="getpannedCircuitData($event)" [tracks]="searchResults" [selectedViewType]="previousType" [selectedUserType]="selectedUserType">
            </app-track-list>
          </ng-container>
        </div>

        <div *ngIf="
            !isShowCircuitInfo &&
            !showMapView &&
            isShowAddCircuitSearch &&
            !isShowCircuitGeofence
          " class="circuits-geosearch-container">
          <span>{{'BP_LM_SEARCH_GEO_FENCE' | customTranslation : translationData}}</span>
          <app-search placeholderText="BP_LM_SEARCH_BY_LOCATION" [page]="page"
            (showSearchedLocation)="getSelectedLocationAddress($event)"></app-search>
        </div>

        <div #scrollRef class="circuit-container-details section" *ngIf="isShowCircuitInfo && (!isShowTrackInfo || (isShowTrackInfo && !trackLength))">
          <app-circuit-info-modal [circuitDetails]="this.locationData" (isShowMapView)="showSearch($event)" [isshowDropDown]="isshowDropDown">
          </app-circuit-info-modal>
        </div>
        <div class="circuit-container-details" *ngIf="isShowTrackInfo && trackLength">
          <!---->
          <app-track-detail-modal (displayDiscardCloseModal)="displayDiscardCloseModal($event)"
          (updatedCircuitDataEmitter)="updatedCircuitData($event)" (updateTrackVisibility)="updateTrackVisibility($event)"
           #trackDetailsComponent></app-track-detail-modal>
          <!---->
        </div>

        <div #scrollRef *ngIf="isShowEmotionTourInfo">
          <app-image-card-carousel [trackData]="emotionTourTrackencodedGpsPoints"
            (selectedAnnotation)="selectedAnnotation($event)"
            [selectedAnnotationIdobj]="this.selectedAnnotationsId"></app-image-card-carousel>
        </div>
        <div class="map-container-main">
          <div class="map-pane" >
            <app-here-map *ngIf="this.circleCoordinates" class="geoman-class" [apiKey]="apiKey" width="100%"
              height="100%" [circuitInfo]="circuitList" [emotionTourInfo]="emotionTourList"
              [selectedType]="selectedType" (pannedCircuitData)="getpannedCircuitData($event)"
              (showLocationInfo)="showLocationInfo($event)" [trackInfo]="trackdetails" [sectorInfo]="sectorPointsDetails"
              [offRoadTourList]="offRoadTourList"
              [circleCoordinates]="this.circleCoordinates" [trackLength]="trackLength" [radius]="circuitRadius"
              [isShowCircuitInfo]="isShowCircuitInfo" [isShowTrackInfo]="isShowTrackInfo"
              [isShowEmotionTour]="isShowEmotionTourInfo" [emotionTourDetails]="emotionTourDetails"
              [searchLocation]="searchLocation" [deletedCircuitGuid]="deletedCircuitGuid"
              [isCircuitDetailsPage]="this.isCircuitDetailsPage" [backToLocationsFromCircuitDetails]="
                backToLocationsFromCircuitDetails
              " [emotionTourTrackencodedGpsPoints]="emotionTourTrackencodedGpsPoints"
              [isShowAddCircuitSearch]="isShowAddCircuitSearch" (selectedLocation)="selectedLocation($event)"
              (disableSearchBar)="disableSearchBar($event)" [selectedOption]="selectedButton"
              [setZoomInLocation]="setZoomInLocation" [selectedAnnotationsId]="selectedAnnotationsId"
              (selectedAnnotationObj)="selectedAnnotation($event)" [selectedLocationAddress]="selectedLocationAddress"
              (emitRadius)="sendRadiusValuetoInput($event)" (emitNotification)="updateNotification($event)"
              (enableRadiusBar)="enableRadiusInput($event)" [radiusValueFromTool]="radiusValueFromTool"
              [clearDrawnItems]="clearDrawnItems" [selectedUserType]="selectedUserType"
              (backToHome)="backToHomeFromMap()">
            </app-here-map>

            <app-status-modal *ngIf="isAddCircuit" [statusTitle]="statusTitle" [statusMessage]="statusMessage"
              [statusSubMessage]="statusSubMessage" [statusImage]="statusImage" [isShowStatus]="isAddCircuit">
            </app-status-modal>
            <div class="map-notification" *ngIf="(!isShowCircuitInfo && !isShowEmotionTourInfo) && isShowZoombutton"
              [ngClass]="isShowCircuitGeofence ? 'geofence-notification' : ''">
              {{ notificationMessage | customTranslation: translationData }}
              <span *ngIf="isShowZoombutton">
                <div>{{'BP_LM_ZOOM_IN_MSG' | customTranslation: translationData}}<a (click)="zoomIn()" *ngIf="isShowZoombutton"
                    class="zoom-in-button">{{'BP_LM_ZOOM_IN_LBL' | customTranslation: translationData }}</a></div>
              </span>
            </div>
            <div class="map-buttons-layer-style" *ngIf="
              isShowCircuitGeofence &&
              !isShowCircuitInfo &&
              !isShowEmotionTourInfo &&
              !showMapView
            ">
            <div class="notification-main">
                <app-geofence-toolbar (radiusValue)="sendRadiusValuetoMap($event)" (selectedTool)="optionSelected($event)"
                  [notificationMessage]="notificationMessage" [selectedButton]="selectedButton"
                  [enableRadiusInputBox]="enableRadiusInputBox" [radius]="radiusValue"></app-geofence-toolbar>
              </div>
            </div>         
          </div>
        </div>
      </div>
      <div class="carousel-wrapper" *ngIf="isShowCircuitInfo">
        <div class="layout-count-wrapper">
          <span class="example-spacer"></span>
        </div>
        <div class="track-carousel">
          
            <app-track-cards-carousel [trackData]="slides" class="track-class"
              (selectedTrack)="showTrackPolyline($event)" (showModal)="addNewLayout($event)"
              [trackFromSearch]="selectedTrackFromSearch"></app-track-cards-carousel>
          
        </div>
      </div>
      </ng-container>
    </div>
  </div>

<div *ngIf="showNotFoundPage">
  <app-page-not-found></app-page-not-found>
</div>

<div *ngIf="showModal">
  <app-trackupload (closeModal)="closeModal($event)" [isShowModal]="showModal"></app-trackupload>
</div>

<app-common-alert-modal [title]="alertTitle" [message]="alertMessage" [isModalOpen]="isModalOpen"
[actionType]="actionType" (isModalResponseClicked)="getCancelModalResponse($event)"
[cancelBtnTitle]="alertCancelBtnTitle" [actionBtnTitle]="alertActionBtnTitle"></app-common-alert-modal>  