<div class="group-section" *ngIf="!profileswitchselected">
  <span class="operate">{{ 'BP_PM_OPERATING_AS' | customTranslation:translationData}}</span>
  <div class="profile">
    <span class="dp">
      <img [src]="profileImage" alt="">
    </span>
    <div>
      <p>{{ groupName}}</p>
      <span (click)="switchProfile($event)" role="button">{{'BP_PM_SWITCH_PROFILE' | customTranslation:translationData}}</span>
    </div>
  </div>
  <ul>
    <li (click)="manageBusiness()" role="button" *ngIf="enableManageBusiness">
      <span class="manage-business">{{'BP_PM_MANAGE_BUSINESS' | customTranslation:translationData}}</span>
    </li>
    <li role="button" (click)="myFleets()" *ngIf="enableManageFleet">
      <span class="manage-feet">{{'BP_PM_MANAGE_FLEET' | customTranslation:translationData}}</span>
    </li>
  </ul>
</div>

<ng-container *ngIf="profileswitchselected">
  <app-alias-list [isSwitchProfile]="true"></app-alias-list>
</ng-container>
