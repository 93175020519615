export interface ISearchResponse {
    locations: ILocation[]
    totalElements: number
    totalPages: number
    pageNumber: number
    pageSize: number
  }
  
  export interface ILocation {
    guid: string
    name: string
    type: string
    visibility: string
    description: string
    coordinates: string
    createdDate: string
    updatedDate: string
    owner: IOwner
  }
  
  export interface IOwner {
    ciamId: string
    bio: string
    link: string
    nickName: string
    profileImage: string,
    name: string
  }

  export interface CircuitDetailsResponse {
    name: string
    location: string
    address: string
    web: string
    wikipedia: string
    continent: string
    country: string
    layouts: Layout[]
    type: string
    description: string
    media?: Media2
    encodedPolyline: string
  }

  export interface BoostData {
    modelType:string,
    updatedTime:Date
  }
  
  export interface Layout {
    boostDetails?:BoostData[],
    circuitGuid?: string
    layoutUrl?: string
    guid: string
    name: string
    encodedGpsPoints: string | number[][] 
    sectorPoints: number[][]
    createdDate: string
    updatedDate: string
    visibility: string
    noOfSectors: number
    trackLength: number,
    media?: Media
    trackType: number
    description?: string
    owner: IOwner
  }

  
  
  export interface Media {
    image?: Image[]
  }
  
  export interface Image {
    id: string
    name: string
    originalUrl?: string
    thumbnailUrl?: string
  }
  
  export interface Media2 {
    image: Image2[]
  }
  
  export interface Image2 {
    id: string
    name: string
    originalUrl?: string
    thumbnailUrl?: string
  }

  export interface MapViewResponse {
    circuitInfoList: (CircuitInfoListEntity)[];
    emotionTourList: (EmotionTourListEntity)[];
    offRoadTourList: (OffRoadTourListEntity)[];
  }
  export interface CircuitInfoListEntity {
    guid: string;
    name: string;
    visibility: string;
    continent: string;
    country: string;
    coordinates: string;
    layoutsAvailable: boolean;
    layouts: number;
    createdDate: string;
    updatedDate: string;
    description?: string | null;
    media?: Media | null;
    encodedPolyline?: string | null;
  }
  export interface Media {
    image?: Image[] | undefined;
  }
  export interface EmotionTourListEntity {
    guid: string;
    name: string;
    visibility: string;
    description?: string | null;
    coordinates: string;
    createdDate: string;
    updatedDate: string;
    encodedPolyline?: string | null;
    annotationsCount: number;
    trackLength: number;
    media?: Media | null;
  }
  
  export interface OffRoadTourListEntity {
    guid: string;
    name: string;
    visibility: string;
    description?: string | null;
    coordinates: string;
    createdDate: string;
    updatedDate: string;
    encodedPolyline?: string | null;
    annotationsCount: number;
    trackLength: number;
  }

  export interface SearchResponse {
    locations: (LocationsEntity)[];
    totalElements: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
  }
  export interface LocationsEntity {
    guid: string;
    name: string;
    type: string;
    visibility: string;
    coordinates: string;
    createdDate: string;
    updatedDate: string;
    owner?: Owner | null;
    description?: string | null;
    totalNumberOfLayouts?: number | null;
    layouts?: (LayoutsEntity)[] | null;
  }
  export interface Owner {
    ciamId: string;
    nickName?: string | null;
    bio?: string | null;
  }
  export interface LayoutsEntity {
    guid: string;
    name: string;
    type: string;
    visibility: string;
    coordinates: string;
    createdDate: string;
    updatedDate: string;
    owner: Owner1;
    trackLength: number;
    noOfSectors: number;
  }
  export interface Owner1 {
    ciamId: string;
  }

  export enum ESEARCH_PAGE_STRING {
    ADD_NEW_EMOTION_TOUR = 'ADD_NEW_EMOTION_TOUR',
    ADD_NEW_CIRCUIT = 'ADD_NEW_CIRCUIT'
  }

  export enum EALL_TRACKS {
    CIRCUIT = 'CIRCUIT',
    EMOTION_TOUR = 'EMOTION_TOUR',
    OFF_ROAD = 'OFFROAD_TOUR',
    OFFROAD_TRACK = 'OFFROAD_TRACK',
    ALL = 'ALL'
  }

  export enum ELOCATION_SCREENS {
    LANDING_PAGE = 'LANDING_PAGE',
    CIRCUIT_INFO = 'CIRCUIT_INFO',
    TRACK_INFO = 'TRACK_INFO',
    EMOTION_TOUR_INFO = 'EMOTION_TOUR_INFO',
  }
  
  