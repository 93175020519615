import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslationService } from '../../service/translation.service';

@Component({
  selector: 'app-generic-tooltip',
  templateUrl: './generic-tooltip.component.html',
  styleUrls: ['./generic-tooltip.component.scss']
})
export class GenericTooltipComponent implements OnInit {

  tooltip: string | TemplateRef<any> | any = '';
  left: number = 0;
  top: number = 0;
  position: string = 'top';
  translationData: any;

  anchorPos: string = '';
  childTemplate: TemplateRef<any> | any;
  isStringInput: boolean = false;

  constructor(private _translationService: TranslationService) { }

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

}
