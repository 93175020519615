<div
    *ngIf="displayPublishedDetails || (state === 'ADD_WAY_POINT' || state === 'START_ADDED' || state === 'ADD_WAY_POINT_DETAILS' || state === 'WAY_POINT_ADDED' || state === 'DESTINATION_CANCEL') || ((state === 'SECOND' || state === 'THIRD' || state === 'DESTINATION_ADDED' || state === 'EDIT_START' || state === 'EDIT_DESTINATION' || currentStep === 'EDITING_DESTINATION' || currentStep === 'EDITING_START') && startLocationAdded)">
    <div class="info-bubble"
        *ngIf="showInsiderSPtMsg">
        <span class="info-bubble-txt">{{'BP_LM_ADD_INSDR_PT' |customTranslation: translationData}}</span>
    </div>
    <div #slidesContainer class="carousal dealer-carousel-parent"
        [ngClass]="{'carousal-scroll': annotationDataSetArray.length>0}" (mouseover)="checkSlidesOverflow()" (mouseleave)="showPrevNextButtons.showLeftArrow = showPrevNextButtons.showRightArrow = false">
        <div class="carousel-item-parent">
            <div class="cr-item">
                <div *ngIf="(this.state === 'ADD_WAY_POINT' || this.state==='ADD_WAY_POINT_DETAILS')&& !gpxUploaded"
                    class="overlay"></div>
                <!----------------------------------Displays Start Card of carousel--------------------------------------------->
                <div *ngIf="(!showLoaderUntillGeoJsonCompletes || (showLoaderUntillGeoJsonCompletes && !tourPublishStage) || (state === 'DESTINATION_CANCEL' || state === 'EDIT_START' || state === 'EDIT_DESTINATION' || currentStep === 'EDITING_DESTINATION' || currentStep === 'EDITING_START'))"
                #startDiv class="add-start-location" (click)="handleSlideClick(startPointData, 'start')"
                    [class.highlight-border]="((activeSlide && activeSlide.includes('origin')) || (editActive && selectedCard === 'start')) && !startPointData.media"
                    [ngClass]="{'overlay-opacity': (((state === 'ADD_WAY_POINT' || state==='ADD_WAY_POINT_DETAILS' || state === 'THIRD' ) || (state === 'START_ADDED') || (state === 'START_ADDED') || (state === 'SECOND'))) || (editActive && selectedCard !== 'start'), 'background-color-change': hideSlidesData}">
                    <div class="highlight-image-class" [ngClass]="editActive && selectedCard === 'start' && startPointData.media[0].url ? 'edit-active': ''">
                        <span class="loc-img-bg" [style.background-image]="'url(' + startPointData.media[0].url+ ')'"
                            *ngIf="startPointData?.media" title="slide-cover-image"></span>
                    </div>
                    <div class="location-content" [ngClass]="{'hide': hideSlidesData}">
                        <div class="panel-box">
                            <div class="icon-selection-style">
                                <span class="panel-row cursor-clickable">
                                    <span class="icon icon_start_flag"></span>
                                    <span *ngIf="!startPointData; else elseBlock" class="loc-title">{{'BP_LM_START' |
                                        customTranslation:translationData}}</span>
                                    <ng-template #elseBlock>
                                        <span title="{{startPointData?.title}}"
                                            class="loc-title">{{startPointData?.title ?
                                            startPointData?.title : startPointData?.name }}</span>
                                    </ng-template>
                                </span>
                            </div>
                            <div class="panel-row">
                                <div class="loc-detail"
                                    title="{{startPointData?.description}}">
                                    {{startPointData?.description}}</div>
                                <div class="location-description">{{startPointData?.description}}</div>
                                <div *ngIf="!tourPublishStage && !displayPublishedDetails" class="delete-slide-block">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="showCompleteCarousel()">
                <div #slide *ngFor="let item of waypointsList; let i = index"
                    (click)="handleSlideClick(item, i)"
                    class="slides-list">
                    <!------------------Case 1: After Tour Publish: Show spinner while geoJson API----------------------------->
                    <!----Case 2: Before Tour Pubslish: This is added to show previous highlights even if spinner is running while AMG annotation is being created--------------->
                    <div *ngIf="!showLoaderUntillGeoJsonCompletes || (showLoaderUntillGeoJsonCompletes && !tourPublishStage)"
                        class="add-start-location" [class.highlight-border]="item.id === activeSlide" [ngClass]="{'overlay-opacity': ((((this.state === 'ADD_WAY_POINT' || this.state==='ADD_WAY_POINT_DETAILS') && !editActive) && this.currentStep !== 'EDIT_AFTER_PUBLISH') || (this.state === 'EDIT_AFTER_PUBLISH' && (this.currentStep === 'ADD_WAY_POINT' || this.currentStep==='ADD_WAY_POINT_DETAILS'))), 
                                    'background-color-change': hideSlidesData, 'show-pointer-icon': displayPublishedDetails, 
                                    'unselected-card-opacity':((editActive || inViewCardDetailsMode) && selectedCard !== i),
                                    'edit-active':((editActive || inViewCardDetailsMode) && selectedCard === i)}">
                            <div class="highlight-image-class" [ngClass]="{'hide': hideSlidesData}">
                            
                                <img class="loc-img-bg" alt=" " [src]="item?.media_details[0]?.thumb_url" (error)="imgeNotLoading=true"
                                    [ngClass]="imgeNotLoading ? 'dummy-img': ''"
                                    *ngIf="(!editAmgAnnotationLoader && item?.media_details?.length > 0 && item?.media_details[0]?.thumb_url !== '')"
                                    title="slide-cover-image" />
                                <div class="loader-block " *ngIf="selectedCard == i && editAmgAnnotationLoader">
                                    
                                    <ngx-skeleton-loader animation="pulse"
                                        [theme]="{width: '100%', height: '1em', 'background-color': '#393939', 'animation-duration': '1s', margin: '0'}"></ngx-skeleton-loader>
                                    <ngx-skeleton-loader animation="pulse"
                                        [theme]="{width: '75%', height: '1em', 'background-color': '#393939', 'animation-duration': '1s', margin: '0'}"></ngx-skeleton-loader>
                                       
                                </div>
                            </div>
                        <div class="location-content"
                            [ngClass]="{'hide': hideSlidesData, 'show-highlight-icon': item?.media_details?.length === 0, 'remove-margin-image-show': item?.media_details?.length !== 0}">
                            <div class="panel-box">
                                <div class="poi-block" *ngIf="item?.media_details?.length === 0">
                                    <span class="poi-numbers">{{i + 1}}</span>
                                    <span class="add-poi-icon-class-non-amg-highlights"></span>
                                </div>
                                <div class="icon-selection-style text-move-top">
                                    <div class="panel-row cursor-clickable">
                                        <span class="icon icon_poi_pin" *ngIf="item?.provider == 'MBITION'"></span>
                                        <span class="icon icon_amg_min" *ngIf="item?.provider === 'AMG'"></span>
                                        <span *ngIf="!displayPublishedDetails && (!item || !item.name)">{{'BP_LM_HIGHLIGHTS'
                                            |
                                            customTranslation:translationData}}</span>
                                        <span class="loc-title">{{i + 1}}&nbsp;&nbsp;|&nbsp;&nbsp;{{item.title ?
                                            item.title : item.name}}</span>
                                    </div>
                        
                                </div>
                                <div class="panel-row">
                                    <div class="loc-detail" title="{{item.name}}">{{item.description ? item.description:
                                        item.name}}</div>
                                    <div *ngIf="((!tourPublishStage && !displayPublishedDetails) || (editAfterPublishActive || (emotionTourTrackencodedGpsPoints && 
                                    currentStep == 'WAY_POINT_ADDED')) 
                                    && !autoAnnotate)"
                                        class="delete-slide-block">
                                         <span *ngIf="editAfterPublishActive" class="icon icon_loc_delete trash_icon"
                                            (click)="callDeleteDialogPostPublish(i, item.name, item.hasOwnProperty('id')? item.id: '')"></span>
                                         <span *ngIf="!editAfterPublishActive" class="icon icon_loc_delete trash_icon"
                                            (click)="callDeleteDialog($event, i, item.name, item.hasOwnProperty('id')? item.id: '')"></span>
                                    </div>
                                </div>
                        
                                <div class="location-description">{{item.description}}</div>
                        
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="showLoaderUntillGeoJsonCompletes && tourPublishStage">
                    <div class="add-start-location"
                        [ngClass]="{'overlay-opacity': (this.state === 'ADD_WAY_POINT' || this.state==='ADD_WAY_POINT_DETAILS'), 'background-color-change': hideSlidesData}">
                        <div class="loader-block">
                            <ngx-skeleton-loader animation="pulse"
                                [theme]="{width: '100%', height: '1em', 'background-color': '#393939', 'animation-duration': '1s', margin: '0.5em 0 0 0'}"></ngx-skeleton-loader>
                            <ngx-skeleton-loader animation="pulse"
                                [theme]="{width: '75%', height: '1em', 'background-color': '#393939', 'animation-duration': '1s', margin: '0.5em 0 0 0'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>


            <!---------------A-B tour, step 3: This code belongs to insider spot, changes to add insider spont start here-------------->
            <ng-container *ngIf="(currentStep === 'ADD_WAY_POINT' || currentStep === 'ADD_WAY_POINT_DETAILS')&& !editActive">
                <div class="cr-item">
                    <div class="start-location set-location">
                        <div class="panel-box">
                            <div class="panel-row">
                                <span class="icon icon_highlight_flag"></span>
                                <h6>{{'BP_LM_HIGHLIGHT_LBL' | customTranslation: translationData}} {{waypointsList.length + 1}}</h6>
                            </div>
                            <div class="panel-row">
                                <p>{{ helperMsgs.ALL_INSIDER_SPOTS_MSG | customTranslation:translationData}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!----------------------------------- This displays + icon to add insider point--------------------------------------------------->
            <ng-container *ngIf="showAddInsiderSpotCard()">
                <div (click)="addHighlight('ADD_WAY_POINT')" class="highlight-add-insider-point">
                    <div class="start-location" [ngClass]="showInsiderSPtMsg?'set-location':'un-set'">
                        <div class="panel-row center">
                            <span class="dealer-icon icon_add_stroke" [ngClass]="{'hide': hideSlidesData}"></span>
                            <p [ngClass]="{'hide': hideSlidesData}">{{carouselTitles.ADD_INSIDER_SPOT_TITLE | customTranslation:
                                translationData }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!----------------------------Changes to display a loader when AMG annotation service is running----------------------------------->
            <ng-container *ngIf="showLoaderUntillGeoJsonCompletes && !tourPublishStage">
                <div class="add-start-location"
                    [ngClass]="{'overlay-opacity': (this.state === 'ADD_WAY_POINT' || this.state==='ADD_WAY_POINT_DETAILS'), 'background-color-change': hideSlidesData}">
                    <div class="loader-block">
                        <ngx-skeleton-loader animation="pulse"
                            [theme]="{width: '100%', height: '1em', 'background-color': '#393939', 'animation-duration': '1s', margin: '0.5em 0 0 0'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader animation="pulse"
                            [theme]="{width: '75%', height: '1em', 'background-color': '#393939', 'animation-duration': '1s', margin: '0.5em 0 0 0'}"></ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>
            <!-----------------------------------------------Destination Related Changes start here-------------------------------------------->
            <!--------------------------Changes before adding destination------------------------->
            <ng-container
                *ngIf="(state === 'START_ADDED' && startLocationAdded) || (state === 'THIRD' && startLocationAdded) || (state === 'SECOND' && startLocationAdded)">
                <div class="cr-item" [ngClass]="{'hide': hideSlidesData}">
                    <div class="start-location highlight-border initial">
                        <div class="panel-box">
                            <div class="panel-row">
                                <span class="icon icon_dest_flag"></span>
                                <h6 *ngIf="!destinationPointData" class="loc-title">{{'BP_LM_DESTINATION' |
                                    customTranslation:translationData}}</h6>
                            </div>
                            <div class="panel-row">
                                <p>{{helperMsgs.ADD_DESTINATION_INFO_MSG| customTranslation:translationData}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-----------------------Changes post adding destination start here----------------------->
            <div class="cr-item"
                *ngIf="showDestinationCard()">
                <div *ngIf="this.state === 'ADD_WAY_POINT' || this.state==='ADD_WAY_POINT_DETAILS'" class="overlay">
                </div>
                <!-----Case 1: After Tour Publish: Hide destination details during eoJson API call, once geoJson is executed display all details----->
                <!-----Case 2: Before Tour Publish: Show destination details during AMG annotation API call------>
                <div *ngIf="!showLoaderUntillGeoJsonCompletes || (showLoaderUntillGeoJsonCompletes && !tourPublishStage)"
                    class="add-start-location"
                    [class.highlight-border]="((activeSlide && activeSlide.includes('destination')) || ((editActive && selectedCard === 'destination'))) && !destinationPointData.media"
                    (click)="handleSlideClick(destinationPointData, 'destination')"
                    [ngClass]="{'overlay-opacity': (state === 'ADD_WAY_POINT' || state === 'ADD_WAY_POINT_DETAILS')|| (editActive && selectedCard !== 'destination'), 'background-color-change': hideSlidesData, 'show-pointer-icon': displayPublishedDetails}">
                    <div class="highlight-image-class" [ngClass]="editActive && selectedCard === 'destination' && destinationPointData.media[0].url ? 'edit-active': ''">
                        <span class="loc-img-bg" [style.background-image]="'url(' + destinationPointData.media[0].url + ')'"
                            *ngIf="destinationPointData?.media" title="slide-cover-image"></span>
                    </div>
                    <div class="location-content" [ngClass]="{'hide': hideSlidesData}">
                        <div class="panel-box">
                            <div class="icon-selection-style">
                                <span class="panel-row cursor-clickable">
                                    <span
                                        [ngClass]="isRoundTrip ? 'icon icon_round_trip' :'icon icon_dest_flag'"></span>
                                    <span *ngIf="!destinationPointData" class="loc-title">{{'BP_LM_DESTINATION' |
                                        customTranslation:translationData}}</span>
                                    <span *ngIf="destinationPointData" class="loc-title">{{destinationPointData.title ? destinationPointData.title : destinationPointData.name }}</span>
                                </span>
                            </div>
                            <div class="panel-row">
                                <div class="loc-detail"
                                    title="{{destinationPointData?.description}}">
                                    {{destinationPointData?.description}}</div>
                                <div class="location-description">{{destinationPointData?.description}}</div>
                                <div *ngIf="!tourPublishStage && !displayPublishedDetails" class="delete-slide-block">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--------- This when geoJson is getting called before  publish and post generate, Comes for destination card------------------>
                    <div *ngIf="showLoaderUntillGeoJsonCompletes && tourPublishStage">
                        <div class="add-start-location"
                            [ngClass]="{'overlay-opacity': (this.state === 'ADD_WAY_POINT' || this.state==='ADD_WAY_POINT_DETAILS'), 'background-color-change': hideSlidesData}">
                            <div class="loader-block">
                                <ngx-skeleton-loader animation="pulse"
                                    [theme]="{width: '100%', height: '1em', 'background-color': '#393939', 'animation-duration': '1s', margin: '0.5em 0 0 0'}"></ngx-skeleton-loader>
                                <ngx-skeleton-loader animation="pulse"
                                    [theme]="{width: '75%', height: '1em', 'background-color': '#393939', 'animation-duration': '1s', margin: '0.5em 0 0 0'}"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Adding a dummy element so that right scroll button is visible-->
            <div class="dummy-elem" #destDiv></div>
        </div>
        <div class="carousal-back-next" *ngIf="showPrevNextButtons.showLeftArrow ||  showPrevNextButtons.showRightArrow">
            <div class="button-controls">
                <div role="button" class="prev" (click)="goToPrevSlide()" [ngClass]="{'prev-btn-dspy': showPrevNextButtons.showLeftArrow}" *ngIf="showPrevNextButtons.showLeftArrow">
                    <span class="arrow-left"></span>
                </div>
                <div role="button" class="next" (click)="goToNextSlide()" [ngClass]="{'nxt-btn-dspy': showPrevNextButtons.showRightArrow}" *ngIf="showPrevNextButtons.showRightArrow">
                    <span class="arrow-right"></span>
                </div>
            </div>
        </div>
    </div>
</div>

<!---------------------------------------Step 1: Fist card to add Start point, with start message----------------------------------------->
<ng-container *ngIf="(((state === 'SECOND' || state === 'THIRD') && !startLocationAdded)) && !hideRoundTripCard &&
((isOffroadTourFlow && emotionTourTrackencodedGpsPoints) || !isOffroadTourFlow)">
    <div class="dealer-carousel-parent">
        <div class="start-location highlight-border initial">
            <div class="panel-box">
                <div class="panel-row">
                    <span class="icon icon_start_white"></span>
                    <h6>{{ 'BP_LM_START' | customTranslation: translationData }}</h6>
                </div>
                <div class="panel-row">
                    <p>{{ helperMsgs.ADD_START_INFO_MSG | customTranslation: translationData }}</p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
