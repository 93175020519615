import {Injectable} from '@angular/core';

import {Amplify, Auth} from 'aws-amplify';
import { environment } from 'src/environments/environment';



@Injectable()

export class AuthAmplifyService {

  isUserLoggedIn = false;
  loggedInUserInfo: any = {};
  userAuthTokenData: any;
  auth = Auth;

  constructor() {
    this.config();
  }

  config() {
    Amplify.configure({
    Auth:environment.amplifyConfig
    }
      );
  }

  async init(): Promise<any>{
    this.auth.currentAuthenticatedUser().then(
      (data:any) => {
          const userData = this.getCurrentUserToken();
          if (!userData?.length) {
              this.storeCurrentSessionData();
          }
          return true;
      },
      (res:any) => {  
          console.log('it goes to can acrive error block');
          return false;
      }
  )
  }

  async storeCurrentSessionData() {
    await this.currentSession().then((res:any) => {
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken();
      let jwt_expires_in = accessToken.getExpiration();
      let refreshToken = res.getRefreshToken();
      let jwtRefreshToken = refreshToken.getToken();
      let idToken = res.getIdToken();
      let jwtIdToken = idToken.getJwtToken();
      let fullData = { "id_token": jwtIdToken, "access_token": jwt, "refresh_token": jwtRefreshToken, "expires_in": jwt_expires_in, "token_type": "Bearer" }
      this.setUserLoginStatus(true);
      this.setCurrentUserToken(fullData);
      return fullData;
      
    })
  }

  async signIn() {
    await this.auth.federatedSignIn({customProvider: 'IAMProvider'})
  } 

  async logout() {
    localStorage.removeItem('profileImage');
    localStorage.removeItem('profileName');
    localStorage.removeItem('aliasGroupName');
    localStorage.removeItem("profile");
    localStorage.removeItem("isProfileChanged");
    
    // Set Time out is added fix some edge case
    await this.auth.signOut().then((data) => {
      if (data) {        
        window.open(environment.amplifyConfig.oauth.redirectSignOut, "_self");
        localStorage.clear()
      }
    });
  }

  currentSession() {
    return this.auth.currentSession();
  }

  getUserLoginStatus() {
    return this.isUserLoggedIn;
  }

  setUserLoginStatus(status: boolean) {
    this.isUserLoggedIn = status;
  }
  
  setCurrentUserToken(data: any) {  
    this.userAuthTokenData = data;
    localStorage.setItem('Token', data.access_token);
  }

  getCurrentUserToken() {  
    return this.userAuthTokenData;
  }
}