

export class EVENTS_CONSTANTS {

    static ADD_EVENT_HASHTAG:'BP_EM_AEH'
    static BEST_RACES:'BP_EM_BEST_RACES'
    static BACK_TO_EVENT:'BP_EM_BTE'
    static CROP_COVER_IMAGE:'BP_EM_CROP_COVERIMG'
    static DELETE_IMAGE:'BP_EM_DELETE_IMAGE'
    static DOWNLOAD:'BP_EM_DOWNLOAD'

    static DOWNLOAD_ALL: 'BP_EM_DOWNLOAD_ALL'
    static DOWNLOAD_ADMIN_QR:'BP_EM_DOWNLOADADM_QR'
    static DOWNLOAD_EMPTY_EXCEL:'BP_EM_DOWNLOADEMP_EXCEL'
    static DOWNLOAD_EXCEL_TEMP:'BP_EM_DOWNLOAD_EXCEL_TEMP'
    static DOWNLOAD_QR :'BP_EM_DOWNLOAD_QR'

    static INCOMING_RACES:'BP_EM_INCOMING_RACES'
    static MANAGE_INCOMING_RACES:'BP_EM_MANAGE_INCOMING_RACES'
    static MANUALLY_ADD_PARTICIPANTS:'BP_EM_MANUALLY_ADD_PART'
    static OPERATOR_EMAIL:'BP_EM_OPERATOR_EMAIL'
    static OPERATOR_NAME:'BP_EM_OPERATOR_NAME'
    static OPERATOR_NUMBER:'BP_EM_OPERATOR_PH'
    static OR:'BP_EM_OR'
    static RANK:'BP_EM_RANK'
    static RACER_NAME:'BP_EM_RACER_NAME'
    static RENAME_EXERCISE:'BP_EM_RENAME_EXERCISE'
    static STATUS:'BP_EM_STATUS'
    static SELECT_EVENT_EXERCISES:'BP_EM_SELECT_EVENT_EXERCISES'
    static TIME_CLOCKED:'BP_EM_TIME_CLOCKED'
    static QR_CODES:'BP_EM_QR_CODES'
    static UPLOAD_PARTICIPANTS:'BP_EM_UPLOAD_PARTICIPANTS'
    static UPLOAD_PART_LIST:'BP_EM_UPLOAD_PART_LIST'
    static ALL_EVENTS:'BP_EM_ALL_EVENTS'
    static EMAILER_FAILED:'BP_EM_EMAIL_FAIL'
    static EMAILER_HOLD:'BP_EM_EMAIL_HOLD'
    static EMAILER_SENT:'BP_EM_EMAIL_SENT'
    static FAILED:'BP_EM_FAILED'
    static MANAGE_BR_BD:'BP_EM_MANAGE_BR_BD'
    static IN_PROGRESS:'BP_EM_BR_IP'
    static RACE_DATA_UPLOADED:'BP_EM_RACE_DATA_UPLOAD'
    static RACE_DATA_UPLOAD_FAIL:'BP_EM_RACE_DATA_UPLOAD_FAIL'
    static RACE_DATA_UPLOAD_HOLD:'BP_EM_RACE_DATA_UPLOAD_HOLD'
    static RACE_VIDEO_UPLOADED:'BP_EM_RACE_VIDEO_UPLOAD'
    static RACE_VIDEO_UPLOAD_FAIL:'BP_EM_RACE_VIDEO_UPLOAD_FAIL'
    static RACE_VIDEO_UPLOAD_HOLD:'BP_EM_RACE_VIDEO_UPLOAD_HOLD'

    static SEARCH_RACER:'BP_EM_SEARCH_RACER'
    static OPERATOR_TEXT:'BP_EM_OPERATOR_TEXT'
    
    static DRAG_DROP:'BP_EM_DRAG_DROP'
    static EDIT_EVENT:'BP_EM_EDIT_EVENT'
    static ERROR_LARGE_IMAGE:'BP_EM_ERR_LARGEIMG'
    static ERROR_SMALL_IMAGE:'BP_EM_ERR_SMALLIMG'
    static PARTICPANT_ADD_EDIT_DOWN:'BP_EM_PARTICIPANT_AED'
    static ADD_EVENT_INFO:'BP_ADD_EVENT_INFO'
    static ALL:'BP_EM_All'
    static ADD_PARTICIPANT:'BP_EM_ADD_PARTICIPANT'
    static ADD_PARTICIPANTS:'BP_EM_ADD_PARTICIPANTS'

    static DOWNLOAD_PART_QR:'BP_EM_DOWNLOAD_PART_QR'
    static DOWNLOAD_PART_QR_CODES:'BP_EM_DOWNLOAD_PART_QR_CODES'
    static ENTER_EVENT_HASHTAG:'BP_EM__ENTER_EVENT_HASHTAG'
    static BP_EM_EVENT_ESVU:'BP_EM_EVENT_ESVU'
    static CHANGE_EX_NAME:'BP_EM_CHANGE_EX_NAME'
    static ENTER_SHORT_URL:'BP_EM_ENTER_SHORT_URL'

    static EXERCISE_FLEADERBOARD:'BP_EM_EXERCISE_FLEADERBOARD'
    static EXERCISE_LEADERBOARD_DETAILS:'BP_EM_EXERCISE_LEADERBOARD_DETAILS'
    static CIRCUIT_LAYOUT_DETAILS:'BP_EM_CIRCUIT_LAYOUT_DETAILS'
    static LEADERBOARD_LINKS:'BP_EM_LEADERBOARD_LINKS'
    static LIVE_LEADRBOARD:'BP_EM_LIVE_LEADRBOARD'
    static MOBILE_RANKING:'BP_EM_MOBILE_RANKING'
    static SEARCH_CIRCUIT:'BP_EM_SEARCH_CIRCUIT'
    static SEARCH_HERE:'BP_EM_SEARCH_HERE'

    static UPDATE_SCORE:'BP_EM_UPDATE_SCORE'
    static SCORE:'BP_EM_SCORE'
    
    static RACES_JUST_NOW:'BP_EM_RACES_JUST_NOW'
    static RACES_MIN_AGO:'BP_EM_RACES_MIN_AGO'
    static RACES_YESTERDAY:'BP_EM_RACES_YESTERDAY'
    static REMOVE_EXERCISE:'BP_EM_REMOVE_EXERCISE'
    static SEARCH_PART_NAME:'BP_EM_SEARCH_PART_NAME'
    static BEST_TIME:'BP_EM_BEST_TIME'

    static CONSISTANCY:'BP_EM_CONSISTANCY'
    static EFFICIENCY:'BP_EM_EFFICIENCY'
    static EXERCISE_NOT_DELETED:'BP_EM_EXERCISE_NOT_DELETED'
    static LIVE_EVENTS:'BP_EM_LIVE_EVENTS'
    static BP_EM_PARTICIPANT:'BP_EM_PARTICIPANT'

    static PARTICIPANTS_ENTER_EMAIL:'BP_EM_PARTICIPANTS_ENTER_EMAIL'
    static PARTICIPANTS_ENTER_FNAME:'BP_EM_PARTICIPANTS_ENTER_FNAME'
    static PARTICIPANTS_ENTER_LNAME:'BP_EM_PARTICIPANTS_ENTER_LNAME'
    static PARTICIPANTS_FIRST_NAME:'BP_EM_PARTICIPANTS_FIRST_NAME'
    static PARTICIPANTS_LAST_NAME:'BP_EM_PARTICIPANTS_LAST_NAME'
    static PARTICIPANTS_NOT_AVAILABLE:'BP_EM_PARTICIPANTS_NOT_AVAILABLE'
    static PARTICIPANTS_REFRESH:'BP_EM_PARTICIPANTS_REFRESH'
    static PARTICIPANTS_SCREEN_SUBTITLE:'BP_EM_PARTICIPANTS_SCREEN_SUBTITLE'
    static PARTICIPANTS_SEARCH_PLACEHOLDER:'BP_EM_PARTICIPANTS_SEARCH_PLACEHOLDER'
    static PARTICIPANTS_SLNO:'BP_EM_PARTICIPANTS_SLNO'

    static PARTICIPANT_GROUPS:'BP_EM_PARTICIPANT_GROUPS'
    static UPLOAD_EXCEL:'BP_EM_UPLOAD_EXCEL'
    static ACTIONS:'BP_EM_ACTIONS'
    static DELETE_PARTICIPANTS:'BP_EM_DELETE_PARTICIPANTS'
    static DOWNLOAD_PARTICIPANTS:'BP_EM_DOWNLOAD_PARTICIPANTS'
    static LANGUAGE:'BP_EM_LANGUAGE'
    static LAST_UPDATED:'BP_EM_LAST_UPDATED'
    static LOCAL_LANGUAGE:'BP_EM_LOCAL_LANGUAGE'

    static NO_PARTICIPANTS:'BP_EM_NO_PARTICIPANTS'
    static SEARCH_RESULTS:'BP_EM_SEARCH_RESULTS'
    static PARTICIPANTS:'BP_EM_PARTICIPANTS'
    static BROWSE_EVENT:'BP_EM_BROWSE_EVENT'
   
    static NAME:'BP_EM_NAME'
    static ADD_PENALTY:'BP_EM_ADD_PENALTY'
    static CHANGE_IMAGE:'BP_EM_CHANGE_IMAGE'
    static END_DATE:'BP_EM_END_DATE'
    static START_DATE:'BP_EM_START_DATE'


    static COVER_IMAGE:'BP_EM_COVER_IMAGE'
    static DRAG_DROP_CIRCUIT_IMAGE:'BP_EM_DRAG_DROP_CIRCUIT_IMAGE'
    static UPLOAD_EVENT_LOGO:'BP_EM_UPLOAD_EVENT_LOGO'
    static ABOUT_EVENT:'BP_EM_ABOUT_EVENT'

    static ADD_FIRST_EXERCISE:'BP_EM_ADD_FIRST_EXERCISE'
    static CREATE_GROUP:'BP_EM_CREATE_GROUP'
    static CROP_EVENT_IMAGE:'BP_EM_CROP_EVENT_IMAGE'
    static DELETE_EVENT:'BP_EM_DELETE_EVENT'
    static ADMIN_QR:'BP_EM_ADMIN_QR'
    static DOWNLOAD_QR_CODES:'BP_EM_DOWNLOAD_QR_CODES'

    static EDIT_PENALTY:'BP_EM_EDIT_PENALTY'
    static EDIT_PENALTY_FOR:'BP_EM_EDIT_PENALTY_FOR'
    static EVENT_EXERCISE:'BP_EM_EVENT_EXERCISE'
    static GENERATING_ADMIN_QR:'BP_EM_GENERATING_ADMIN_QR'
    static EXERCISE_LEADERBOARD:'BP_EM_SET_EXERCISE_LEADERBOARD'
    static EXERCISE:'BP_EM_EXERCISE'
    static FINAL_TIME:'BP_EM_FINAL_TIME'
    static GENERATE_QR:'BP_EM_GENERATE_QR'

    static VIEW_BEST_RACES:'BP_EM_VIEW_BEST_RACES'
    static UPLOAD_IMAGE:'BP_EM_UPLOAD_IMAGE'
    static IMAGE_LARGE:'BP_EM_IMG_LARGE'
    static STAY_ON_PAGE:'BP_EM_STAY_ON_PAGE'
    static CHANGES_WONT_SAVE:'BP_EM_CHANGES_WONT_SAVE'
    static LEAVE_PAGE:'BP_EM_LEAVE_PAGE'
    static UPLOAD_FAILED:'BP_EM_UPLOAD_FAILED'
    static TRY_AGAIN:'BP_EM_TRY_AGAIN'
    static WANT_TO_LEAVE_PAGE:'BP_EM_WANT_TO_LEAVE_PAGE'

    static LIVE_RACES:'BP_EM_LIVE_RACES'
    
    static NO_PARTICPANTS:'BP_EM_NO_PARTICPANTS'
    static PENALTY_ADDED:'BP_EM_PENALTY_ADDED'
    static ADD_PENALTY_FOR:'BP_EM_ADD_PENALTY_FOR'
    static PRINT:'BP_EM_PRINT'

    static RACES_UPDATED:'BP_EM_RACES_UPDATED'
    static CHECK_NEW_RACES:'BP_EM_CHECK_NEW_RACES'
    static SEARCH_EVENTS:'BP_EM_SEARCH_EVENTS'
    
    static EVENT_LABELS = {
        EVENT_LOGO:'BP_EM_EVENT_LOGO',
        EVENT_NAME:'BP_EM_EVENT_NAME'
    }

    static DROP_DOWN_LABELS = {
        NAME_AZ:'BP_EM_NAME_AZ',
        NEW_OLD:'BP_EM_NEW_OLD',
        OLD_NEW:'BP_EM_OLD_NEW',
        BP_EM_NAME_AZ:'BP_EM_NAME_AZ',
        EVENT_TYPES:'BP_EM_EVENT_TYPES',
        SORT_BY:'BP_EM_SORT_BY'
    }

    static BUTTON_LABELS={
         MANAGE:'BP_EM_MANAGE_BTN_LBL',
         ADD_SCORE: 'BP_EM_ADD_SCORE',
         GROUP:'BP_EM_GROUP_BTN_LBL',
         ADD:'BP_EM_ADD_BTN_LBL',
         SUBMIT:'BP_EM_SUBMIT_BTN_LBL',
         UPLOAD:'BP_EM_UPLOAD_BTN_LBL',
         MANAGE_EVENT:'BP_EM_MANAGE_EVENT',
         MANAGE_EVENT_RELATED_DETAILS:'BP_EM_MANAGE_EVENT_RELATED_DETAILS',
         CREATE_EVENT:'BP_EM_CREATE_EVENT',
         CREATE_NEW_EVENT:'BP_EM_CREATE_NEW_EVENT',
         MANAGE_PARTICIPANTS:'BP_EM_MANAGE_PARTICIPANTS',
         CREATE:'BP_EM_CREATE'
    }

    static ERROR_MESSAGES={
        PAST_EVENT_MODIFY:'BP_EM_PAST_EVENT_MODIFY',
        DELERE_EVENT_RACES:'BP_EM_DELERE_EVENT_RACES',
        EXERCISE_EXIST:'BP_EM_EXERCISE_EXIST',
        PAST_EXERCISE_UPDATE:'BP_EM_PAST_EXERCISE_UPDATE',
        PARTICIPANT_EXIST:'BP_EM_ERROR_PARTICIPANT_EXIST',
        DELETE_PARTICIPANT:'BP_EM_ERROR_DELETE_PARTICIPANT',
        AUTHORIZATION:'BP_EM_ERROR_AUTHORIZATION',
        EMAIL_ALREADY_EXISTS:'BP_EM_EMAIL_ALREADY_EXISTS',
        RACE_FOUND:'BP_EM_ERROR_RACE_FOUND',
        SCORE_MISMATCH:'BP_EM_ERROR_SCORE_MISMATCH'
    }

    static ALERT_POPUP_TITLES={
        CONFIRM_DELETE_EVENT_TITLE:'BP_EM_CONFIRM_DELETE_EVENT_TITLE',
        CONFIRM_DELETE_EXERCISE_TITLE:'BP_EM_CONFIRM_DELETE_EXERCISE_TITLE',
        CONFIRM_DELETE_RACE_TITLE: 'BP_EM_CONFIRM_DELETE'
    }
    
    static ALERT_POPUP_MESSAGES={
        DELETE_EVENT_CONFIRM_MSG: 'BP_EM_DLT_EVENT_CONFIRM_MSG',
        DELETE_EXERCISE_CONFIRM_MSG: "BP_EM_DLT_EXERCISE_CONFIRM_MSG",
        DELETE_RACE_CONFIRM_MSG: 'BP_EM_DLT_RACE_CONFIRM_MSG'
    }
}