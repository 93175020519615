import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output  } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModalService } from 'src/app/modules/events/services/common-modal.service';
import { TranslationService } from '../service/translation.service';


@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit, OnDestroy {

  headerTitle: string = '';
  isCloseButtonClicked: boolean = false;
  isSaveButtonClicked: boolean = false;
  isCloseButtonVisible: boolean = false;
  isSaveButtonVisible: boolean = false;
  
  @Input()
  isCloseModal: boolean | undefined;

  @Input()
  closeButtonTitle: string = '';

  @Input()
  saveButtonTitle: string = '';

  @Input()
  showModalContent: any = '';

  @Input()
  doneButtonDisabled:any;

  @Input()
  templateBtn:boolean | undefined = true;

  @Input()
  component:string | undefined

  @Input()
  isHeaderVisible:boolean | undefined

  @Output()
  closeModalEmitter =  new EventEmitter<boolean>();

  @Output()
  saveModalEmitter =  new EventEmitter<boolean>();

  @Input()
  type:any

  @Input()
  isFooterVisible:any

  @Input()
  isCloseBtnVisible:boolean=false


  subscription: Subscription = new Subscription();
  translationData: any;

  constructor(@Inject(CommonModalService) private commonModalService: CommonModalService,
  private _translationService: TranslationService) { }

  
  ngOnChanges() {
    console.log(this.templateBtn, 'templateBtn');
  }

  ngOnInit(): void {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    let subscription1 = this.commonModalService._modalHeaderTitle.subscribe((data) => {
      this.headerTitle = data;
    });

    let subscription2 = this.commonModalService._modalHeaderIsCloseBtnVisible.subscribe((data) => {
      this.isCloseButtonVisible = data;
    })

    let subscription3 = this.commonModalService._modalHeaderIsSaveBtnVisible.subscribe((data) => {
      this.isSaveButtonVisible = data;
    })

    this.subscription.add(subscription1);
    this.subscription.add(subscription2);
    this.subscription.add(subscription3);
  }

  close() {
    this.closeModalEmitter.emit(true);
  }
  
  save() {
    this.saveModalEmitter.emit(true);
  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
