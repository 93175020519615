import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { SharingDataService } from 'src/app/shared/service/sharing-data.service';
import {Subscription} from 'rxjs'
import { TranslationService } from 'src/app/shared/service/translation.service';
@Component({
  selector: 'app-group-section',
  templateUrl: './group-section.component.html',
  styleUrls: ['./group-section.component.scss']
})
export class GroupSectionComponent implements OnInit, OnDestroy {
  // Feature flags
  public enableManageBusiness: boolean = false;
  public enableManageFleet: boolean = false;

  constructor(
    private route:Router,
    private dataSharing:SharingDataService,
    private cdr: ChangeDetectorRef,
    private translationService:TranslationService
  ) { }

  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
  public viewContainer: ViewContainerRef | undefined
  groupName:any
  profileswitchselected:boolean=false
  subscriptions:Subscription[]=[]
  translationData:any
  profileImage:any
  
  ngOnInit(): void {
    this.subscriptions.push(this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
        this.enableManageBusiness = this.translationService.featureFlags.profile.enableManageBusiness;
        this.enableManageFleet = this.translationService.featureFlags.profile.enableManageFleet;
      }));
      this.dataSharing.getProfileChanged().subscribe(()=>{
      this.groupName=localStorage.getItem('profileName')
      this.profileImage=localStorage.getItem('profileImage')
      })
    this.groupName=localStorage.getItem('profileName')
    this.profileImage=localStorage.getItem('profileImage')
   }

  
  manageBusiness(){
   this.dataSharing.setMobileMenuStatus(false);
   this.route.navigate(['profile/member-list'])
   this.dataSharing.setProfileDetailsClicked(true)
  }
  switchProfile(event:any){
    event.stopPropagation();
    this.profileswitchselected=true
    this.dataSharing.setProfileSwitched(true)
    this.cdr.detectChanges()
    localStorage.setItem("isProfileChanged",'true')
  }

  public myFleets(): void {
    this.dataSharing.setMobileMenuStatus(false);
    this.route.navigate(['profile/fleet'])
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub)=>sub.unsubscribe())
  }
}
