import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent {

  @Input()
  imageChangedEvent: any;
  @Input()
  maintainAspectRatio:any;
  @Input()
  aspectRatio:any;
  @Input()
  containWithinAspectRatio:any;
  @Input()
  cropperMinWidth:any;
  @Input()
  cropperMinHeight:any;
  @Input()
  resizeToWidth:any;
  @Input()
  resizeToHeight:any;
  @Output()
  croppedImage = new EventEmitter<any>();
  @Output()
  loadedImage = new EventEmitter<any>();
  @Output()
  failedImage = new EventEmitter<any>();
  
  


 

  imageCropped(event:any){
    this.croppedImage.emit(event);
  }
  imageLoaded(event:any){
    this.loadedImage.emit(event);
  }
  loadImageFailed(){
    this.failedImage.emit();
  }

}
