<div class="create-drop-down">
    <div (click)="openDropdown()" class="create-new new">
        <span class="icon icon_add_item"></span>
        <p>{{locationConstants.HELPER_INPUT_LABELS.NEW| customTranslation: translationData | titlecase}}</p>
        <div (click)="openDropdown()" *ngIf="isDropdownOpen" class="overlay"></div>
    </div>
   
    <div (click)="sendSelectedCreateOption('CIRCUIT')" *ngIf="isDropdownOpen && enableCircuitFeature" class="create-new new-circuit">
        <span class="icon icon_track"></span>
        <p>{{locationConstants.BUTTON_NAMES.CIRCUITS| customTranslation: translationData | titlecase }}</p>
    </div>
    <div *ngIf="isDropdownOpen && enableEmotionTourFeature" (click)="sendSelectedCreateOption('EMOTION_TOUR')" class="create-new new-emotion-tour">
        <span class="icon icon_dealer_flow"></span>
        <p>{{locationConstants.BUTTON_NAMES.EMOTION_TOUR | customTranslation: translationData | titlecase}}</p>
    </div>
    <div  *ngIf="isDropdownOpen && enableoffroadfeature" (click)="sendSelectedCreateOption('OFFROAD_TOUR')" class="create-new new-offroad">
        <span class="icon icon_off_road"></span>
        <p >{{locationConstants.BUTTON_NAMES.OFFROAD | customTranslation: translationData}}</p>
    </div>
    </div>