import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ETOUR_CREATION_STEPS, RecentSearch } from '../../interfaces/business-tour-create.interface';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';

@Injectable({
  providedIn: 'root'
})
export class MediaCarouselService {
  
  public openModal = new BehaviorSubject<boolean>(false);
  public scrollToSelectedIndex = new BehaviorSubject<Object>({});
  public deleteSelectedMedia = new BehaviorSubject<any>([]);
  public callGetTrackDetails = new BehaviorSubject<boolean>(false);
  public shareMediaUrls = new BehaviorSubject<any>([]);
  public showAddButton= new BehaviorSubject<Object>({});
  public CancelCurrentStep = new BehaviorSubject<string>(ETOUR_CREATION_STEPS.SECOND);
  public highlightCount = new BehaviorSubject<number>(0);
  public hideContentOnLoadersPage = new BehaviorSubject<boolean>(false); 
  public startLocationButtonClicked = new BehaviorSubject<boolean>(false);
  public showPlusIconOnMapHover = new BehaviorSubject<boolean>(true);
  public waypointAdd = new BehaviorSubject<boolean>(false);
  public deleteAnnotationRedrawMap = new BehaviorSubject<any>('');
  private _highlights: string[] = [];
  private _annotationIds: string[] = [];
  private _isRoundTripSelected: boolean = false;
  private _viewInsdrSptDetailsSubject$ = new BehaviorSubject<boolean>(false);
  private _modalActionSubject$ = new BehaviorSubject<string>('');
  private _insiderSptDetails: any;
  private _recentSearch: RecentSearch[] = [];

  /**
   * setter and getter that will have list of highlights incl start and destination
   * We can set, get and reset these values and communicate b/w diff components
   */
  public set highlightList(highlights: string[]) {
    this._highlights = [...this._highlights, ...highlights];
  }

  public get highlightList(): string[] {
    return this._highlights;
  }

  public set annotationIds(annotationIds: string[]) {
    this._annotationIds = [...this._annotationIds, ...annotationIds]
  }

  public get annotationIds(): string[] {
    return this._annotationIds;
  }

  public set isRoundTripSelected(roundTrip: boolean) {
    this._isRoundTripSelected = roundTrip;
  }

  public get isRoundTripSelected(): boolean {
    return this._isRoundTripSelected;
  }

  public set decideModalAction(action: string) {
    this._modalActionSubject$.next(action);
  }
  
  public get decideModalAction(): any {
    return this._modalActionSubject$.asObservable();
  }

  public set viewSelectedCardDetails(action: boolean) {
    this._viewInsdrSptDetailsSubject$.next(action);
  }
  
  public get viewSelectedCardDetails(): any {
    return this._viewInsdrSptDetailsSubject$.asObservable();
  }


  public set insiderSptDetails(details: any) {
    this._insiderSptDetails = details;
  }

  public get insiderSptDetails(): any {
    return this._insiderSptDetails;
  }

  /**
   * @description setter and getter for recent searches. We will display top 5 recent searches to the user.
   * However, we store 10 most recent searches. These search results will be present as long as the user session is present
   */
  public set recentSearchHistory(recentSearch: RecentSearch) {
    this._recentSearch.unshift(recentSearch);
    if (this._recentSearch.length > LOCATIONS_CONSTANTS.MAX_SEARCH_RESULTS_TO_STORE) {
      this._recentSearch.pop();
    }
    sessionStorage.setItem('Recent_Search', JSON.stringify(this._recentSearch));
  }

  public get recentSearchHistory(): RecentSearch[] | any {
    const search = sessionStorage.getItem('Recent_Search');
    this._recentSearch = search ? JSON.parse(search): [];
    return this._recentSearch;
  }

}
