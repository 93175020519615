
<ng-container *ngIf="!loader">
  <div class="card-list-main">
    <div *ngIf="!loader" class="add-card car" role="button" (click)="addCar()">
      <img [src]="defaultCarImage" alt="" class="img" />
      <p class="card-para">{{(carList?.length?'BP_PM_CAR_FLEET':'BP_PM_FLEET_EMPTY') | customTranslation : translationData}}</p>
      <div class="button-region">
        <button (click)="addCar()" class="beta">
          <span class="icon icon_add"></span>
          <span>{{'BP_PM_CAR'| customTranslation:translationData }}</span>
        </button>
      </div>
    </div>
    <ng-container *ngFor="let item of carList; let i = index">
      <div class="card card-section" (mouseover)="hoverOnCard(i,$event)" (mouseleave)="mouseOutFromCard(i,$event)">
      <div [ngClass]="cardHovered && showIndex==i?'bg-blur':''">
        <div class="img">
          <img [src]="item.carImageUrl?item.carImageUrl:car" alt="" />
        </div> 
        <div class="fleet-info-box">
          <p class="car-model">{{(item.carName ? item.carName : 'BP_PM_MODEL_DETAILS_UNAVALIABLE') | customTranslation : translationData}}</p>
          <div class="vin-kebab">
          <p class="vin-number">{{'BP_PM_VVIN' | customTranslation:translationData }} : {{item.vin}}</p>    
          </div>
          <div class="kebab-license">
            <p class="license-number">{{'BP_PM_LICNO'| customTranslation:translationData }}: {{item.licensePlate}}</p>
          </div>
        </div>
       
      </div>
      <div *ngIf="cardHovered && showIndex==i" class="edit-delete">
        <div class="button-region">
          <button disabled (click)="editCar(item)" class="delta"><span>{{buttonNames.EDIT | customTranslation:translationData}}</span></button>
        </div>
        <div (click)="deleteFleet(item.vin,i)" class="button-region">
          <button class="delta"><span>{{buttonNames.DELETE |customTranslation:translationData }}</span></button>
        </div>
    </div>
     </div>

    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="loader">
  <div class="card-list-main">
      <div class="card">
        <div class="img">
          <ngx-skeleton-loader class="car-model" animation="pulse"  [theme]="{width: '100%', height: '100%','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader>
        </div>
        <div class="fleet-info-box">
        <p class="car-model"><ngx-skeleton-loader animation="pulse"  [theme]="{width: '20em', height: '0.75em','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader></p>
        <p class="vin-number"><ngx-skeleton-loader animation="pulse"  [theme]="{width: '20em', height: '0.75em','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader>
        </p> 
        <p class="lic-number"><ngx-skeleton-loader animation="pulse"  [theme]="{width: '15em', height: '0.75em','background-color': '#4b4b4b', 'animation-duration': '1s' }"></ngx-skeleton-loader>
        </p>  
      </div>     
      </div>
  </div>
</ng-container>

<ng-container *ngIf="addCarClicked || editCarClicked">
  <app-add-car [editCarDetails]="editCarDetails" (cancelClicked)="cancelClicked($event)"></app-add-car>
</ng-container>

<app-common-alert-modal [isModalOpen]=isDeleteModalOpen [title]="title" [actionType]="actionType"
        (isModalResponseClicked)="userAction($event)" [message]="message" [cancelBtnTitle]="cancelBtnTitle"
[actionBtnTitle]="actionBtnTitle" [placeholderObject]="placeholderObject"></app-common-alert-modal>