import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonModalService {

  _modalHeaderTitle = new BehaviorSubject<any>(null);
  modalHeaderTitle$ = this._modalHeaderTitle.asObservable();

  _modalHeaderIsCloseBtnVisible = new BehaviorSubject<any>(false);
  modalHeaderIsCloseBtnVisible$ = this._modalHeaderIsCloseBtnVisible.asObservable();

  _modalHeaderIsSaveBtnVisible = new BehaviorSubject<any>(false);
  modalHeaderIsSaveBtnVisible$ = this._modalHeaderIsSaveBtnVisible.asObservable();

  _addParticipantModalStream = new BehaviorSubject<any>(false);
  addParticipantModalStream$ = this._addParticipantModalStream.asObservable();

  _editParticipantModalStream = new BehaviorSubject<any>(false);
  editParticipantModalStream$ = this._editParticipantModalStream.asObservable();
  
  _createGroupModalStream = new BehaviorSubject<any>(false);
  createGroupModalStream$ = this._createGroupModalStream.asObservable();

  _participantQrModalStream = new BehaviorSubject<any>(false);
  participantQrModalStream$ = this._participantQrModalStream.asObservable();


  updateHeaderTitle(title: string) {
    this._modalHeaderTitle.next(title);
  }

  updateCloseBtnVisiblity(isVisible: boolean) {
    this._modalHeaderIsCloseBtnVisible.next(isVisible);
  }

  updateSaveBtnVisiblity(isVisible: boolean) {
    this._modalHeaderIsSaveBtnVisible.next(isVisible);
  }

  triggerAddParticipantModal(data: any) {
    this._addParticipantModalStream.next(data);
  }

  triggerEditParticipantModal(data: any) {
    this._editParticipantModalStream.next(data);
  }

  triggerCreateGroupModal(data: boolean) {
    this._createGroupModalStream.next(data);
  }

  triggerParticipantQrModal(data:boolean){
    this._participantQrModalStream.next(data);
  }
}
