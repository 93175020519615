import { LOCATIONS_CONSTANTS } from './../../interfaces/locations-constants';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { LocationsHttpService } from "../../services/locations-http.service";
import { MediaCarouselService } from "../../services/media/media-carousel.service";
import { DbService } from "../blob-image/image.db";
import { APP_CONSTANTS } from 'src/app/shared/constants/constants';
import { TranslationService } from 'src/app/shared/service/translation.service';

@Component({
  selector: "app-media-upload",
  templateUrl: "./media-upload.component.html",
  styleUrls: ["./media-upload.component.scss"],
})
export class MediaUploadComponent implements OnInit, OnChanges {
  @Input() trackMedia: any[] = [];
  @Input() uploadEnable: boolean = false;
  @Input() totalMediaCount: any;
  @Input() trackName: string = "";
  @Input() isFromPage: string = "";
  @Input() getSelectedMediaIndex: any;
  @Input() isShowGallery: boolean = false;
  @Input() trackNameGuid:any;

  public imageUrls: any = [];
  public vedioUrls: any = [];
  public mediaUrls: any = [];
  public selectedMedia: any = [];
  public mediaFiles: any = [];
  public numberOfFiles: number = 0;
  public showMediaCount: boolean = true;
  selectedImgIndex: number = 0;
  public isuploadDisabled: boolean = true;
  thumbnailUrls: any = [];
  showCheckBox: boolean = false;
  indexOfSelectedDiv: number = 0;
  checkBoxSelected: boolean = false;
  allSelectedMedia: any = [];
  deleteMediaObj: any;
  allCheckedMedia: any[] = [];
  subscribeShareMediaUrl: any;
  trackGuid: any;
  isMediaUpload:boolean=false;
  translationData: any;
  constructor(
    private locationsHttpService: LocationsHttpService,
    private toastService: ToastService,
    private mediaCarouselService: MediaCarouselService,
    private db: DbService,
    private _translationService: TranslationService
  ) {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );
  }

  ngOnInit(): void {
    this.subscribeShareMediaUrl =
      this.mediaCarouselService.shareMediaUrls.subscribe((value: any) => {
        this.trackMedia = [];
        this.mediaFiles = [];
        this.trackMedia = value;
        this.mediaUrls = this.trackMedia;
        this.totalMediaCount = value.length;
        this.thumbnailUrls = this.trackMedia;
        this.allSelectedMedia = [];
      });
    
    this.trackGuid = this.trackMedia[0]?.guid?this.trackMedia[0].guid :this.trackNameGuid;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isuploadDisabled = !this.uploadEnable;
    if (this.isFromPage) {
      if (this.isFromPage.toLowerCase() === "mediagallery") {
        this.showMediaCount = false;
      }
      this.mediaCarouselService.scrollToSelectedIndex.subscribe(
        (value: any) => {
          this.selectedImgIndex = value.selectedMediaIndex;
        }
      );
    }

    if (changes.getSelectedMediaIndex &&
      changes.getSelectedMediaIndex.previousValue !==
      changes.getSelectedMediaIndex.currentValue
    ) 
    if (this.isuploadDisabled) {
      this.imageUrls = [];
      this.vedioUrls = [];
      this.mediaFiles = [];
      
    }
  }

  fileChange(event: Event): void {
    this.allSelectedMedia = [];
    this.selectedMedia = (<HTMLInputElement>event?.target).files;
    if ( this.selectedMedia?.[0]) {
      this.numberOfFiles = this.selectedMedia.length;
      
      if (this.checkFilesValidation(this.numberOfFiles)) {
        for (let i = 0; i < this.numberOfFiles; i++) {
          this.mediaFiles.push(this.selectedMedia[i]);
          const reader = new FileReader();
          this.selectedImgIndex = -1;
          this.mediaCarouselService.scrollToSelectedIndex.next({
            selectedMediaIndex: this.selectedImgIndex,
          });
          reader.readAsDataURL(this.selectedMedia[i]);
        }
        this.locationsHttpService.sendMediaValue(this.mediaFiles);
        this.updateMedia(this.mediaFiles);
        this.mediaFiles = [];
      }
    }
    
  }

  /**
   * @description called once image is uploaded successfully
   * @function updateMedia
   * @param mediaFiles 
   */
  updateMedia(mediaFiles: any) {
    let userId = "Admin";
    let data = { guid: this.trackGuid };
    if (this.mediaFiles.length) {
      this.isMediaUpload=true;
      this.locationsHttpService
        .UpdateTrackDetails(userId, data, mediaFiles)
        .subscribe({
          next: (res: any) => {
            if (res.message) {
              this.locationsHttpService.clearMediaValue();
              this.locationsHttpService.mediaContent.next([]);
              this.mediaFiles=[];
              this.getTrackDetails();
              this.isMediaUpload=false;
              this.toastService.show(
                ToastType.Success,
                LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.TRACK_UPDATE_SUCCESS_MSG,
                "8%",
                "45%"
              );
              setTimeout(() => {
                this.toastService.hide();
              }, APP_CONSTANTS.MIN_TIME_TOASTER);
            }
          },

          error: (error: any) => {
            this.isMediaUpload=false;
            if (error.status == 0) {
              this.toastService.show(
                ToastType.Error,
                "Cannot upload file as media length exceeds",
                "8%",
                "41%"
              );
              
            } else {
              this.toastService.show(
                ToastType.Error,
                error["error"]["error-message"],
                "8%",
                "41%"
              );
              this.mediaFiles=[];
            }
            setTimeout(() => {
              this.toastService.hide();
            }, APP_CONSTANTS.MIN_TIME_TOASTER);
          },
          
        });
    }
  }
  
  /**
   * @function checkFilesValidation
   * @param numberOfFiles 
   * @returns boolean
   * @description called when file is uploaded from device, check it's validaity
   */
  checkFilesValidation(numberOfFiles: number): boolean {
    let isValidFiles: boolean = true;
    for (let i = 0; i < numberOfFiles; i++) {
      const fileExtension: string[] = this.selectedMedia[i].name.split(".");
      const extension: string = fileExtension[fileExtension.length - 1];
      if (!LOCATIONS_CONSTANTS.ALLOWED_EXTENTIONS_IMG_UPLOAD.includes(extension)) {
        this.toastService.show(
          ToastType.Error,
          "Please upload a file in .jpeg, .jpg, .png , .gif or .mp4 format ",
          "8%",
          "40%"
        );
        setTimeout(() => {
          this.toastService.hide();
        }, APP_CONSTANTS.MIN_TIME_TOASTER);
        isValidFiles = false;
      }
    }
    return isValidFiles;
  }

  /**
   * @function blobImageClick
   * @returns void
   * @param index - number
   * @param preview - Object with count, guid, id, type...
   * @description called when image is clicked in popup
   */
  public blobImageClick(index: number, preview: any): void {
    this.selectedImgIndex = index;
    this.mediaCarouselService.scrollToSelectedIndex.next({
      selectedMediaIndex: this.selectedImgIndex,
    });
    if (!this.allCheckedMedia.length) {
      this.allSelectedMedia = [];

      if (this.allSelectedMedia.indexOf(preview) === -1) {
        this.allSelectedMedia.push(preview.id);
      }
      this.deleteMediaObj = {
        deleteMediaIds: this.allSelectedMedia,
        guid: preview.guid,
      };
      this.allSelectedMedia = [];
      this.mediaCarouselService.deleteSelectedMedia.next(this.deleteMediaObj);
    }
  
      this.mediaCarouselService.openModal.next(true);
   
  }

  mouseEnter(index: number) {
    this.showCheckBox = true;
    this.indexOfSelectedDiv = index;
  }
  mouseLeave() {
    this.showCheckBox = false;
  }
  getOrginalUrls() {
    return this.mediaUrls.map((media: any) => {
      return media;
    });
  }

  /**
   * @description called to select/unselect checkbox and handle checked events
   * @param event 
   * @param selectedMedia 
   */
  toggleEditable(event: Event, selectedMedia: any) {
    
    this.mediaCarouselService.deleteSelectedMedia.subscribe((value: any) => {
      
      if (!value) {
        this.allCheckedMedia = [];
      }
    });
    selectedMedia.isMediaSelected = !selectedMedia.isMediaSelected;
    if ((<HTMLInputElement>event?.target).checked) {
      this.checkBoxSelected = true;

      if (this.allCheckedMedia.indexOf(selectedMedia) === -1) {
        this.allCheckedMedia.push(selectedMedia.id);
      }
      this.deleteMediaObj = {
        deleteMediaIds: this.allCheckedMedia,
        guid: selectedMedia.guid,
        checked: true,
      };
      this.mediaCarouselService.deleteSelectedMedia.next(this.deleteMediaObj);
    } else {
      if (this.allCheckedMedia.indexOf(selectedMedia.id) > -1) {
        this.allCheckedMedia.splice(
          this.allCheckedMedia.indexOf(selectedMedia.id),
          1
        );
        this.deleteMediaObj = {
          deleteMediaIds: this.allCheckedMedia,
          guid: selectedMedia.guid,
          checked: true,
        };
        this.mediaCarouselService.deleteSelectedMedia.next(this.deleteMediaObj);
      }
    }
  }

  /**
   * @function showGallery
   * @returns void
   * @description called when '+' icon is called in Add Media
   */
  showGallery(): void {
    if (this.isFromPage.toLowerCase() !== "mediagallery") {
      if (!this.isuploadDisabled) {
        this.mediaCarouselService.openModal.next(true);
        this.mediaCarouselService.showAddButton.next({
          showAddButton: false,
          mode: "edit",
          from: "trackview",
        });
      } else {
        this.mediaCarouselService.showAddButton.next({
          showAddButton: true,
          mode: "edit",
          from: "trackedit",
        });
      }
    }
  }

  /**
   * @description called upon successful upload of image
   */
  getTrackDetails() {
    let count: number = 0;
    this.locationsHttpService
      .getTrackDetails(this.trackGuid)
      .subscribe((trackData) => {
        this.trackMedia = [];
        this.imageUrls = [];
        trackData.media?.image?.map((image: any, _index: any) => {
          let imageObj: any = {
            originalUrl: image.originalUrl,
            thumbnailUrl: image.thumbnailUrl,
            type: "image",
            count: count,
            id: image.id,
            guid: trackData.guid,
          };
          this.thumbnailUrls.unshift(imageObj);
          this.trackMedia.push(imageObj);
          count = count + 1;
        });
        trackData.media?.video?.map((video: any, _index: any) => {
          let vedioObj: any = {
            originalUrl: video.originalUrl,
            thumbnailUrl: video.thumbnailUrl,
            type: "video",
            count: count,
            id: video.id,
            guid: trackData.guid,
          };
          this.thumbnailUrls.unshift(vedioObj);
          this.trackMedia.push(vedioObj);
          count = count + 1;
        });

        setTimeout(() => {
          this.toastService.hide();
        }, 4000);
        this.selectedImgIndex = 0;
        this.trackMedia.forEach((item:any) =>{ this.db.getMedia(item.id, "thumbnail");})

        
        this.mediaCarouselService.shareMediaUrls.next(this.trackMedia);
      });
  }
  ngOnDestroy() {
    this.subscribeShareMediaUrl.unsubscribe();
  }
}
