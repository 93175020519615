<div class="header-column">
  <div class="web-portal-container">
    <div class="title-bar">
      <div class="header-title">{{ title | customTranslation:translationData | sentencecase }}</div>
      <div class="button-region">
        <div *ngIf="enableSpinner" class="spinner-border publish-spinner" role="status"></div>
        
        <div *ngIf="!enableSpinner" >
          <button class="hallow" (click)="secondaryButtonClick()" >
            <span>{{ secondaryButtonTitle | customTranslation:translationData | sentencecase}}</span>
          </button>
        </div>
        <button *ngIf="!enableSpinner" class="beta"   [ngClass]="isDisablePrimaryButton? 'disabled-button' : ''" (click)="primaryButtonClick()">
          <span>{{ primaryButtonTitle | customTranslation:translationData | sentencecase}}</span>
        </button>
      </div>
    </div>
  </div>
</div>