<image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="maintainAspectRatio"
    [aspectRatio]="aspectRatio"
    [containWithinAspectRatio]="containWithinAspectRatio"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (loadImageFailed)="loadImageFailed()"
    [cropperMinWidth]="cropperMinWidth"
    [cropperMinHeight]="cropperMinHeight"
    [resizeToWidth]="resizeToWidth"
    [resizeToHeight]="resizeToHeight">
</image-cropper>
