<div class="lang-overlay">
  <div class="screen-main-reg open-modal">
    <div class="web-portal-container">
      <div class="screen-content-header">
        <div class="title-bar">
          <div class="header-title">
            <h2><span>{{'BP_LM_SELECT_LANG' | customTranslation: translationData}}</span></h2>
          </div>
          <div class="button-region">
            <button type="button" class="hallow" (click)="closeLangSelection()">
              <span>{{BUTTON_NAMES.CLOSE | customTranslation: translationData}}</span>
            </button>
          </div>
        </div>
        <div id="lang-selct-box">
          <div class="reg-lang-list-blk" [hidden]="true">
            <h6>Your region - Germany</h6>
            <ul class="lt-row-w-g left">
              <li class="clm-3 clm-tab-4"><span>English</span></li>
              <li class="clm-3 clm-tab-4"><span>German</span></li>
              <li class="clm-3 clm-tab-4"><span>French</span></li>
            </ul>
          </div>
          <div class="all-lang-list-blk">
            <h6>{{'BP_LM_ALL_LANGUAGES_LBL' | customTranslation: translationData}}</h6>
            <ul class="lt-row-w-g left">
              <li class="clm-3 clm-tab-8" *ngFor="let language of allLanguages">
                <a><span class="current-lang" id="locale-desc" (click)="switchLanguage(language.localeCode)"> {{ language.localeDescription }} </span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>