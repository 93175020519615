<div>
  <div
    class="media-header"
    *ngIf="
      mediaUrls.length > 0 &&
      isuploadDisabled &&
      isFromPage.toLowerCase() !== 'mediagallery'
    "
  >
    Media
  </div>
  <div
    class="media-header"
    *ngIf="!isuploadDisabled && isFromPage.toLowerCase() !== 'mediagallery'"
  >
    {{'BP_CM_ADD_MEDIA' | customTranslation: translationData}}
  </div>
  <app-media-gallery
    *ngIf="this.isFromPage.toLowerCase() !== 'mediagallery'"
    [imageUrls]="getOrginalUrls()"
    [selectedImgIndex]="selectedImgIndex"
    [trackName]="trackName"
    [trackNameGuid]="this.trackGuid"
    class="wb-content-grid"
    id="media-gallery"
  ></app-media-gallery>
  <div *ngIf="isMediaUpload" class="spinner-container">
    <div class="spinner-border publish-spinner"></div>
  </div>
  <div class="flex-container media-column">
    <div *ngIf="!isuploadDisabled" class="upload-media" for="fileField" id="add-media"  (click)="showGallery()">
      <div class="image-upload">
        <label for="file-input">
          <img class="add-media-image" src="assets/locations/icn-add-image.png" alt=""/>
        </label>
        <input
          id="file-input"
          type="file"
          (change)="fileChange($event)"
          multiple="multiple"
          [disabled]="isuploadDisabled"
          accept=".jpg, .jpeg, .png, .gif,.mp4"
        />
      </div>
    </div>
    <ng-container *ngIf="mediaUrls.length > 0">
      <div
        *ngFor="let preview of thumbnailUrls; let i = index"
        class="media-block"
        (mouseenter)="mouseEnter(i)"
        (mouseleave)="mouseLeave()"
        [ngClass]="[
          mediaUrls[i].count > 7 && isuploadDisabled && showMediaCount
            ? 'display-image'
            : '',
          mediaUrls[i].count > 8 && !isuploadDisabled && showMediaCount
            ? 'display-image'
            : '',
          i === getSelectedMediaIndex &&
          isFromPage.toLowerCase() === 'mediagallery'
            ? 'thumbnail-border'
            : ''
        ]"
      >
        <div
          *ngIf="
            (isFromPage.toLowerCase() === 'mediagallery' &&
              showCheckBox &&
              i === indexOfSelectedDiv) ||
            (checkBoxSelected && preview.isMediaSelected)
          "
        >
          <label class="custom-checkbox">
            <input type="checkbox" (change)="toggleEditable($event, preview)" id="{{'checkbox'+i}}"/>
          </label>
        </div>
        <app-blob-image
          *ngIf="preview.thumbnailUrl"
          [url]="preview"
          (click)="blobImageClick(i, preview)"
          [totalMediaCount]="mediaUrls.length"
          [isUploadMediaEnabled]="!isuploadDisabled"
          [showMediaCount]="showMediaCount"
          [isGalleryOPened]="isShowGallery"
          id="{{'blob-image' + i}}"
        ></app-blob-image>
      </div>
    </ng-container>
  </div>
</div>
